import React, {useEffect, useState} from "react";
import "./NewCases.css";
import {AiOutlineEdit} from "react-icons/ai";
import {useSelector} from "react-redux";
import {Tooltip} from "antd";
import EditModel from "../../Components/EditModel/EditModel";
import {getCurrencySymbolsForMarket, getLocalDate, getLocalYear} from "../../utils/utils";
import {isEmpty} from "lodash";
import {useNavigate} from "react-router-dom";

function Rows({
                  index,
                  item,
                  isReload,
                  setIsReload,
              }) {
    const {user} = useSelector((state) => state.auth);
    const isAdmin = user?.role === "Admin";
    const [isEditModel, setIsEditModel] = useState(false);
    const isNew = item.viewed === false;
    const navigate = useNavigate();

    useEffect(() => {

    }, [user]);

    const editNewCase = () => {
        if (isAdmin) setIsEditModel(true)
        else if (isEmpty(user.payment) || !user.payment) {
            navigate('/payment-setting')
        }else {
            setIsEditModel(true)
        }
    };

    const rows = [
        // {
        //     className: "table_items_first_row",
        //     width: "70px",
        //     labelValue: (
        //         <Tooltip
        //             title={<div style={{width: "100%", color: "black"}}>Submit your Case</div>}
        //             // title={<div style={{ width: "100%", color: "black" }}>Update</div>}
        //             color="white"
        //         >
        //             <AiOutlineEdit className="editBtn" onClick={editNewCase}/>
        //         </Tooltip>
        //     ),
        // },
        {
            className: "table_items",
            width: "100px",
            labelValue: (
                <>
                    {isNew && <span className="newFlag blinking">NEW</span>}
                    {item?.isInput ? <input className="w_70"/> : item?.caseId}
                </>
            ),
            // isAdminOnly: true,
        },
        // {
        //   className: "table_items",
        //   width: "100px",
        //   labelValue: `${item?.expiresIn} days` || "-",
        // },
        // {
        //   className: "table_items",
        //   width: "160px",
        //   labelValue: `${item?.closeAutomatically} days` || "-",
        // },
        {
            className: "table_items",
            width: "300px",
            labelValue: getLocalYear(item?.date || ""),
        },
        {
            className: "table_items",
            width: "160px",
            labelValue: item.type,
        },
        {
            className: "table_items",
            width: "80px",
            labelValue: item?.totalClaimableUnits,
        },
        // {
        //   className: "table_items",
        //   width: "130px",
        //   labelValue:
        //     item?.type === "lost"
        //       ? "N/A"
        //       : item?.referenceId
        //       ? JSON.stringify(item?.referenceId)
        //       : "N/A",
        // },
        // {
        //   className: "table_items",
        //   width: "120px",
        //   labelValue:
        //     item?.status === "TO_BE_CLAIMED" ||
        //     item?.status === "TO_BE_CHECKED" ||
        //     !item?.status
        //       ? "REIMBURSABLE"
        //       : item?.status,
        // },
        {
            className: "table_items",
            width: "110px",
            labelValue: (item?.storeName || "-"),
        },
        {
            className: "table_items",
            width: "100px",
            labelValue: item?.country,
        },
        {
            className: "",
            width: "",
            toolTip: (
                <Tooltip
                    title={
                        <div>
                            <div className="" style={{width: "100%", color: "black"}}>
                                <div>
                                    <span className="tooltip_product_label">Product Name</span>
                                    {item?.title}
                                </div>
                                <div className="product_details">
                                    <div style={{marginRight: "15px"}}>
                                        <span className="tooltip_product_label">SKU </span>
                                        {item?.sellerSku || "334334343434"}
                                    </div>
                                    <div>
                                        <span className="tooltip_product_label">FNSKU </span>
                                        {item?.fnsku || "23423423423423"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    color="white"
                >
                    <div className="table_items product_description">
                        <div>
                            {item?.title ? `${item.title.substring(0, 30)}......` : ""}
                        </div>
                        <div className="product_details">
                            <div style={{marginRight: "15px"}}>
                                <span className="product_label">SKU </span>
                                {item?.sellerSku || ""}
                            </div>
                            <div className="">
                                <span className="product_label">FNSKU </span>
                                {item?.fnsku || ""}
                            </div>
                        </div>
                    </div>
                </Tooltip>
            ),
        },
        // {
        //     className: "table_items",
        //     width: "120px",
        //     labelValue:
        //         <div>{item?.estimatePrice && item?.estimatePrice?.value === 0 ? "N.D" : getCurrencySymbolsForMarket(item?.estimatePrice)}</div>
        // },
        // {
        //     className: "table_items",
        //     width: "120px",
        //     labelValue:
        //         <p style={{color: "red"}}>{item.isProductInInventory ? '' : "Attention: Product not in inventory!"}</p>
        // },
    ];
    return (
        <tr key={index}>
            {rows.map((row) => {
                return row.isAdminOnly ? (
                    isAdmin && (
                        <td className="responsivePadding">
                            <div className={row.className} style={{width: row.width, overflow: "auto"}}>
                                {row.labelValue || row.toolTip}
                            </div>
                        </td>
                    )
                ) : row.isUserOnly ? (
                    !isAdmin && (
                        <td className="responsivePadding">
                            <div className={row.className} style={{width: row.width, overflow: "auto"}}>
                                {row.labelValue || row.toolTip}
                            </div>
                        </td>
                    )
                ) : (
                    <td className="responsivePadding">
                        <div className={row.className} style={{width: row.width, overflow: "auto"}}>
                            {row.labelValue || row.toolTip}
                        </div>
                    </td>
                );
            })}
            <EditModel
                isEditModel={isEditModel}
                setIsEditModel={setIsEditModel}
                item={item}
                isReload={isReload}
                setIsReload={setIsReload}
            />
        </tr>
    );
}

export default Rows;