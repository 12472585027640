import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {GetUser} from '../../redux/slices/auth-slice';

import Status from "./Status/Status";
import History from "./History/History";
import {dashboardStats,} from "../../redux/slices/dashboard-slice";
import {GetAdjustmentsCases, GetNewCases,} from "../../redux/slices/adjustment-slice";
import "./Dashboard.css";
import {axiosBaseUrl} from "../../config/axios-configuration";
import {useNavigate} from "react-router-dom";

function Dashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {user} = useSelector((state) => state.auth);

    const {
        reimbursedItems = 0,
        dashboardSummary = {},
    } = useSelector((state) => state.dashboardStats);
    const {newCases, adjustments, isLoading, err, total} =
    useSelector((state) => state.adjustment) || {};

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [region, setRegion] = useState("eu");

    useEffect(() => {
        dispatch(dashboardStats());
        dispatch(
            GetNewCases({
                storeId: "All",
                adjustmentType: "All",
                skip: 0,
                limit: 10,
                filters: {},
            })
        );
        dispatch(
            GetAdjustmentsCases({
                storeId: "All",
                adjustmentType: "All",
                skip: 0,
                limit: 10,
                filters: {
                    status: {value: "Customer Action Required"},
                },
            })
        );
    }, []);

    const axios = axiosBaseUrl();

    const getActionsColoumns = (availableCols) => {
        const colomns = [];
        availableCols.map((actions) => {
            if (actions.labelValue) {
                colomns.push({
                    title: actions.labelValue,
                    dataIndex: actions.backendKey,
                    key: actions.backendKey,
                    render: actions.render,
                });
            }
        });
        return colomns;
    };

    const registerPrivacyPolicy = useCallback(async () => {
        const response = await axios.post("/auth/update-user", {privacy: true});

        if (response.data.success) {
            dispatch(GetUser());
        }
    }, [axios, dispatch]);

    return (
        <div className="dashboard_container">
            <Row>
                <Col sm={12} md={12} lg={6}>
                    <Status className='shadow-sm'
                            reimbursedItems={reimbursedItems}
                            dashboardSummary={dashboardSummary}
                    />
                    <Col sm={12} className="mt-4  shadow-sm">
                        {!(user?.role === "Admin") && <History className=' shadow-sm'/>}
                    </Col>

                </Col>
            </Row>
            {/* <PrivacyModal open={!user?.privacy} onOk={registerPrivacyPolicy} /> */}
        </div>
    );
}

export default Dashboard;