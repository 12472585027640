import React from "react";
import { getSearchTerm, getIsClearFilter } from "../../utils/utils";
import SortIcon from "./SortIcon";
import { Input } from "antd";

function DefaultInput({
  col,
  setActiveOptions,
  activeOptions,
  searchClick,
  setSearchClick,
}) {
  return (
    <div>
      <Input
        addonBefore={
          <SortIcon
            col={col}
            setActiveOptions={setActiveOptions}
            activeOptions={activeOptions}
            className="sortIconStyle"
          />
        }
        // type={col.type && col.type === 'number' ? 'number' : 'text'}
        value={activeOptions[col?.backendKey]?.value}
        placeholder=""
        allowClear
        onChange={(e) => {
          const { value = "" } = e.target || {};
          const updateOptions = {
            ...activeOptions,
            [col.backendKey]: {
              ...activeOptions[col.backendKey],
              value: value.trim(),
            },
          };
          setActiveOptions(updateOptions);
        }}
        onBlur={(e) => setSearchClick(false)}
        className="tableThInput"
        style={{
          width: `${col.width}px`,
        }}
        bordered={false}
        onClick={() => setSearchClick(true)}
      />
      <div
        className={
          getSearchTerm(col.backendKey, activeOptions) || searchClick
            ? "labelValue"
            : "labelValueWithoutTerm"
        }
        style={{ maxWidth: `${col.width - 25}px` }}
      >
        {col.labelValue}
      </div>
    </div>
  );
}

export default DefaultInput;
