import React from "react";
import { DatePicker, Button } from "antd";
import SortIcon from "./SortIcon";
import { getSearchTerm } from "../../utils/utils";
import "./TableTh.css";
import { useState } from "react";

function DatePicker1({
  col,
  setActiveOptions,
  activeOptions,
  searchClick,
  setSearchClick,
}) {
  const [selectedDate, setSelectedDate] = useState(
    activeOptions[col?.backendKey]?.value
  );
  const [selectedTimestamps, setSelectedTimestamps] = useState([])
  const [openState, setOpenState] = useState(false);
  return (
    <div>
      <div
        className="tableThInput"
        style={{
          display: "flex",
          alignItems: "center",
          width: `${col.width}px`,
        }}
      >
        {col.hasSort && (
          <SortIcon
            col={col}
            setActiveOptions={setActiveOptions}
            activeOptions={activeOptions}
            className="sortIconWithDate"
          />
        )}
        <DatePicker
          onChange={(date) => {
            // const updateOptions = {
            //   ...activeOptions,
            //   [col.backendKey]: {
            //     ...activeOptions[col.backendKey],
            //     value: date,
            //   },
            // };
            // setActiveOptions(updateOptions);
            setSelectedDate(date);
          }}
          open={openState}
          value={activeOptions[col?.backendKey]?.value || ""}
          bordered={false}
          showTime={false}
          placeholder=""
          onBlur={(e) => {
            setOpenState(false);
            setSearchClick(false);
            setSelectedDate("");
          }}
          onClick={(e) => {
            e.stopPropagation();
            setSearchClick(true);
            setOpenState(true);
          }}
          style={{ zIndex: 150, width: "100%" }}
          renderExtraFooter={() => (
            <div className="date-picker-footer">
              <Button
                className="date-picker-footer-clear-btn"
                disabled={!activeOptions[col?.backendKey]?.value}
                onClick={() => {
                  if (activeOptions[col?.backendKey]?.value) {
                    const updateOptions = {
                      ...activeOptions,
                      [col.backendKey]: {
                        ...activeOptions[col.backendKey],
                        value: "",
                      },
                    };
                    setOpenState(false);
                    setActiveOptions(updateOptions);
                    setSearchClick(false);
                  }
                }}
              >
                Clear
              </Button>
              <Button
                type="primary"
                className="date-picker-footer-save-btn"
                disabled={
                  !(selectedDate || activeOptions[col?.backendKey]?.value)
                }
                onClick={() => {
                  if (selectedDate) {
                    const updateOptions = {
                      ...activeOptions,
                      [col.backendKey]: {
                        ...activeOptions[col.backendKey],
                        value: selectedDate,
                      },
                    };
                    setOpenState(false);
                    setActiveOptions(updateOptions);
                    setSearchClick(false);
                  }
                }}
              >
                Save
              </Button>
            </div>
          )}
        />
      </div>
      <div
        className={
          getSearchTerm(col.backendKey, activeOptions) || searchClick
            ? "labelValue"
            : "labelValueWithoutTerm"
        }
        style={{ maxWidth: `${col.width - 25}px` }}
      >
        {col.labelValue}
      </div>
    </div>
  );
}

export default DatePicker1;
