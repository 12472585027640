import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const NotificationDot = styled.div`
  background-color: #F50F0F;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: -20%;
  right: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
  font-weight: bold;
  transition: transform 0.3s ease;
  text-align: center;
  line-height: 20px;
`;

const NotificationText = ({ counter }) => {
  const [size, setSize] = useState(1);

  useEffect(() => {
    setSize(1.2);
    const timer = setTimeout(() => {
      setSize(1);
    }, 90); 
    return () => clearTimeout(timer);
  }, [counter]);

  return (
    <NotificationDot style={{transform: `scale(${size}) translate(50%, -50%)`}}>{counter}</NotificationDot>
  );
};

export default NotificationText;
