import React from "react";
import Logo from "../assets/logo.png";

function LogoComponent() {
  return (
    <div
      style={{
        textAlign: "center",
        marginBottom: "30px",
      }}
    >
      <img
        src={Logo}
        alt="SOS FBA"
        style={{ border: "1px solid gray", borderRadius: "50%" }}
      />
    </div>
  );
}

export default LogoComponent;
