import React, {useState, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Modal, Row, Col, Input, Button, message, Divider} from "antd";
import {UpdateTransactionAllCases} from "../../redux/slices/adjustment-slice";
import {toast} from 'react-toastify';

function EditModalAllCases({isEditModel, setIsEditModel, item, setTransactionUpdated}) {
    const dispatch = useDispatch();
    const [caseDetails, setCaseDetails] = useState({
        amazonCaseId: item?.amazonCaseId,
        reimbursementId: item?.reimbursementIds
    });

    const isResolved = item?.status === 'Resolved';

    const handleInputChange = (key, value) => {
        setCaseDetails({
            ...caseDetails,
            [key]: value
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsEditModel(false);
        const amazonCaseId = caseDetails.amazonCaseId ? Number(caseDetails.amazonCaseId) : null;
        const reimbursementId = caseDetails.reimbursementId ? Number(caseDetails.reimbursementId) : null;
        if ((amazonCaseId !== null && isNaN(amazonCaseId)) || (reimbursementId !== null && isNaN(reimbursementId))) {
            toast.error("Amazon Case ID and Reimbursement ID should be numbers.");
            setCaseDetails({amazonCaseId: item?.amazonCaseId, reimbursementId: item?.reimbursementIds});
            return;
        }


        let response;
        console.log({
            _id: item._id,
            amazonCaseId: amazonCaseId,
            reimbursementId: reimbursementId,
        })
        if (item?._id) {
            response = await dispatch(
                UpdateTransactionAllCases({
                    _id: item._id,
                    amazonCaseId: amazonCaseId,
                    reimbursementId: reimbursementId,
                })
            );
        }
        if (response) {
            if (response.payload && response.type === "adjustments/update-all-cases/fulfilled") {
                if (response.payload.updated) {
                    let status = response.payload.response.status;
                    toast.success(`Transaction updated successfully. Now status is "${status}".`);
                } else {
                    toast.success("Transaction updated successfully.");
                }
            }
            setTransactionUpdated(true);
        }
    };

    const handleCloseModal = () => {
        // Ripristina lo stato originale quando la modale viene chiusa senza salvare
        setCaseDetails({amazonCaseId: item?.amazonCaseId || "", reimbursementId: item?.reimbursementIds || ""});
        setIsEditModel(false);
    };

    return (
        <Modal
            style={{top: 50}}
            title={
                <div className="model_header">
                    <div style={{fontWeight: "bold", fontSize: "18px"}}>
                        {`Case ID: ${item?.caseId || "N/A"}`}
                    </div>
                </div>}
            open={isEditModel}
            onCancel={handleCloseModal}
            width={800}
            okButtonProps={{disabled: isResolved}}
            onOk={handleSubmit}
            okText={isResolved ? "Resolved" : "Submit"}
            cancelButtonProps={{style: {display: "none"}}}
        >
            {isResolved ? (
                <p>
                    Note: Editing is not allowed when the status is resolved.
                </p>
            ) : (
                <p>
                    Please enter the Amazon Case ID and Reimbursement ID for modification.
                </p>
            )}

            <Divider/>

            <Row className="mt-3">
                <Col span={6}>Amazon Case ID</Col>
                <Col span={12}>
                    <Input
                        style={{width: "134%"}}
                        placeholder="Enter Amazon Case Id"
                        value={caseDetails.amazonCaseId}
                        onChange={(e) => handleInputChange('amazonCaseId', e.target.value)}
                        disabled={isResolved}
                    />
                </Col>
            </Row>

            <Row className="mt-3">
                <Col span={6}>Reimbursement ID</Col>
                <Col span={12}>
                    <Input
                        style={{width: "134%"}}
                        placeholder="Enter Amazon Reimbursement ID"
                        value={caseDetails.reimbursementId}
                        onChange={(e) => handleInputChange('reimbursementId', e.target.value)}
                        disabled={isResolved}
                    />
                </Col>
            </Row>

            <Divider/>
            <Row className="mt-3">
                <Col span={6}>Status</Col>
                <Col span={12}>
                    <Input
                        disabled
                        value={
                            item?.status === "TO_BE_CLAIMED" ||
                            item?.status === "TO_BE_CHECKED" ||
                            !item?.status
                                ? "REIMBURSEABLE"
                                : item?.status
                        }
                        style={{width: "134%"}}
                    />
                </Col>
            </Row>

            {
                <Row className="mt-3">
                    <Col span={6}>Transaction Item Id</Col>
                    <Col span={12}>
                        <Input
                            disabled
                            value={item?.referenceIds &&
                            item?.referenceIds.length > 0 ?
                                item?.referenceIds[0] :
                                "N/A"}
                            style={{width: "134%"}}
                        />
                    </Col>
                </Row>
            }

            <Row className="mt-3">
                <Col span={6}>Case type</Col>
                <Col span={12}>
                    <Input disabled value={item?.type || "N/A"} style={{width: "134%"}}/>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col span={6}>FNSKU</Col>
                <Col span={12}>
                    <Input disabled value={item?.fnsku || "N/A"} style={{width: "134%"}}/>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col span={6}>SKU</Col>
                <Col span={12}>
                    <Input disabled value={item?.sellerSku || "N/A"} style={{width: "134%"}}/>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col span={6}>Quantity</Col>
                <Col span={12}>
                    <Input disabled value={item?.totalClaimableUnits || "N/A"} style={{width: "134%"}}/>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col span={6}>Account</Col>
                <Col span={12}>
                    <Input disabled value={item?.storeName || "N/A"} style={{width: "134%"}}/>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col span={6}>Marketplace</Col>
                <Col span={12}>
                    <Input disabled value={item?.country || "N/A"} style={{width: "134%"}}/>
                </Col>
            </Row>
        </Modal>
    );
}

export default EditModalAllCases;