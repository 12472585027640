import React, {useEffect, useState} from "react";
import TableView from "./TabelView/TableView";
import {Button, Card, Col, Input, Modal, Row, Tooltip} from "antd";
import {useSelector} from "react-redux";
import {UserModal} from "./UserModal";
import {toast} from "react-toastify";
import {axiosBaseUrl} from "../../config/axios-configuration";
import Verified from "../../assets/Instagram-Verification Badge-24.png";
import './Users.css'
import {FileTextOutlined} from "@ant-design/icons";

//TODO refactor
export const UsersSeller = () => {
    const [pageNo, setPageNo] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [size, setSize] = useState(100);
    const [activeOptions, setActiveOptions] = useState({});
    const [billingModal, setBillingModal] = useState();
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
    const [modalCallback, setModalCallback] = useState(null);
    const [textModal, setTextModal] = useState(false);
    const [sellerToAdd, setSellerToAdd] = useState("")
    const axios = axiosBaseUrl();
    const {users, isLoading, err, total} =
    useSelector((state) => state.admin) || {};

    const [data, setData] = useState(users);

    const viewRow = (record) => {
        setBillingModal(record);
    };

    const USERS_COLUMNS = [
        {
            title: "Commission Discount",
            dataIndex: "commissionDiscount",
            key: "commissionDiscount",
            editable: true,
            hasFilter: true
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            editable: false,
            hasFilter: true
        },
        {
            title: "Store",
            dataIndex: "storeName",
            key: "storeName",
            editable: true,
            hasFilter: true
        },
        {
            title: "SellerIds",
            dataIndex: "sellerIds",
            key: "sellerIds",
            editable: false,
            hasFilter: true
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            editable: false,
            hasFilter: true
        },
        {
            title: "Join Date",
            dataIndex: "createdAt",
            key: "createdAt",
            editable: false,
        },
        {
            title: "Operation",
            dataIndex: "operation",
            render: (_, record) =>
                    <div>
                        {record?.payment?.address && (
                            <Tooltip title={'Billing Info'}>
                                <Button onClick={() => viewRow(record)}>
                                    <img style={{color: record.billingDetailsVerified ? 'blue' : 'red'}} src={Verified}
                                         alt={""}/>
                                </Button>
                            </Tooltip>
                        )}
                        {(!record?.payment || !record?.payment?.address) && (
                            <Tooltip title={'Edit'}>
                                <Button danger onClick={() => viewRow(record)}>
                                    <img style={{color: record.billingDetailsVerified ? 'blue' : 'red'}} src={Verified}
                                         alt={""}/>
                                </Button>
                            </Tooltip>
                        )}
                        <Tooltip title={'Cases'}>
                            <Button onClick={() => window.open('/seller/new-cases?user=' + record._id)}>
                                <FileTextOutlined/>
                            </Button>
                        </Tooltip>
                    </div>
        },
    ];

    useEffect(() => {
        setData(users);
    }, [users]);

    useEffect(() => {
        axios
            .get("/auth/seller/users")
            .then(r => {
                setData(r.data.users)
            })
            .catch(err => console.error(err))
    }, [pageNo, size, activeOptions, isModalOpen]);

    const onlyUnique = (value, index, array) => {
        return array.indexOf(value) === index;
    }

    const mergedColumns = USERS_COLUMNS.map((col) => {
        if (!col.editable) {
            if (col.hasFilter) {
                return {
                    ...col,
                    filters: data.map(x => ({text: x[col.key], value: x[col.key]})).filter(onlyUnique),
                    filterMode: 'menu',
                    filterSearch: true,
                    onFilter: (value, record) => {
                        return record[col.key].indexOf(value) === 0
                    },
                };
            } else {
                return col;
            }
        }
        return {
            ...col,
            filters: data.map(x => ({text: x[col.key], value: x[col.key]})).filter(onlyUnique),
            filterMode: 'menu',
            filterSearch: true,
            onFilter: (value, record) => {
                console.log(value, record)
                return record[col.key].indexOf(value) === 0
            },
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === "commissionDiscount" ? "number" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });
    const saveUserAssociation = () => {
        if (sellerToAdd && sellerToAdd !== "")
            axios
                .post("/auth/seller/associate", {sellerId: sellerToAdd})
                .then(r => {
                    setSellerToAdd("")
                    toast.success("Seller added successfully.")
                    window.location.reload()
                })
                .catch(err => {
                    console.error(err)
                    toast.error(err.response.status === 404 ? "Seller not found." : "Generic error")
                })
    }

    return (
        <>
            <Card>
                <div>
                    <Row>
                        <h3>Add Seller</h3>
                    </Row>
                    <Row>
                        <Col>
                            <Input onChange={(e) => {
                                setSellerToAdd(e.target.value)
                            }} placeholder={"SellerId"}/>
                        </Col>
                        <Col>
                            <Button onClick={saveUserAssociation}>
                                Add
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Card>
            <UserModal
                isModalOpen={!!billingModal}
                onCancel={() => setBillingModal(null)}
                user={billingModal}
                closeModal={setBillingModal}
            />
            <TableView
                columns={mergedColumns}
                data={data}
            />
            <Modal key={isConfirmationModalVisible}
                   title="Conferma"
                   open={isConfirmationModalVisible}
                   onOk={() => {
                       if (modalCallback) modalCallback();
                       setIsConfirmationModalVisible(false);
                       setModalCallback(null);
                   }}
                   onCancel={() => {
                       setIsConfirmationModalVisible(false);
                       setModalCallback(null);
                   }}
            >
                {textModal}
            </Modal>
        </>
    )
        ;
}