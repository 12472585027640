import React from "react";
import "./Status.css";
import Progress from "../../../assets/progress.png";
import ArrowDown from "../../../assets/arrow_down.png";
import ArrowUp from "../../../assets/arrow_up.png";
import Trolly from "../../../assets/trolly.png";
import {getCurrencySymbols} from "../../../utils/utils";
import {
  AiFillLinkedin,
  AiFillFacebook,
  AiFillTwitterCircle,
} from "react-icons/ai";

function Status({ reimbursedItems, dashboardSummary }) {
  return (
    <div className="status_container">
      <div className="status_heading">Current status</div>
      {/* <div className="status_description">Summary...........</div> */}
      <div className="status_details_wrapper">
        <div className="progress_container">
          <img className="progress_img" src={Progress} alt="progress" />
          <div>
            <div className="status_description">Total Reimbursement</div>
            <div className="reimbursment_price">
              {getCurrencySymbols(dashboardSummary?.recovered)}
            </div>
          </div>
          {/* <div className="progress_share_wrapper">
            <div className="status_description">Share</div>
            <div className="progress_icons">
              <AiFillLinkedin />
              <AiFillFacebook />
              <AiFillTwitterCircle />
            </div>
          </div> */}
        </div>
        {/* <div className="transaction_container">
          <div>
            <div className="status_description">Potential Recovery Amount</div>
            <div className="transaction_quantity">
              {getCurrencySymbols(dashboardSummary?.claimed)}
            </div>
          </div>
        </div> */}
        <div className="transaction_container">
          <img className="trolly_img" src={Trolly} />
          <div>
            <div className="status_description">Reimbursed item</div>
            <div className="transaction_quantity">{reimbursedItems}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Status;
