import React from 'react';
import ReactDOM from "react-dom/client";
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist';
import Store from './redux/store';
import {ErrorBoundary} from 'react-error-boundary'

let persistor = persistStore(Store);

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.NODE_ENV !== "development")
    console.log = () => {};

root.render(
    // <StrictMode>
        <ErrorBoundary FallbackComponent={Fallback}>
            <Provider store={Store}>
                <PersistGate loading={false} persistor={persistor}>
                    <BrowserRouter>
                            <App/>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    // </StrictMode>
);

function Fallback({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: "red" }}>{error.message}</pre>
        </div>
    );
}