import React from "react";
import { Select } from "antd";
import SortIcon from "./SortIcon";
import { getSearchTerm } from "../../utils/utils";

const App = ({
  col,
  setActiveOptions,
  activeOptions,
  searchClick,
  setSearchClick,
}) => (
  <div>
    <div
      className="tableThInput"
      style={{
        display: "flex",
        alignItems: "center",
        width: `${col.width}px`,
      }}
    >
      {col.hasSort && (
        <SortIcon
          col={col}
          setActiveOptions={setActiveOptions}
          activeOptions={activeOptions}
          className="sortIconWithDate"
        />
      )}
      <Select
        showArrow
        allowClear
        style={{
          width: "100%",
          zIndex: 1000,
        }}
        onChange={(selectedOption) => {
          const updateOptions = {
            ...activeOptions,
            [col.backendKey]: {
              ...activeOptions[col.backendKey],
              value: selectedOption,
            },
          };
          setActiveOptions(updateOptions);
        }}
        value={activeOptions[col?.backendKey]?.value}
        options={col.selectOptions}
        bordered={false}
        onBlur={(e) => setSearchClick(false)}
        onClick={() => setSearchClick(true)}
      />
    </div>
    <div
      className={
        getSearchTerm(col.backendKey, activeOptions) || searchClick
          ? "labelValue"
          : "labelValueWithoutTerm"
      }
      style={{ maxWidth: `${col.width - 25}px` }}
    >
      {col.labelValue}
    </div>
  </div>
);
export default App;
