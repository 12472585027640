import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

import authSlice from '../slices/auth-slice';
import adminSlice from '../slices/admin-slice';

import storeSlice from '../slices/store-slice';
import adjustmentSlice from '../slices/adjustment-slice';
import dashboardSlice from '../slices/dashboard-slice';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'stores']
  // transforms: [storesTransform]
};

const reducers = combineReducers({
  auth: authSlice,
  stores: storeSlice,
  adjustment: adjustmentSlice,
  dashboardStats: dashboardSlice,
  admin: adminSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/LogOut') {
    state = undefined;
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk] ,
  devTools: true
});
