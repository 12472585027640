import React, {useEffect, useState} from "react";
import ContactInfo from "./ContactInfo.js";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {Button, Col, Divider} from "antd";

export function Contacts({contacts, setContacts, isDisabled}) {
    const [internalContacts, setInternalContacts] = useState([])

    useEffect(() => {
        setInternalContacts(contacts ?? [])
    }, [contacts]);

    const updateContacts = () => {
        setContacts(internalContacts)
    }

    const add = () => {
        let tmp = [...internalContacts]
        tmp.push({name: '', surname: '', phone: '', description: ''})
        setInternalContacts(tmp)
    }

    const remove = (index) => {
        let tmp = [...internalContacts]
        tmp.splice(index, 1)
        setInternalContacts(tmp)
    }

    const setContact = (contact, index) => {
        let tmp = [...internalContacts]
        tmp[index] = contact
        setInternalContacts(tmp)
    }
    return <>
        {internalContacts && internalContacts?.map((x, index) => <Col>
            <ContactInfo info={x} setInfo={setContact} index={index} isDisabled={isDisabled}/>
            <Button onClick={() => remove(index)}><MinusCircleOutlined/></Button>
            <Divider/>
        </Col>)}
        <Divider/>
        <Button onClick={add}><PlusCircleOutlined/></Button>
        <Button onClick={updateContacts}>Save Contacts</Button>

    </>
}