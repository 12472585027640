import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const GetAdjustments = createAsyncThunk(
  'adjustment/get-adjustment',
  async (params, { rejectWithValue }) => {
    try {

      const response = await axios.get('/adjustments/get-adjustments', {
        params,
      });
      const responsedData = response.data
      return responsedData
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          errorMessage: err.response.data.error,
          status: err.response.status
        });
      }
      return rejectWithValue({
        errorMessage: 'Network Error'
      });
    }
  }
);

export const GetNewCases = createAsyncThunk(
  'cases/new-cases',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get('/cases/new-cases', {
        params,
      });
      const responsedData = response.data
      return responsedData
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          errorMessage: err.response.data.error,
          status: err.response.status
        });
      }
      return rejectWithValue({
        errorMessage: 'Network Error'
      });
    }
  }
);

export const GetAdjustmentsCases = createAsyncThunk(
  'cases/adjustment-cases',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get('/cases/adjustment-cases', {
        params,
      });
      const responsedData = response.data
      return responsedData
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          errorMessage: err.response.data.error,
          status: err.response.status
        });
      }
      return rejectWithValue({
        errorMessage: 'Network Error'
      });
    }
  }
);

export const ClaimCase = createAsyncThunk(
  'adjustment/claim-case',
  async (data, { rejectWithValue }) => {
    try {

      const response = await axios.post('/adjustments/claim-adjustment', data);
      const responsedData = response.data
      return responsedData
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          errorMessage: err.response.data.error,
          status: err.response.status
        });
      }
      return rejectWithValue({
        errorMessage: 'Network Error'
      });
    }
  }
);

export const CreateCase = createAsyncThunk(
    'adjustment/create-case',
    async (data, { rejectWithValue }) => {
      try {

        const response = await axios.post('/adjustments/create-case', data);
        const responsedData = response.data
        return responsedData
      } catch (err) {
        if (err.response && err.response.data) {
          return rejectWithValue({
            errorMessage: err.response.data.error,
            status: err.response.status
          });
        }
        return rejectWithValue({
          errorMessage: 'Network Error'
        });
      }
    }
);

export const UpdateTransactionAllCases = createAsyncThunk(
  "adjustments/update-all-cases",
  async (data, { rejectWithValue }) => {
    try {
      const { token, userId, _id, amazonCaseId, reimbursementId, isFromAdmin } = data;

      const response = await axios.post("/adjustments/update-all-cases", {
        _id,
        userId,
        token,
        amazonCaseId,
        reimbursementId,
        isFromAdmin
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status,
          },
        });
      }
      return rejectWithValue({
        err: {
          message: "Network Error",
          status: 502,
        },
      });
    }
  }
);

const adjustments = createSlice({
  name: 'actionRequired',
  initialState: {
    isLoading: false,
    success: false,
    errorMessage: '',
    adjustments:[],
    newCases: [],
    total: 0,
    totalQuantity: 0,
    totalEstimate:0,
    message: '',
    caseSubmitted: false
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
  },
  extraReducers: {
    [GetAdjustments.pending]: (state, action) => ({
      ...state,
      isLoading: true
    }),
    [GetAdjustments.fulfilled]: (state, action) => ({
      ...state,
      adjustments: action.payload.adjustments,
      total: action.payload.total,
      isLoading: false,
      success: true
    }),
    [GetAdjustments.rejected]: (state, action) => ({
      ...state,
      isLoading: false,
      errorMessage: action.payload.errorMessage
    }),
    [GetNewCases.pending]: (state, action) => ({
      ...state,
      isLoading: true
    }),
    [GetNewCases.fulfilled]: (state, action) => ({
      ...state,
      newCases: action.payload.newCases,
      total: action.payload.total,
      totalQuantity: action.payload.totalQuantity,
      totalEstimate: action.payload.totalEstimate,
      isLoading: false,
      success: true
    }),
    [GetNewCases.rejected]: (state, action) => ({
      ...state,
      isLoading: false,
      errorMessage: action.payload.errorMessage
    }),
    [ClaimCase.pending]: (state, action) => ({
      ...state,
      isLoading: true,
      caseSubmitted: false,
      errorMessage: null,
    }),
    [ClaimCase.fulfilled]: (state, action) => ({
      ...state,
      caseSubmitted:true,
      isLoading: false
    }),
    [ClaimCase.rejected]: (state, action) => ({
      ...state,
      isLoading: false,
      caseSubmitted: false,
      errorMessage: action.payload.errorMessage
    }),
    [GetAdjustmentsCases.pending]: (state, action) => ({
      ...state,
      isLoading: true,
      errorMessage: null,
    }),
    [GetAdjustmentsCases.fulfilled]: (state, action) => ({
      ...state,
      isLoading: false,
      adjustments: action.payload.cases,
      total: action.payload.total,
    }),
    [GetAdjustmentsCases.rejected]: (state, action) => ({
      ...state,
      isLoading: false,
      errorMessage: action.payload.errorMessage
    })
  }
});


const { reducer, actions } = adjustments;

export const {
  SetState
} = actions;

export default reducer;
