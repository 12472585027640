import {Modal} from "antd";
import * as PropTypes from "prop-types";
import React from "react";

const TermsConditions = (props) => {
    return <>
        {!window.location.pathname.includes("terms") && props.user && props.user.billingAccount === "CONTACT" && <Modal
            title="Approve the latest Terms and conditions"
            open={!props.latestTecApproved}
            onOk={props.onOk}
            okText="Read the Terms"
            closable={false}
            cancelButtonProps={{style: {display: "none"}}}
        >
            <p>
                We have updated our terms and conditions. Please review them and accept to continue using your
                account.
            </p>
            <br/>
        </Modal>}
    </>;
}

TermsConditions.propTypes = {
    user: PropTypes.any,
    latestTecApproved: PropTypes.bool,
    onOk: PropTypes.func
};

export default TermsConditions