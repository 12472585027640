import { Col, Row } from "antd";
import React from "react";
import { Typography } from "antd";

import "./PrivacyPolicy.css";
import { CookiePolicy } from "./CookiePolicy";

const { Title, Text } = Typography;

export const PrivacyPolicy = () => {
  return (
    <Row justify="center" className="mt-20 pb-100">
      <Col span={20}>
        <Title level={3} className="policy-title mt-20">
          INFORMATIVA SULLA PRIVACY
        </Title>
        <br />
        <br />
        <Text>
          In questa sezione, in accordo con la normativa europea introdotta dal
          Regolamento UE 679/2016 e con la normativa italiana (D.lgs. n.
          196/2003), vengono fornite le informazioni in merito al trattamento
          dei dati personali degli Utenti che consultano le pagine del sito
          internet www.sosfba.it (di seguito: “<b>Sito</b>”) o che usufruiscono
          dei servizi sullo stesso messi a disposizione (di seguito “
          <b>Utenti”</b> o “<b>Interessati”</b>).
        </Text>
        <br />
        <br />
        <Text>
          L'informativa è resa esclusivamente per il Sito e non anche per gli
          altri siti web eventualmente consultati dall'utente tramite i link
          presenti all'interno del Sito.
        </Text>

        <br />
        <br />
        <Title level={4} className="policy-title mt-20">
          Titolare del trattamento
        </Title>

        <Text>
          Il Titolare del trattamento dei dati personali degli utenti del Sito
          www.sosfba.it è la società Solaris Group S.R.L., P.iva: <strong>IT</strong>13055980018, con sede in Torino (TO), Via Nizza, 262/1, CAP 10126 e-mail:{" "}
          <a href="mailto:supporto@sosfba.it">supporto@sosfba.it</a>.
        </Text>
        <br />
        <br />
        <Text>
          E-mail per contattare il titolare e chiedere la cancellazione dei tuoi
          dati, qualora raccolti, previo tuo consenso:{" "}
          <a href="mailto:supporto@sosfba.it">supporto@sosfba.it</a>.
        </Text>

        <br />
        <Title level={4} className="policy-title mt-20">
          ***
        </Title>

        <Title level={4} className="policy-title">
          A - Tipologia di dati trattati
        </Title>
        <Title level={4} className="policy-title">
          I. Dati identificativi
        </Title>

        <Text>
          In accordo con la normativa europea introdotta dal Regolamento UE
          679/2016, la consultazione del Sito può comportare il trattamento di
          dati idonei a identificare direttamente o indirettamente una persona
          fisica quali: nome, cognome, indirizzo e-mail, numero di telefono,
          indirizzo IP, Report Logistica di Amazon.
        </Text>

        <br />
        <br />
        <Text>
          Il Sito non richiede all’Interessato di fornire dati c.d.
          “particolari”, ovvero, secondo quanto previsto dal GDPR (art. 9), i
          dati personali che rivelino l'origine razziale o etnica, le opinioni
          politiche, le convinzioni religiose o filosofiche, o l'appartenenza
          sindacale, nonché dati genetici, dati biometrici intesi a identificare
          in modo univoco una persona fisica, dati relativi alla salute o alla
          vita sessuale o all'orientamento sessuale della persona. Nel caso in
          cui la prestazione richiesta imponesse il trattamento di tali dati,
          l’Interessato riceverà preventivamente apposita informativa e gli sarà
          richiesto di prestare consenso esplicito.
        </Text>

        <Title level={4} className="policy-title mt-20">
          II. Dati di navigazione
        </Title>
        <Text>
          I dati di navigazione sono dati acquisiti automaticamente dai sistemi
          e dai programmi preposti al funzionamento del Sito e sono necessari
          per la fruizione dei servizi web [es. indirizzi IP, browser
          utilizzato, nomi di dominio dei sistemi utilizzati dagli utenti per
          collegarsi al portale web, gli indirizzi in notazione URI (Uniform
          Resource Identifier) delle risorse richieste, l’orario della
          richiesta, il metodo utilizzato nel sottoporre la richiesta al server,
          la dimensione del file ottenuto in risposta, il codice numerico
          indicante lo stato della risposta data dal server (buon fine, errore,
          ecc.) ed altri parametri relativi al sistema operativo e all’ambiente
          informatico dell’utente]. Tali dati vengono acquisiti anche in
          mancanza di registrazione al Sito o richiesta di informazioni.
        </Text>
        <br />
        <br />
        <Text>
          I dati di navigazione vengono utilizzati esclusivamente in maniera
          aggregata per elaborare statistiche anonime sulla consultazione del
          Sito e per controllarne il corretto funzionamento e non consentono di
          identificare gli utenti interessati, venendo, inoltre, cancellati
          subito dopo l'elaborazione in forma anonima.
        </Text>
        <br />
        <br />
        <Text>
          Possono, tuttavia, essere utilizzate per l’accertamento di
          responsabilità in caso di reati informatici compiuti ai danni del sito
          web.
        </Text>

        <Title level={4} className="policy-title mt-20">
          III. Dati forniti volontariamente dall’utente
        </Title>
        <Text>
          I dati personali forniti volontariamente dall’Utente (come ad esempio
          nome, cognome, numero di telefono, indirizzo e-mail) allo scopo di
          inviare messaggi al Sito e/o usufruire dei servizi messi a
          disposizione sono utilizzati al solo fine di rispondere alle esigenze
          dall’interessato e per ottemperare agli obblighi di legge e verranno
          automaticamente eliminati al termine dell’utilizzo per tali finalità.
          La Ditta, infatti, non conserva i dati personali forniti
          volontariamente dall’Utente.
        </Text>
        <br />
        <br />
        <Text>
          La base giuridica di tali trattamenti è l’adempimento delle
          prestazioni inerenti alle domande formulate, nonché il rispetto di
          obblighi di legge.
        </Text>
        <br />
        <br />
        <Text>
          Le informazioni che l’Utente del Sito riterrà di rendere pubbliche
          tramite i servizi e gli strumenti messi a disposizione dello stesso,
          sono fornite dall’Utente consapevolmente e volontariamente, esentando
          il Sito da qualsiasi responsabilità in merito ad eventuali violazioni
          di leggi.
        </Text>
        <br />
        <br />
        <Text>
          Spetta all’Utente verificare di avere i permessi per l’immissione di
          dati personali di terzi o di contenuti tutelati dalle norme nazionali
          ed internazionali.
        </Text>

        <Title level={4} className="policy-title mt-20">
          IV. Dati raccolti tramite cookie analitici
        </Title>
        <Text>
          Il Sito acquisisce anche dati relativi all’Utente mediante l’utilizzo
          di cookies.
        </Text>
        <br />
        <br />
        <Text>
          Per maggiori informazioni sui dati trattati tramite i cookie, sulle
          tipologie di cookie attive e su come disattivarle si rinvia
          all’informativa cookie.
        </Text>
        <br />
        <br />
        <Text>
          Tali cookie sono utilizzati per tracciare le preferenze di navigazione
          dell’utente e per la raccolta di dati statistici. L’utente può
          disattivare tali cookie accedendo alle impostazioni del proprio
          browser, così come indicato nell’informativa cookie del Sito.
        </Text>

        <br />
        <Title level={4} className="policy-title mt-20">
          ***
        </Title>

        <Title level={4} className="policy-title">
          B - Finalità del trattamento
        </Title>
        <Text>I dati personali raccolti vengono usati per:</Text>
        <ul>
          <li>
            consentire agli Utenti di usufruire sei servizi messi a disposizione
            sul Sito;
          </li>
          <li>
            consentire alla Ditta l’analisi dei documenti inviati e la creazione
            di rapporti di rimborso FBA;
          </li>
          <li>
            rispondere alle richieste di informazioni e assistenza avanzate
            dall’Utente sul Sito ovvero attraverso il numero verde e/o il numero
            WhatsApp messi a disposizione dalla Ditta;
          </li>
          <li>
            ricavare informazioni statistiche anonime sull'uso del portale web;
          </li>
          <li>per controllarne il corretto funzionamento del portale web;</li>
          <li>
            l’invio di comunicazioni e newsletter, sia in formato cartaceo sia
            elettronico, all’indirizzo e-mail fornito dall’Utente: nel caso in
            cui l’utente decida di iscriversi alla newsletter di www.sosfba.it,
            solo in seguito ad un eventuale e specifico consenso, i dati
            personali saranno trattati dal Titolare del trattamento per l’invio
            di comunicazioni commerciali o promozionali, di aggiornamenti
            relativi, ad esempio, a offerte esclusive, eventi speciali e
            promozioni. Per annullare l’iscrizione alla newsletter è sufficiente
            fare clic sull’apposito link di disiscrizione riportato in fondo
            alle e-mail ricevute o scrivendo all’indirizzo www.sosfba.it.
          </li>
          <li>
            l'accertamento di responsabilità in caso di ipotetici reati
            informatici ai danni del sito web;
          </li>
          <li>
            il rispetto di ogni altro obbligo di legge non compreso nelle
            finalità precedenti.
          </li>
        </ul>

        <Text>
          La comunicazione dei dati può essere effettuata solo a seguito di
          richiesta da parte dell'Autorità Giudiziaria nei termini di legge.
        </Text>

        <br />
        <Title level={4} className="policy-title mt-20">
          ***
        </Title>

        <Title level={4} className="policy-title">
          C - Base giuridica del trattamento
        </Title>
        <Text>
          Base giuridica del trattamento dei dati personali è l’adempimento
          delle prestazioni inerenti al rapporto instaurato con la
          sottoscrizione dei Termini e Condizioni, il consenso dell’interessato,
          il rispetto degli obblighi di legge ed il legittimo interesse del
          Titolare del trattamento ad effettuare trattamenti necessari a tali
          finalità.
        </Text>

        <Title level={4} className="policy-title">
          I. Esecuzione di un contratto
        </Title>
        <Text>
          Il Titolare tratta Dati Personali relativi all’Utente quando il
          trattamento è necessario all’esecuzione di un contratto con l’Utente
          e/o all’esecuzione di misure precontrattuali.
        </Text>

        <Title level={4} className="policy-title">
          II. Consenso dell’interessato
        </Title>
        <Text>
          L'invio facoltativo, esplicito e volontario di posta elettronica,
          messaggi o qualsiasi tipo di comunicazioni indirizzate ai recapiti
          indicati su questo Sito comporta la successiva acquisizione
          dell'indirizzo del mittente, del numero di telefono o di eventuali
          altri dati personali che saranno utilizzati per rispondere alle
          richieste. Tale trattamento avviene sulla base del consenso
          dell’interessato.
        </Text>
        <br />
        <br />
        <Text>
          Si assicura che tale trattamento sarà improntato ai principi di
          liceità, correttezza, trasparenza, adeguatezza, pertinenza e necessità
          di cui all'art. 5, paragrafo 1 del GDPR. Specifiche informative di
          sintesi verranno progressivamente riportate o visualizzate nelle
          pagine dei siti predisposte per particolari servizi a richiesta.
        </Text>

        <Title level={4} className="policy-title">
          III. Adempimento di obblighi legali
        </Title>
        <Text>
          Il trattamento dei dati personali potrà avvenire senza consenso
          dell’interessato nel caso in cui il Titolare debba adempiere ad un
          obbligo legale.
        </Text>

        <Title level={4} className="policy-title">
          IV. Legittimo interesse del Titolare del trattamento
        </Title>
        <Text>
          Il Titolare tratta Dati Personali relativi all’Utente nel caso in cui
          il trattamento è necessario per il perseguimento del legittimo
          interesse del Titolare o di terzi.
        </Text>

        <Title level={4} className="policy-title">
          V. Facoltatività del conferimento dei dati
        </Title>
        <Text>
          A parte quanto specificato per l’adempimento del contratto o degli
          obblighi di legge, per i cookie e per i dati di navigazione, l'utente
          è libero di fornire o meno i propri dati personali. Tuttavia, il
          mancato conferimento dei dati potrebbe comportare l'impossibilità di
          ottenere quanto l’esecuzione del servizio.
        </Text>

        <br />
        <Title level={4} className="policy-title mt-20">
          ***
        </Title>

        <Title level={4} className="policy-title">
          D - Modalità e durata del trattamento
        </Title>
        <Text>
          I dati personali sono trattati attraverso strumenti informatici e in
          conformità con il Regolamento UE n. 679/2016.
        </Text>
        <br />
        <br />
        <Text>
          La conservazione dei dati trattati durerà per il tempo necessario alle
          finalità descritte nella presente informativa e, quindi, per il minimo
          tempo necessario ovvero fino ad una esplicita richiesta
          dell’interessato e comunque nel rispetto dei limiti temporali imposti
          dalla legge.
        </Text>
        <br />
        <br />
        <Text>
          Il Titolare si impegna ad adottare tutte le misure di sicurezza
          adeguate a prevenire la perdita e l’alterazione dei dati personali,
          nonché qualunque utilizzo illecito e non autorizzato degli stessi.
        </Text>
        <br />
        <br />
        <Text>
          I dati saranno trattati esclusivamente da soggetti autorizzati dal
          Titolare, tra cui eventuali responsabili del trattamento,
          rappresentanti e soggetti pubblici per l’adempimento degli obblighi
          previsti dalla legge, che svolgono le rispettive attività di
          trattamento in qualità di autonomi titolari.
        </Text>
        <br />
        <br />
        <Text>
          Tra i soggetti autorizzati dal Titolare che potrebbero trattare i dati
          rientrano a titolo esemplificativo: collaboratori reparto commerciale
          e legale, nonché fornitori di servizi tecnici terzi, hosting provider
          e Ditta informatiche (il presente elenco non è da ritenersi
          tassativo). I dati trattati non saranno, comunque, diffusi a
          destinatari indeterminati.
        </Text>
        <br />
        <br />
        <Text>
          Tra i soggetti autorizzati dal Titolare che potrebbero trattare i dati
          per finalità di profilazione rientra la piattaforma di marketing
          online{" "}
          <a
            href="https://it.sendinblue.com/legal/privacypolicy/"
            target="_blank"
            rel="noreferrer"
          >
            “Sendinblue”
          </a>
          .
        </Text>
        <br />
        <br />
        <Text>
          La sicurezza delle informazioni raccolte non può essere garantita da
          eventuali attacchi hacker e, in generale, da violazione delle norme di
          sicurezza messe in atto per la protezione dei dati.
        </Text>
        <br />
        <br />
        <Text>
          In caso di attacchi o violazioni, comunque, gli stessi saranno
          comunicati agli interessati e alle autorità competenti secondo le
          norme di legge.
        </Text>

        <br />
        <Title level={4} className="policy-title mt-20">
          ***
        </Title>

        <Title level={4} className="policy-title">
          E - Luogo del trattamento
        </Title>

        <Text>
          I trattamenti relativi ai servizi del portale web sono effettuati da
          personale identificato ed espressamente designato in funzione delle
          specifiche finalità dei servizi richiesti e sottoscritti.
        </Text>
        <br />
        <br />
        <Text>
          Per i trattamenti in oggetto, il Titolare può avvalersi dell'aiuto di
          aziende esterne, spedizionieri, consulenti, case manager, consorzi,
          fornitori di software e servizi operanti, tramite personale
          identificato e incaricato, nell'ambito delle finalità previste e in
          modo da garantire la massima sicurezza e riservatezza dei dati. Negli
          altri casi i dati personali raccolti non saranno diffusi a terze
          parti, salvo espresso consenso dell’interessato, ad eccezione dei casi
          in cui la comunicazione a soggetti terzi sia necessaria per adempiere
          ad obblighi imposti da leggi, regolamenti o provvedimenti delle
          autorità di controllo, oppure risulti essenziale per tutelare i
          diritti di altri utenti o del sito web stesso.
        </Text>
        <br />
        <br />
        <Text>
          I dati personali verranno trattati e conservati, esclusivamente per le
          finalità sopra indicate e per custodirli e archiviarli in modo sicuro,
          su server remoti gestiti da provider leader del settore che assicurano
          la conformità a elevati standard di protezione in materia di
          trattamento dei dati personali.
        </Text>
        <br />
        <br />
        <Text>
          Ciò potrebbe comportare il trasferimento dei dati in paesi extra-UE,
          in cui potrebbero aver sede tutti o parte dei suddetti server.
        </Text>
        <br />
        <br />
        <Text>
          In particolare, i dati personali potranno essere trasferiti fuori
          dall’Unione Europea presso la Ditta{" "}
          <a
            href="https://it.sendinblue.com/legal/privacypolicy/"
            target="_blank"
            rel="noreferrer"
          >
            “WordPress”
          </a>
          , plug-in per e-commerce utilizzato dal Sito gestito dal Titolare.
        </Text>
        <br />
        <br />
        <Text>
          Il trattamento e la conservazione dei dati da parte del suddetto
          provider avverrà verso un Paese terzo “adeguato” ai sensi della
          decisione assunta dalla Commissione europea, di cui in particolare la
          decisione per l'adeguatezza della protezione fornita dalla legge
          canadese sulla tutela delle informazioni personali e sui documenti
          elettronici (
          <i>
            Canadian Personal Information Protection and Electronic Documents
            Act
          </i>
          ) o di certificazione <i>Privacy Shield</i> (USA), ovvero sulla base
          di un vincolo contrattuale o di clausole contrattuali standard
          approvate dalla Commissione europea, oppure di norme vincolanti
          d’impresa approvate attraverso la specifica procedura di cui all’art.
          47 GDPR.
        </Text>
        <br />
        <br />
        <Text>
          Per il trasferimento dei dati verso paesi extra-UE, di regola non
          occorre attendere l’autorizzazione nazionale del Garante. Tuttavia,
          l’autorizzazione del Garante sarà ancora necessaria se un titolare
          desidera utilizzare clausole contrattuali specifiche, non riconosciute
          come adeguate tramite decisione della Commissione europea oppure
          accordi amministrativi stipulati tra autorità pubbliche.
        </Text>

        <Title level={4} className="policy-title">
          F - Diritti degli interessati
        </Title>
        <Text>
          In costanza di trattamento, l'interessato può esercitare, in qualunque
          momento, i seguenti diritti:
        </Text>

        <ul>
          <li>
            ottenere la conferma dell'esistenza o meno dei medesimi dati e, in
            caso positivo, conoscerne il contenuto e l'origine;
          </li>
          <li>
            verificarne l'esattezza chiedere la rettifica dei dati inesatti,
            l'integrazione dei dati incompleti o l'aggiornamento dei dati
            vetusti;
          </li>
          <li>
            ottenerne la limitazione del trattamento, ove ricorra una delle
            ipotesi previste dall'articolo 18 GDPR;
          </li>
          <li>
            chiedere la cancellazione dei dati trattati in violazione di legge,
            ovvero in presenza di una delle altre condizioni previste
            dall'articolo 17, comma 1, lett. a), b), c), e) ed f) GDPR;
          </li>
          <li>
            opporsi in ogni caso, per motivi legittimi, al loro trattamento,
            ovvero di opporsi al trattamento negli altri casi previsti
            dall'articolo 21, commi 2 e 3 e 22 GDPR;
          </li>
          <li>
            revocare in qualsiasi momento il proprio consenso liberamente dato
            al trattamento dei dati personali per le finalità specificate di
            seguito;
          </li>
          <li>
            ottenere il rilascio dei dati personali oggetto di trattamento in un
            formato compatibile con le applicazioni informatiche standard, per
            permetterne il trasferimento su altre piattaforme di Sua scelta,
            senza frapporre impedimenti alla trasmissione diretta dei dati
            trattati ad altro Titolare del trattamento, ove tale trasmissione
            diretta sia tecnicamente fattibile (c.d. diritto alla portabilità
            dei dati).
          </li>
        </ul>

        <Text>
          Le richieste relative all'esercizio dei suddetti diritti vanno rivolte
          al Titolare via e-mail (supporto@sosfba.it).
        </Text>

        <br />
        <br />
        <Text>
          Nel caso di mancato o parziale riscontro del Titolare alle suddette
          richieste, l'interessato avrà diritto a proporre reclamo al Garante
          per la protezione dei dati personali (www.garanteprivacy.it) o ricorso
          giudiziale nei termini e secondo le modalità previste ai sensi degli
          artt. 77 e ss. Regolamento UE 2016/679 (GDPR).
        </Text>

        <Title level={4} className="policy-title">
          G - Aggiornamenti dell’informativa
        </Title>

        <Text>
          Aggiornamenti normativi futuri potrebbero portare alla modifica
          dell’attuale informativa, caricata sul Sito in data 20.02.2023.
        </Text>

        <br />
        <br />
        <Text>In caso di modifica, il Titolare ne darà notizia sul Sito.</Text>
        <CookiePolicy />
      </Col>
    </Row>
  );
};
