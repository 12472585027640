import React, {useEffect, useState} from "react";
import TableView from "../../Components/TableView";
import AllCasesRows from "./AllCasesRows";
import {ALL_CASES_COLOMNS, ALL_CASES_COLOMNS_USER} from "../../utils/constant";

import {useDispatch, useSelector} from "react-redux";
import {GetAdjustmentsCases} from "../../redux/slices/adjustment-slice";
import {GetStores} from "../../redux/slices/store-slice";

function AllCases() {
  const dispatch = useDispatch();

  const [pageNo, setPageNo] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [size, setSize] = useState(100);
  const [caseType, setCaseType] = useState("All");
  const [storeValue, setStoreValue] = useState("All");
  const [storeDropdownData, setStoreDropdownData] = useState([
    {
      value: "All",
      label: "All",
    },
  ]);
  const[fnsku, setFnsku] = useState('');
  const[transactionItemId, setTransactionItemId] = useState('');
  const[type, setType] = useState('');
  const[documentId, setDocumentId] = useState('');
  const [country, setCountry] = useState('');
  const [activeOptions, setActiveOptions] = useState({});
  const [allColumns, setAllColumns] = useState(ALL_CASES_COLOMNS);
  const [transactionUpdated, setTransactionUpdated] = useState(false);

  useEffect(() => {
    dispatch(GetStores());
  }, []);

  const { adjustments, isLoading, err, total } =
    useSelector((state) => state.adjustment) || {};
  const { stores } = useSelector((state) => state.stores) || {};

  useEffect(() => {
    dispatch(
      GetAdjustmentsCases({
        storeId: storeValue,
        adjustmentType: caseType,
        skip: (pageNo - 1) * size,
        limit: size,
        filters: activeOptions
      })
    );
    if (transactionUpdated) {
      setTransactionUpdated(false);
    }
  }, [pageNo, size, caseType, storeValue, activeOptions, transactionUpdated]);
  


  useEffect(() => {
    if (stores && stores.length) {
      let data = stores.map(({ _id, name, sellerId }) => {
        return {
          value: _id,
          label: name || sellerId,
        };
      });
      data = [{ value: "All", label: "All" }, ...data];
      const tempAllColumns = [...allColumns];
      const index = tempAllColumns.findIndex(item => item.backendKey == 'storeId');
      tempAllColumns[index].selectOptions = data;
      setAllColumns(tempAllColumns);
      setStoreDropdownData(data);
    }
  }, [stores]);

  return (
    <div>
      <TableView
        allColumns={ALL_CASES_COLOMNS_USER}
        setActiveOptions={setActiveOptions}
        activeOptions={activeOptions}
        Rows={AllCasesRows}
        setFnsku={setFnsku}
        setTransactionItemId={setTransactionItemId}
        setType={setType}
        setDocumentId={setDocumentId}
        setCountry={setCountry}
        data={adjustments}
        loading={isLoading}
        setIsModalOpen={setIsModalOpen}
        setPageNo={setPageNo}
        setSize={setSize}
        pageNo={pageNo}
        size={size}
        total={total}
        setTransactionUpdated={setTransactionUpdated}
      />
    </div>
  );
}

export default AllCases;
