import {DATE_OPTIONS, CURRENCY_SYMBOLS, CURRENCY_CODE} from "./constant";
import * as XLSX from 'xlsx/xlsx.mjs';

export const getLocalDate = (date) => {
    if (date) {
        const newDate = new Date(date);
        return newDate.toLocaleDateString(undefined, DATE_OPTIONS);
    }
    return "-";
};

export const getLocalYear = (date) => {
    if (date) {
        const newDate = new Date(date);
        return newDate.getFullYear()
        // return newDate.toLocaleDateString(undefined, DATE_OPTIONS);
    }
    return "-";
};
export const getCurrencySymbolsForMarket = (data = {}) => {
    return data && data.unit ? `${CURRENCY_SYMBOLS[data.unit]} ${data.value}` : `${CURRENCY_SYMBOLS[data.unit] || '€'}0.00`;
};

export const getCurrencySymbols = (data = {}) => {
    const currencies = Object.keys(data)?.map(
        (key) => `${CURRENCY_SYMBOLS[key]} ${data[key]}`
    );
    return currencies.join(", ").toString() || "0";
};


export const getCountrySymbols = (country) => {
    return CURRENCY_SYMBOLS[CURRENCY_CODE[country]];
};

export const getSearchTerm = (backendKey, activeOptions = {}) => {
    return activeOptions && activeOptions[backendKey]?.value || ""
}

export const getIsClearFilter = (activeOptions = {}) => {
    return (
        Object.keys(activeOptions)?.length > 0 &&
        Object.keys(activeOptions).some(
            (it) => activeOptions[it].value || activeOptions[it].activeSort
        )
    );
};

export const transformDataWithColumns = (columns, data) => {
    return data.map(item => {
        const transformedItem = {};
        columns.filter(x => !x.hidden).forEach(column => {
            function extracted() {
                return column.labelValue === "Product"
                    ? "fnsku"

                    : (column.labelValue === "Rimborso Ottenuto" || column.labelValue === 'Estimated reimbursement'
                        ? (column.labelValue === "Rimborso Ottenuto" ? {array: ['amountReimbursed', 'EUR']} : {array: ['estimatePrice', 'value']})
                        : column.backendKey);
            }

            if (column.backendKey) {
                const ex = extracted()
                if (typeof ex === 'object') {
                    transformedItem[column.labelValue] = item[ex.array[0]] ? item[ex.array[0]][ex.array[1]] : 0;
                } else {
                    transformedItem[column.labelValue] = item[ex];

                }
            }
        });
        return transformedItem;
    });
};

export const downloadXlsx = (e, data, filename) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AllCases");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, filename);
};