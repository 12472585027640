import React from "react";
import "./PaymentSetting.css";
import { Button } from "antd";

function PaymentTile({ title, items, type, user, handleOpen }) {
  return (
    <div className="payment_tile_container">
      <div className="tile_tile">{title}</div>
      <div className="payment_tile_wrapper">
        {items.map((item) => {
          return (
            <div className="tile_items">
              <div style={{ width: "200px" }}>{item.name}</div>
              <div className={`tile_item2 ${item.color ? "color" : ""}`}>
                {item.value}
              </div>
            </div>
          );
        })}
        {/*{user && user.payment && user.payment.stripeUserId && (*/}
        {user && (
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              ghost
              // style={{ width: "100px" }}
              onClick={() => handleOpen(type)}
            >
              Update{" "}
              {type == "billing_information"
                ? "Billing Information"
                : "Payment Information"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentTile;
