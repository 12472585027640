import React, {useEffect, useState} from "react";
import {Col, Row, Input, Divider, Button} from "antd";
import {
    useSelector
} from "react-redux";
import CountrySelector from "./CountrySelector.js";
import axios from "axios";
import {Contacts} from "./Contacts.jsx";
import {toast} from "react-toastify";

function BillingAddress({
                            payment,
                            setPayment,
                            blankInput,
                            isDisabled,
                            contactInfo,
                            setContactInfo
                        }) {
    const [vat, setVat] = useState("")
    const [hiddenForm, setHiddenForm] = useState(true)
    const fetchBillingInfo = async () => {
        axios.get(`/auth/billing-info/${vat}`)
            .then(res => {
                setHiddenForm(false)
                const data = res.data.billingInfo.billingInfo
                setPayment({
                    ...payment,
                    companyName: data.companyName,
                    addressline1: data.address.registeredOffice.streetName,
                    city: data.address.registeredOffice.town,
                    postCode: data.address.registeredOffice.zipCode,
                    province: data.address.registeredOffice.province,
                    vatId: vat,
                    sdi: data.sdiCode,
                    pec: data.pec,
                    country: data.address.registeredOffice.country,
                })
            }).catch(x => {
            setHiddenForm(false)
            toast.error("IVA not found")
        })
    }
    useEffect(() => {
        if (!user?.payment && blankInput) {
            setPayment({});
        }
    }, []);

    const handleInputChange = (key, value) => {
        setPayment({
            ...payment,
            [key]: value
        })
    }

    const getBillingFromVat = async (e) => {
        const vat = e.target.value
        setVat(vat)
    }
    let {user} = useSelector(state => state.auth)
    return (
        <>
            <Row className="mt-3">
                <Col span={6}>Partita IVA</Col>
                <Col span={12}>
                    <Input
                        onChange={getBillingFromVat}
                        value={vat}
                        style={{width: "100%"}}
                        placeholder="Partita IVA - Es. IT13055980018"
                        disabled={isDisabled}
                        min={6}
                    />
                </Col>
                <Col span={6}>
                    <Button
                        disabled={vat.length !== 13}
                        onClick={fetchBillingInfo}
                    >
                        Cerca
                    </Button>
                </Col>
            </Row>
            <Divider/>
            <Row className="mt-3">
                <Col span={6}>Nome Azienda *</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('companyName', e.target.value)}
                        value={payment?.companyName}
                        style={{width: "134%"}}
                        disabled={isDisabled || hiddenForm}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Indirizzo *</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('addressline1', e.target.value)}
                        value={payment?.addressline1}
                        style={{width: "134%"}}
                        disabled={isDisabled || hiddenForm}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Città *</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('city', e.target.value)}
                        value={payment?.city}
                        style={{width: "134%"}}
                        disabled={isDisabled || hiddenForm}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Postcode (CAP) *</Col>
                <Col span={12}>
                    <Input
                        value={payment?.postCode}
                        onChange={(e) => handleInputChange('postCode', e.target.value)}
                        type="text"
                        style={{width: "134%"}}
                        disabled={isDisabled || hiddenForm}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Provincia *</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('province', e.target.value)}
                        value={payment?.province}
                        type="text"
                        style={{width: "134%"}}
                        disabled={isDisabled || hiddenForm}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Nazione *</Col>
                <Col span={12}>
                    <CountrySelector setPayment={handleInputChange}
                                     isDisabled={isDisabled || hiddenForm}
                                     initValue={payment?.country}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Partita IVA *</Col>
                <Col span={12}>
                    <Input
                        required
                        onChange={(e) => handleInputChange('vatId', e.target.value)}
                        value={payment?.vatId}
                        style={{width: "134%"}}
                        disabled={isDisabled || hiddenForm}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Codice Univoco (SDI)*</Col>
                <Col span={12}>
                    <Input
                        required
                        onChange={(e) => handleInputChange('sdi', e.target.value)}
                        value={payment?.sdi}
                        style={{width: "134%"}}
                        disabled={isDisabled || hiddenForm}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>PEC*</Col>
                <Col span={12}>
                    <Input
                        required
                        onChange={(e) => handleInputChange('pec', e.target.value)}
                        value={payment?.pec}
                        style={{width: "134%"}}
                        disabled={isDisabled || hiddenForm}
                    />
                </Col>
            </Row>
            <Divider/>
            <Row>
                <Col>
                    <div style={{fontWeight: "bold", fontSize: "18px"}}>Contact Info</div>
                    <Contacts setContacts={setContactInfo} contacts={contactInfo} isDisabled={false}/>
                </Col>
            </Row>
        </>
    );
}

export default BillingAddress;
