import React, {useState, useEffect} from "react";
import {Checkbox, Input} from "antd";
import {Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import Loader from "../../Components/Spin";
import Logo from "../../Components/Logo";
import {toast} from "react-toastify";

import "./SignUp.css";

import {SignUp} from "../../redux/slices/auth-slice";

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token, user, loading, err, success} = useSelector(
        (state) => state.auth
    );
    const [isDisabled, setIsdisabled] = useState(true);
    const [vat, setVat] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [privacy, setPrivacy] = useState(false);
    const [privacyError, setPrivacyError] = useState("");
    const [error, setError] = useState('');

    useEffect(() => {
        if (email && password && confirmPassword && password === confirmPassword) {
            setIsdisabled(false);
        } else {
            setIsdisabled(true);
        }
    }, [email, password, confirmPassword]);

    useEffect(() => {
        if (err && !isDisabled) {
            toast.error(err, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [err]);

    useEffect(() => {
        if (token) {
            navigate("/new-cases");
        }
    }, [token]);

    const onPrivacyChange = (e) => {
        if (privacyError && e?.target?.checked) {
            setPrivacyError("");
        }
        setPrivacy(e?.target?.checked);
    };

    const registerUser = () => {
        if (!privacy) {
            setPrivacyError("Please accept privacy policy before continuing");
            return;
        }
        const lowerCaseEmail = email.toLowerCase();
        if (isValidEmail(lowerCaseEmail)) {
            dispatch(SignUp({email: lowerCaseEmail, password, privacy, vat}));
        } else {
            console.log('Invalid email')
        }
    };

    const errorMessage = 'Invalid email';
    const isValidEmail = (email) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i;
        return emailPattern.test(email);
    };

    const changeEmail = (e) => {
        const {value: inputValue} = e.target;
        const newValue = inputValue.replace(/\s/g, "");
        setEmail(newValue);
        if (!isValidEmail(newValue)) {
            setError(errorMessage);
        } else {
            setError('');
        }
    };

    const changePassword = (e) => {
        const {value: inputValue} = e.target;
        const newValue = inputValue.replace(/\s/g, "");
        setPassword(newValue);
    };

    const changeConfirmPassword = (e) => {
        const {value: inputValue} = e.target;
        const newValue = inputValue.replace(/\s/g, "");
        setConfirmPassword(newValue);
    };

    return (
        <div className="containerSign">
            <div className="signup_container">
                <div className="signup_wrapper">
                    <Logo/>
                    <h4 className="signup_heading">CREATE ACCOUNT</h4>
                    <Input
                        size="large"
                        type={"email"}
                        placeholder="Email"
                        className="mt-2"
                        value={email}
                        status={error !== '' ? 'error' : ""}
                        onChange={changeEmail}
                    />
                    {/*<Input*/}
                    {/*    size="large"*/}
                    {/*    type={"text"}*/}
                    {/*    placeholder="Partita IVA - Es. IT13055980018"*/}
                    {/*    className="mt-2"*/}
                    {/*    value={vat}*/}
                    {/*    status={error !== '' ? 'error' : ""}*/}
                    {/*    onChange={(e) => setVat(e.target.value)}*/}
                    {/*/>*/}
                    <Input.Password
                        size="large"
                        placeholder="Password"
                        className="mt-3"
                        value={password}
                        onChange={changePassword}
                    />
                    <Input.Password
                        size="large"
                        placeholder="Confirm Password"
                        className="mt-3"
                        value={confirmPassword}
                        onChange={changeConfirmPassword}
                    />
                    <Button
                        block
                        size="large"
                        className="mt-3"
                        disabled={isDisabled}
                        onClick={registerUser}
                    >
                        {loading ? <Loader/> : "SIGN UP"}
                    </Button>
                    <Checkbox className="mt-3" onChange={onPrivacyChange} checked={privacy}>
                        <div className=" accept-policies">
                            Accept{" "}
                            <Link to="/privacy-policy" target="_blank">
                                Privacy Policy
                            </Link>{" "}
                            and{" "}
                            <Link to="/termini-e-condizioni" target="_blank">
                                Terms of service
                            </Link>
                        </div>
                    </Checkbox>
                    {error !== '' && (
                        <p style={{color: "red", fontSize: 14}}>{error}</p>
                    )}
                    {privacyError !== '' && (
                        <p style={{color: "red", fontSize: 14}}>{privacyError}</p>
                    )}


                    <div className="signup_forgot_password">
                        <Button
                            type="link"
                            className="mt-4"
                            onClick={() => {
                                navigate("/signin");
                            }}
                        >
                            Do you already have an account?
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;