import {Modal, Select} from "antd";
import * as PropTypes from "prop-types";
import React from "react";


const AmazonAccount = (props) => {
    return <>
        {props.user && props.user?.role !== "Admin" && props.user?.role !== "SuperAdmin" && props.user?.role !== "Operator" && props.user?.role !== "Seller" ?
            <Modal
                title="Add your Amazon Account"
                open={props.open}
                onOk={props.onOk}
                okText="Connect"
                closable={false}
                cancelButtonProps={{style: {display: "none"}}}
            >
                <p>Follow these steps before starting:</p>
                <ol>
                    <li>Choose your Amazon Account's region and click on connect</li>
                    <li>
                        <b>Wait 5 minute</b> while the software identifies your possible reimbursements
                    </li>
                    <li>
                        Go to <b>"New Cases"</b> to request reimbursement
                    </li>
                </ol>
                <label className="mt-3">Region </label>
                <Select
                    showSearch
                    placeholder="Select Region"
                    optionFilterProp="children"
                    value={props.value}
                    onChange={props.onChange}
                    filterOption={props.filterOption
                    }
                    options={[
                        {
                            value: "eu",
                            label: "Europe",
                        },
                        {
                            value: "na",
                            label: "North America",
                        },
                        {
                            value: "fe",
                            label: "Japan",
                        },
                    ]}
                    className="store_select_container"
                />
                <div
                    onClick={props.onClick}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        float: "left",
                        cursor: "pointer",
                        marginTop: "10px",
                        borderRadius: "5px",
                        border: "2px solid #e47911",
                        backgroundColor: "transparent",
                        padding: "4px 15px",
                        maxHeight: "32px",
                        maxWidth: "8rem",
                        color: "#e47911",
                        transition: "background-color 0.3s ease",
                    }}
                    onMouseEnter={props.onMouseEnter}
                    onMouseLeave={props.onMouseLeave}
                >
                    Logout
                </div>
            </Modal>
            : <></>}
    </>;
}

AmazonAccount.propTypes = {
    user: PropTypes.any,
    open: PropTypes.bool,
    onOk: PropTypes.func,
    value: PropTypes.string,
    onChange: PropTypes.func,
    filterOption: PropTypes.func,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
};

export default AmazonAccount