import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios-configuration';

const axios = axiosBaseUrl();

export const GetUsers = createAsyncThunk(
  'auth/get-users',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/auth/get-users');
      const t = response.data

      return {...t, users: t.users.map(x => ({
          ...x,
          storeNames: JSON.stringify(x.stores.map(x => x.name))
              .replaceAll('\"','')
              .replaceAll('[','')
              .replaceAll(']',''),
          businessName: x.payment?.address?.companyName
        }))}
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateUserCommission = createAsyncThunk(
  'auth/update-user',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/update-user', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const admin = createSlice({
  name: 'admin',
  initialState: {
    loading: false,
    total: '',
    users: [],
    success: false,
  },
  reducers: {
    SetAdminState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [GetUsers.pending]: (state, action) => ({
      ...state,
      loading: true,
      err: ""
    }),
    [GetUsers.fulfilled]: (state, action) => ({
      ...state,
      users: action.payload.users,
      total: action.payload.total,
      loading: false,
      err: ""
    }),
    [GetUsers.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error || "Network error",
      success: false,
      loading: false
    }),
  }
});

const { reducer, actions } = admin;

export const {
  SetAdminState
} = actions;

export default reducer;
