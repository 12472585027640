import React, {useState, useEffect, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment";
import {useNavigate} from "react-router-dom";

import {
    Modal,
    Divider,
    Col,
    Row,
    List,
    Typography,
    message,
    Tabs,
    Input,
    Button,
    Collapse, Checkbox,
} from "antd";
import "./EditModel.css";
import {
    getLocalDate,
    getCurrencySymbols,
    getCurrencySymbolsForMarket,
} from "../../utils/utils";
import {AiOutlineCopy, AiOutlineSend} from "react-icons/ai";
import {CaretRightOutlined} from "@ant-design/icons";
import {ImAttachment} from "react-icons/im";

import {ClaimCase, SetState} from "../../redux/slices/adjustment-slice";
import {SELLER_CENTRAL_URL} from "../../utils/constant";
import {toast} from "react-toastify";

const {Panel} = Collapse;
const {TextArea} = Input;

function EditModel({
                       setIsEditModel,
                       isEditModel,
                       item,
                       isReload,
                       setIsReload,
                   }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [popUpOpened, setPopUpOpened] = useState(false);
    const [caseId, setCaseId] = useState("");
    const [reimbursementId, setReimbursementId] = useState("");
    const [rejectedCase, setRejectedCase] = useState(false)
    const [rejectedCaseReason, setRejectedCaseReason] = useState("")
    const {caseSubmitted, isLoading, errorMessage} =
    useSelector((state) => state.adjustment) || {};

    const removeNonNumericChars = useCallback((value) => {
        return value.replace(/\D/g, "");
    }, []);

    const caseIdChange = useCallback(
        (e) => {
            const {value: inputValue} = e.target;
            const numericValue = removeNonNumericChars(inputValue);
            setCaseId(numericValue);
        },
        [removeNonNumericChars]
    );

    const reimnursementIdChange = useCallback(
        (e) => {
            const {value: inputValue} = e.target;
            const numericValue = removeNonNumericChars(inputValue);
            setReimbursementId(numericValue);
        },
        [removeNonNumericChars]
    );

    const submitCase = (rejectedCase) => {
        if (caseId || reimbursementId || rejectedCase) {
            dispatch(
                ClaimCase({
                    type: item?.type,
                    documentId: item?._id,
                    caseId,
                    reimbursementIds: reimbursementId ? [reimbursementId] : [],
                    rejectedCase,
                    rejectedCaseReason
                })
            ).then((d) => {
                if (d.type === "adjustment/claim-case/rejected") {
                    toast.error("Something went wrong. Please try again!");
                } else if (d.type === "adjustment/claim-case/fulfilled") {
                    toast.success("New case submitted successfully.");
                    setIsEditModel(false);
                    window.location.reload()
                }
            });
        } else {
            setIsEditModel(false);
        }
    };

    useEffect(() => {
        if (caseSubmitted && !popUpOpened) {
            dispatch(SetState({field: "caseSubmitted", value: false}));
            setPopUpOpened(true);
            setIsReload(!isReload);
            // toast.success("New case submitted successfully", {
            //   position: "top-right",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "light",
            // });
            // navigate("/all-cases");
        } else {
            setPopUpOpened(false);
        }
    }, [caseSubmitted]);

    let url = "/help/hub/support/INTENT_DA_WarehouseDamaged_CU";
    if (item?.type === "LOST") {
        url = "/help/hub/solution/WF_WarehouseLostIssuesSerenity";
    }

    return (
        <Modal
            title={`Case ID: ${item?.caseId || "N/A"}`}
            open={isEditModel}
            onCancel={() => {
                setIsEditModel(false);
            }}
            width={750}
            footer={null}
        >
            <Divider/>
            <Row>
                <Col span={12}>Status</Col>
                <Col span={12}>
                    {item?.status === "TO_BE_CLAIMED" ||
                    item?.status === "TO_BE_CHECKED" ||
                    !item?.status
                        ? "REIMBURSEABLE"
                        : item?.status}
                </Col>
            </Row>
            <Row>
                <Col span={12}>Account</Col>
                <Col span={12}>{item?.storeName || "N/A"}</Col>
            </Row>
            <Row>
                <Col span={12}>Marketplace</Col>
                <Col span={12}>{item?.country || "N/A"}</Col>
            </Row>
            <Row>
                <Col span={12}>Case type</Col>
                <Col span={12}>{item?.type || "N/A"}</Col>
            </Row>
            <Row>
                <Col span={12}>Estimated reimbursement</Col>
                <Col span={12}>
                    {getCurrencySymbolsForMarket(item?.estimatePrice) || "N/A"}
                </Col>
            </Row>
            <Row>
                <Col span={12}>Creation Date</Col>
                <Col span={12}>{getLocalDate(item?.date || "")}</Col>
            </Row>
            {/* <Row>
        <Col span={12}>Expiry period</Col>
        <Col span={12}>
          {getLocalDate(moment().add(item?.expiresIn).toDate() || "")}
        </Col>
      </Row> */}
            <Row>
                <Col span={12}>FNSKU</Col>
                <Col span={12}>{item?.fnsku || "N/A"}</Col>
            </Row>
            {/* <Row>
        <Col span={12}>ASIN</Col>
        <Col span={12}>{item?.asin || "N/A"}</Col>
      </Row> */}
            <Row>
                <Col span={12}>
                    <b>SKU</b>
                </Col>
                <Col span={12}>
                    <b>{item?.sellerSku || "N/A"}</b>
                </Col>
            </Row>
            <Row>
                <Col span={12}>Quantity</Col>
                <Col span={12}>{item?.totalClaimableUnits || "N/A"}</Col>
            </Row>
            {/* <Row>
        <Col span={12}>FNSKU Price</Col>
        <Col span={12}>
          {getCurrencySymbolsForMarket(item?.estimatePrice) || "N/A"}
        </Col>
      </Row> */}
            <div className="queryContainer">
                {contextHolder}
                <List
                    style={{padding: "20px"}}
                    dataSource={[
                        <span>
              {`Copy/paste the ${
                  item?.type === "LOST"
                      ? `FNSKU:  ${item?.fnsku}`
                      : `Transaction ItemID:  ${item?.referenceId}`
              }`}
                            {/* Copy/paste the FNSKU: {item.fnsku}{" "} */}
                            <AiOutlineCopy
                                className="copyIcon"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        item?.type === "LOST" ? item.fnsku : item?.referenceId
                                    );
                                    messageApi.open({
                                        type: "success",
                                        content: `${
                                            item?.type === "LOST" ? item.fnsku : item?.referenceId
                                        } copied to clipboard`,
                                    });
                                }}
                            />
            </span>,
                        <span>
              Please open a case on{" "}
                            <span>
                <a
                    target="_blank"
                    href={`${
                        SELLER_CENTRAL_URL[item?.country] ||
                        "https://sellercentral.amazon.it"
                    }${url}`}
                    className="queryLink"
                >
                  Amazon in SellerCentral
                </a>
              </span>
            </span>,
                        // `Choose the SKU: ${item?.sellerSku}`,
                        <span>
              You received either a case ID or reimbursement ID. Please select
              the exact ID type and copy it to the field below
            </span>,
                    ]}
                    renderItem={(item, index) => (
                        <List.Item>
                            <Typography.Text mark>{index + 1})</Typography.Text> {item}
                        </List.Item>
                    )}
                />
                {!item?.reimbursementId && !item?.amazonCaseId ? (
                    <Tabs
                        style={{margin: "15px 25px"}}
                        defaultActiveKey="1"
                        onChange={(key) => {
                            setCaseId("");
                            setReimbursementId("");
                        }}
                        items={[
                            {
                                label: `Case ID`,
                                key: "1",
                                children: (
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                    <Input
                        placeholder="Enter Amazon Case Id"
                        size="large"
                        value={caseId}
                        onChange={caseIdChange}
                    />{" "}
                  </span>
                                ),
                            },
                            {
                                label: `Reimbursement ID`,
                                key: "2",
                                children: (
                                    <span
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                    <Input
                        placeholder="Enter Amazon Reimbursement ID"
                        size="large"
                        value={reimbursementId}
                        onChange={reimnursementIdChange}
                    />{" "}
                                        <Button
                                            type="primary"
                                            style={{
                                                marginLeft: "15px",
                                            }}
                                            onClick={() => submitCase()}
                                        >
                      Submit
                    </Button>
                  </span>
                                ),
                            },
                        ]}
                    />
                ) : (
                    <></>
                )}
                {/*<Row style={{padding: "0px 25px"}}>*/}
                {/*    <Col md={4}>*/}
                {/*        <Button onClick={() => setRejectedCase(!rejectedCase)}*/}
                {/*                style={{backgroundColor: "red", color: 'white'}}>*/}
                {/*            Rejected*/}
                {/*        </Button>*/}
                {/*    </Col>*/}
                    {/*<Col md={8}>*/}
                    {/*    /!*<div style={{ textAlign: 'left' }}>*!/*/}
                    {/*    /!*    <label>Reason</label>*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*    <Input*/}
                    {/*        disabled={!rejectedCase}*/}
                    {/*        placeholder="Enter rejection reason"*/}
                    {/*        value={rejectedCase ? rejectedCaseReason : ''}*/}
                    {/*        onChange={(e) => setRejectedCaseReason(e.target.value)}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                {/*</Row>*/}
            </div>
            <div style={{margin: "0px -25px"}}>
                <Collapse
                    style={{padding: "0px 10px"}}
                    bordered={false}
                    // defaultActiveKey={["1"]}
                    expandIcon={({isActive}) => (
                        <CaretRightOutlined
                            rotate={isActive ? 90 : 0}
                            style={{fontSize: "16px"}}
                        />
                    )}
                    className="site-collapse-custom-collapse"
                >
                    {/* <Panel
            header={<h4>Case Description</h4>}
            key="1"
            className="site-collapse-custom-panel"
          >
            <div style={{ margin: "20px 0px" }}>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <h5 style={{ margin: "0", fontSize: "18px" }}>Case Title</h5>
                <AiOutlineCopy
                  className="copyIcon"
                  onClick={() => {
                    navigator.clipboard.writeText(`FNSKU: ${item?.fnsku}{" "}
                    / SKU: ${item?.sellerSku}`);
                    messageApi.open({
                      type: "success",
                      content: `tile copied to clipboard`,
                    });
                  }}
                />
              </span>
              Please initiate an inventory reconciliation - FNSKU: {item?.fnsku}{" "}
              / SKU: {item?.sellerSku}
            </div>

            <div style={{ margin: "20px 0px" }}>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <h5 style={{ margin: "0", fontSize: "18px" }}>
                  Case Description
                </h5>
                <AiOutlineCopy
                  className="copyIcon"
                  onClick={() => {
                    navigator.clipboard.writeText(`FNSKU: ${item.fnsku}\nUsing this start: ${item?.date} \n Using this end: ${item?.date} \nActual starting inventory: 0 \n(+) Receipts: 25 \n(+) Customer Returns: 0\n(-) Shipments: -11\n(-) Removals: -1\n(+/-) Net Adjustments: -1\n\n(=) Expected Ending Inventory: 12\n(=) Actual Ending Inventory: 12\n(=) Inventory Discrepency: 0\n---------------------------\n(-) Amazon misplaced inventory: -1\n() Pending misplaced inventory processing: 0 \n(+) Found inventory: 0\n(+) Reimbursed misplaced inventory: 0\n\n(=) Misplaced inventory not yet reimbursed: 1\n----------------------------\nNeeds to be reimbursed: \n----------------------------`);
                    messageApi.open({
                      type: "success",
                      content: `description copied to clipboard`,
                    });
                  }}
                />
              </span>
            </div>
            <pre>
              FNSKU: {item.fnsku}<br/>
              Using this start: {item?.date} <br/>
              Using this end: {item?.date} <br/>
              Actual starting inventory: 0 <br/>
              (+) Receipts: 25 <br/>
              (+) Customer Returns: 0<br/>
              (-) Shipments: -11<br/>
              (-) Removals: -1<br/>
              (+/-) Net Adjustments: -1<br/>
              <br />
              (=) Expected Ending Inventory: 12<br />
              (=) Actual Ending Inventory: 12<br />
              <br />
              (=) Inventory Discrepency: 0<br />
              ---------------------------<br/>
              (-) Amazon misplaced inventory: -1<br />
              () Pending misplaced inventory processing: 0 <br />
              (+) Found inventory: 0<br />
              (+) Reimbursed misplaced inventory: 0<br />
              <br />
              (=) Misplaced inventory not yet reimbursed: 1<br />
              ----------------------------<br />
              Needs to be reimbursed: 1<br />
              ----------------------------
            </pre>
          </Panel> */}
                </Collapse>
                <Divider/>
                <Row>
                    <Col md={21}>
                        <Button onClick={() => {
                            setRejectedCase(!rejectedCase)
                            submitCase(!rejectedCase)
                        }}
                                danger
                                type="primary"
                                style={{
                                    marginLeft: "15px",
                                }}>
                            Rejected
                        </Button>
                    </Col>
                    <Col md={1}>
                        <Button
                            type="primary"
                            style={{
                                marginRight: "15px",
                            }}
                            onClick={() => submitCase()}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}

export default EditModel;
