import React, {useEffect, useState} from "react";
import "./Dashboard.css";
import {Card, Col, Row} from "antd";
import axios from "axios";
import {CartesianGrid, XAxis, YAxis, Line, LineChart, Legend, BarChart, Bar, Tooltip} from "recharts";

function AdminDashboard() {
    const [stats, setStats] = useState({})
    const [casesStats, setCasesStats] = useState({})
    const [invoices, setInvoices] = useState({})
    const [chartData, setChartData] = useState([])
    const [casesChartData, setCasesChartData] = useState([])
    const [chartDataCases, setChartDataCases] = useState([])
    const [chartDataInvoices, setChartDataInvoices] = useState([])
    const getStats = async () => {
        await axios.get('/dashboard/admin-dashboard-stats')
            .then(res => {
                setStats(res.data.response);
            })
    }

    const getCasesStats = async () => {
        await axios.get('/dashboard/admin-cases-stats')
            .then(res => {
                setCasesStats(res.data.response);
                setCasesChartData(res.data.response
                    .map(x => ({
                        name: x._id,
                        data: x.numberOfCases,
                        amt: 2400,
                    }))
                    .sort((a, b) => new Date(a.name) - new Date(b.name))
                )
            })
    }

    const getInvoices = async () => {
        await axios.get('/dashboard/invoices-dashboard-stats')
            .then(res => {
                setInvoices(res.data.response);
                const t = res.data.response.invoices?.gpInvoices
                    .filter(x => x.key !== 'undefined')
                    .sort((a, b) => a.key.localeCompare(b.key))
                    .map(x => ({
                        name: x.key,
                        data: x.total.total / 100,
                        amt: 2400,
                    }))
                console.log(t)
                setChartData(t)
                // const f = res.data.response.invoices?.invoices
                //     .sort((a, b) => a.created - b.created)
                //     .map(x => ({
                //         name: x.createdAt,
                //         data: x.total / 100,
                //         amt: 2400,
                //     }))
                // setChartDataInvoices(f)
            })
    }

    useEffect(() => {
        getStats()
        getInvoices()
        getCasesStats()
        // getCasesStatsGraph()
    }, []);

    const transformData = (apiData) => {
        const dataMap = {};

        apiData.forEach(item => {
            const {date, type, count} = item;
            if (!dataMap[date]) {
                dataMap[date] = {date};
            }
            dataMap[date][type] = count;
        });

        return Object.values(dataMap);
    };
    const bordered = true
    return (
        <div className="dashboard_container">
            <h2>Cases</h2>
            <Row>
                {/*<Col span={6}>*/}
                {/*    <Card title="Cases" bordered={bordered} style={{backgroundColor: 'lavender'}}>*/}
                {/*        <Card title="TOTAL" bordered={bordered}>{stats.cases?.total}</Card>*/}
                {/*        <Card title="RESOLVED" bordered={bordered}>{stats.cases?.resolved}</Card>*/}
                {/*        <Card title="REJECTED" bordered={bordered}>{stats.cases?.rejected}</Card>*/}
                {/*        <Card title="PROGRESS" bordered={bordered}>{stats.cases?.progress}</Card>*/}
                {/*        <Card title="Percentage of resolved cases"*/}
                {/*              bordered={bordered}>{stats.cases?.percentage} %</Card>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                {/*<Col span={6}>*/}
                {/*    <Card title="New Cases" bordered={bordered} style={{backgroundColor: 'lavender'}}>*/}
                {/*        <Card title="TOTAL" bordered={bordered}>{stats.newCases?.total}</Card>*/}
                {/*        <Card title="LOST_WAREHOUSE" bordered={bordered}>{stats.newCases?.lost_warehouse}</Card>*/}
                {/*        <Card title="LOST_INBOUND" bordered={bordered}>{stats.newCases?.lost_inbound}</Card>*/}
                {/*        <Card title="DAMAGED_WAREHOUSE" bordered={bordered}>{stats.newCases?.damaged_warehouse}</Card>*/}
                {/*        <Card title="DAMAGED_INBOUND" bordered={bordered}>{stats.newCases?.damaged_inbound}</Card>*/}
                {/*        <Card title="DAMAGED_OUTBOUND" bordered={bordered}>{stats.newCases?.damaged_outbound}</Card>*/}
                {/*        <Card title="DISPOSED" bordered={bordered}>{stats.newCases?.disposed}</Card>*/}
                {/*        <Card title="CUSTOMER_RETURN" bordered={bordered}>{stats.newCases?.customer_return}</Card>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
                <Col span={6}>
                    <Card title="Managed Cases" bordered={bordered} style={{backgroundColor: 'cornsilk'}}>
                        <Card title="TOTAL" bordered={bordered}>{stats.managedCases?.total}</Card>
                        <Card title="RESOLVED" bordered={bordered}>{stats.managedCases?.resolved}</Card>
                        <Card title="REJECTED" bordered={bordered}>{stats.managedCases?.rejected}</Card>
                        <Card title="PROGRESS" bordered={bordered}>{stats.managedCases?.progress}</Card>
                        <Card title="Percentage of resolved cases"
                              bordered={bordered}>{stats.managedCases?.percentage} %</Card>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Managed New Cases" bordered={bordered} style={{backgroundColor: 'cornsilk'}}>
                        <Card title="TOTAL" bordered={bordered}>{stats.managedNewCases?.total}</Card>
                        <Card title="LOST_WAREHOUSE" bordered={bordered}>{stats.managedNewCases?.lost_warehouse}</Card>
                        <Card title="LOST_INBOUND" bordered={bordered}>{stats.managedNewCases?.lost_inbound}</Card>
                        <Card title="DAMAGED_WAREHOUSE"
                              bordered={bordered}>{stats.managedNewCases?.damaged_warehouse}</Card>
                        <Card title="DAMAGED_INBOUND"
                              bordered={bordered}>{stats.managedNewCases?.damaged_inbound}</Card>
                        <Card title="DAMAGED_OUTBOUND"
                              bordered={bordered}>{stats.managedNewCases?.damaged_outbound}</Card>
                        <Card title="DISPOSED" bordered={bordered}>{stats.managedNewCases?.disposed}</Card>
                        <Card title="CUSTOMER_RETURN"
                              bordered={bordered}>{stats.managedNewCases?.customer_return}</Card>
                    </Card>
                </Col>
            </Row>
            <h2>Cases stats by type</h2>
            <Row>
                {stats.casesStats?.map(x =>
                    <Col span={6}>
                        <Card bordered={bordered}>
                            <Card title="Type" bordered={bordered}>{x.type}</Card>
                            <Card title="Quantity" bordered={bordered}>{x.quantity} Units</Card>
                            <Card title="Amount reimbursed" bordered={bordered}>{x.amountReimbursed} €</Card>
                            <Card title="Success percentage" bordered={bordered}>{x.successPercentage ?? 0} %</Card>
                        </Card>
                    </Col>)}
            </Row>
            <h2>Stats</h2>
            <Row>
                <Card title="Average reimbursement" bordered={bordered}>{stats.cases?.avg}</Card>
                <Col span={6}>
                    <Card title="Clients" bordered={bordered}>
                        {stats.clients?.total}
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Stores" bordered={bordered}>
                        {stats.stores?.total}
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Managed Stores" bordered={bordered}>
                        {stats.managedStores?.total}
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Current under threshold amount" bordered={bordered}>
                        {invoices?.invoices?.underThresholdInvoices / 100} €
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Average invoice" bordered={bordered}>
                        {invoices?.invoices?.invoiceStats[0]?.avg / 100} €
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Total invoices" bordered={bordered}>
                        {invoices?.invoices?.invoiceStats[0]?.sum / 100} €
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Highest invoice" bordered={bordered}>
                        {invoices?.invoices?.invoiceStats[0]?.max / 100} €
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Average First Month Invoices" bordered={bordered}>
                        {invoices?.invoices?.avgAmountFirstMonth / 100} €
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Average After First Month Invoices" bordered={bordered}>
                        {invoices?.invoices?.avgTotalAfterFirstMonth / 100} €
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={24}>
                    <Row>
                        {/*<h2>Sales</h2>*/}
                        {/*<LineChart width={700} height={500} data={chartData} accessibilityLayer>*/}
                        {/*    <XAxis dataKey="name"/>*/}
                        {/*    <YAxis/>*/}
                        {/*    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>*/}
                        {/*    <Line type="monotone" dataKey="data" stroke="#8884d8"/>*/}
                        {/*    /!*<Line type="monotone" dataKey="pv" stroke="#82ca9d" />*!/*/}
                        {/*    <Tooltip/>*/}
                        {/*</LineChart>*/}
                        <h2>Cases opened by day</h2>
                        <LineChart width={1400} height={500} data={casesChartData} accessibilityLayer>
                            <XAxis dataKey="name"/>
                            <YAxis/>
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                            <Line type="monotone" dataKey="data" stroke="#8884d8"/>
                            {/*<Line type="monotone" dataKey="pv" stroke="#82ca9d" />*/}
                            <Tooltip/>
                        </LineChart>
                    </Row>
                </Col>
                <Col md={24}>
                    <Row>
                        <Col md={8}>
                            <Card title={"Totale fatture non pagate"} bordered={bordered}>
                                {invoices?.invoices?.gpInvoices.reduce((acc, item) => acc + item.total.total_not_paid, 0) / 100} €
                            </Card>
                        </Col>
                        <Col md={8}>
                            <Card
                                title={"Lordo - Totale fatture da incassare mese corrente"}
                                bordered={bordered}>
                                {(invoices?.invoices?.gpInvoices.reduce((acc, item) => acc + item.total.total_not_paid, 0) - invoices?.invoices?.underThresholdInvoices) / 100} €
                            </Card>
                        </Col>
                        <Col md={8}>
                            <Card
                                title={"Netto - Totale fatture da incassare mese corrente"}
                                bordered={bordered}>
                                {(invoices?.invoices?.gpInvoices.reduce((acc, item) => acc + item.total.total_not_paid, 0) - invoices?.invoices?.underThresholdInvoices) / 122} €
                            </Card>
                        </Col>
                    </Row>
                    {invoices?.invoices?.gpInvoices && invoices.invoices?.gpInvoices
                        .filter(x => x.key !== 'undefined')
                        .sort((a, b) => b.key.localeCompare(a.key))
                        .map(x => {
                            return <Col span={24}>
                                <Row>
                                    <Col md={4}>
                                        <Card title={"Invoicing Period"} bordered={bordered}>
                                            {x.key}
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card title={"Total"} bordered={bordered}>
                                            {x.total.total / 100} €
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card title={"Total Paid"} bordered={bordered}>
                                            {x.total.total_paid / 100} €
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card title={"Total not paid"} bordered={bordered}>
                                            {x.total.total_not_paid / 100} €
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card title={"Net"} bordered={bordered}>
                                            {x.total.total_excluding_tax / 100} €
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card title={'VAT'} bordered={bordered}>
                                            {x.total.tax / 100} €
                                        </Card>
                                    </Col>
                                    <Col md={4}>
                                        <Card title={'Number of invoices'} bordered={bordered}>
                                            {x.total.numberOfElements}
                                        </Card>
                                    </Col>
                                    <Col md={4}>
                                        <Card title={'Number of Reimbursements'} bordered={bordered}>
                                            {x.total.numberOfReimbursements}
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        })}
                </Col>
            </Row>
        </div>
    );
}

export default AdminDashboard;
