import React from "react";

import {useNavigate} from "react-router-dom";

import "./AllCases.css";
import {
    getLocalDate,
    getCurrencySymbols,
    getCurrencySymbolsForMarket,
} from "../../utils/utils";
import {Tooltip, Button} from "antd";
import {useSelector} from "react-redux";
import {AiOutlineEdit} from "react-icons/ai";
import {useState} from "react";
import {isEmpty} from "lodash";

import {SELLER_CENTRAL_URL} from "../../utils/constant";
import EditModalAllCases from "../../Components/EditModel/EditModalAllCases";
import CaseDetailModal from "../AdminAllCases/CaseDetailModal";

function AllCasesRows({
                          setTransactionUpdated,
                          index,
                          item
                      }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState();
    const {user} = useSelector((state) => state.auth);
    const isAdmin = user?.role === "Admin";
    const [isEditModel, setIsEditModel] = useState(false);
    const [payment, setPayment] = useState({});


    const handleClose = () => {
        if (!isEmpty(payment) && user.payment) {
            setOpen(false);
        }
    };


    const editAllCases = () => {
        if (!user?.payment) {
            setOpen(true);
        } else {
            setIsEditModel(true);
        }
    };

    const rows = [
        {
            className: "table_items",
            width: "100px",
            labelValue: item?.isInput ? <input className="w_70"/> : item?._id,
            // isAdminOnly: true,
        },
        // {
        //   className: "table_items",
        //   width: "100px",
        //   labelValue: "-",
        // },
        // {
        //   className: "table_items",
        //   width: "160px",
        //   labelValue: `${item?.closeAutomatically} days` || "-",
        // },
        {
            className: "table_items",
            width: "300px",
            labelValue: getLocalDate(item?.date || ""),
        },
        {
            className: "table_items",
            width: "160px",
            labelValue: item?.type,
        },
        {
            className: "table_items",
            width: "80px",
            labelValue: item?.totalClaimableUnits,
        },
        {
            className: "table_items",
            width: "110px",
            labelValue: item?.storeName || "-",
        },
        {
            className: "table_items",
            width: "100px",
            labelValue: item?.country,
        },
        {
            className: "table_items",
            width: "120px",
            labelValue: item?.sellerSku,
        },
        {
            className: "table_items",
            width: "120px",
            labelValue: item?.fnsku,
        },
        {
            className: "table_items",
            width: "120px",
            labelValue: (
                <>
                    {item?.isInput ? <input className="w_70"/> : ""}
                    <span>
            <a
                target="_blank"
                href={`${SELLER_CENTRAL_URL[item?.country] ||
                "https://sellercentral.amazon.it"
                }/cu/case-dashboard/view-case?ref=sc_cd_lobby_vc_v3&ie=UTF&caseID=${item?.amazonCaseId
                }&View+or+Respond=Submit`}
                className="queryLink"
            >
              {item?.amazonCaseId}
            </a>
          </span>
                </>
            ),
        },
        {
            className: "table_items",
            width: "120px",
            labelValue:  <a
                target="_blank"
                href={`${SELLER_CENTRAL_URL[item?.country] ||
                "https://sellercentral.amazon.it"
                }/reportcentral/REIMBURSEMENTS/0/%7B"filters":["","","${item.reimbursementIds[0]}",""],"pageOffset":1,"searchDays":365%7D`}
                className="queryLink">
                {item.reimbursementIds[0]}
            </a>,
        },
        {
            className: "table_items",
            width: "300px",
            labelValue: getLocalDate(item?.dateOfCaseSubmitted || ""),
        },
        {
            className: "table_items",
            width: "300px",
            labelValue: getLocalDate(item?.dateOfReimbursement || ""),
        },
        {
            className: "table_items",
            width: "180px",
            labelValue: getCurrencySymbols(item?.amountReimbursed),
        },
        {
            className: "table_items",
            width: "120px",
            labelValue: item?.status,
        },
    ];
    return (
        <tr key={index}>
            {rows.map((row) => {
                return row.isAdminOnly ? (
                    isAdmin && (
                        <td className="responsivePadding">
                            <div className={row.className} style={{width: row.width,overflow: "auto"}}>
                                {row.labelValue || row.toolTip}
                            </div>
                        </td>
                    )
                ) : row.isUserOnly ? (
                    !isAdmin && (
                        <td className="responsivePadding">
                            <div className={row.className} style={{width: row.width,overflow: "auto"}}>
                                {row.labelValue || row.toolTip}
                            </div>
                        </td>
                    )
                ) : (
                    <td className="responsivePadding">
                        <div className={row.className} style={{width: row.width,overflow: "auto"}}>
                            {row.labelValue || row.toolTip}
                        </div>
                    </td>
                );
            })}
            {<EditModalAllCases
                isEditModel={isEditModel}
                setIsEditModel={setIsEditModel}
                item={item}
                setTransactionUpdated={setTransactionUpdated}
            />}
        </tr>
    );
}

export default AllCasesRows;
