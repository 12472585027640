import React, {useState} from "react";
import {Button, Tooltip} from "antd";

const CopyTooltip = ({text, textToCopy}) => {
    const [copied, setCopied] = useState(false)
    return <>
        <Tooltip title={copied ? 'Copied!' : 'Click to copy'}>
            <Button type="text" onClick={() => {
                setCopied(true)
                navigator.clipboard.writeText(textToCopy)
            }}>
                {text}
            </Button>
        </Tooltip>
    </>
}

export default CopyTooltip