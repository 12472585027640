import React from "react";
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { Dropdown } from "antd";
import { CgSortAz } from "react-icons/cg";
import "./TableTh.css";

function SortIcon({ col, setActiveOptions, activeOptions, className }) {
  const isDefaultInput =
    col.inputValue !== "date" && col.inputValue !== "select";
  const getSortIcon = () => {
    switch (activeOptions[col.backendKey]?.activeSort) {
      case "ascending":
        return (
          <AiOutlineSortAscending
            className={className}
            style={{
              ...(isDefaultInput && {
                border: "0.5px solid #d9d9d9",
                borderRight: "none",
                borderBottom: "none",
              }),
            }}
          />
        );
      case "descending":
        return (
          <AiOutlineSortDescending
            className={className}
            style={{
              ...(isDefaultInput && {
                border: "0.5px solid #d9d9d9",
                borderRight: "none",
                borderBottom: "none",
              }),
            }}
          />
        );
      default:
        return (
          <CgSortAz
            className={className}
            style={{
              ...(isDefaultInput && {
                border: "0.5px solid #d9d9d9",
                borderRight: "none",
                borderBottom: "none",
              }),
            }}
          />
        );
    }
  };
  const sortItems = [
    {
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setActiveOptions({
              ...activeOptions,
              [col.backendKey]: {
                ...activeOptions[col.backendKey],
                activeSort: "ascending",
              },
            });
          }}
        >
          <AiOutlineSortAscending /> Ascending
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setActiveOptions({
              ...activeOptions,
              [col.backendKey]: {
                ...activeOptions[col.backendKey],
                activeSort: "descending",
              },
            });
          }}
        >
          <AiOutlineSortDescending /> Descending
        </div>
      ),
      key: "1",
    },
      {
          label: (
              <div
                  onClick={(e) => {
                      e.stopPropagation();
                      setActiveOptions({
                          ...activeOptions,
                          [col.backendKey]: {
                              ...activeOptions[col.backendKey],
                              activeSort: "",
                          },
                      });
                  }}
              >
                  X - Pulisci ordinamento
              </div>
          ),
          key: "2",
      },
  ];

  return col.hasSort ? (
    <Dropdown
      menu={{
        items: sortItems,
      }}
      trigger={["click"]}
      open={col.sortDropdownOpen}
    >
      {getSortIcon()}
    </Dropdown>
  ) : (
    <div
      className={className}
      style={{
        border: "0.5px solid #d9d9d9",
        borderRight: "none",
        borderBottom: "none",
      }}
    ></div>
  );
}

export default SortIcon;
