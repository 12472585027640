import {Modal} from "antd";
import * as PropTypes from "prop-types";
import React from "react";

const AccountBlocked =  (props) => {
    return <Modal
        title="Your Account is blocked"
        open={props.open}
        closable={false}
        cancelButtonProps={{style: {display: "none"}}}
        okButtonProps={{style: {display: "none"}}}
    >
        <p>
            Your account has been blocked, for further assistance contact <a
            href="mailto:supporto@sosfba.it">supporto@sosfba.it</a>.
        </p>
        <br/>
        <div
            onClick={props.onClick}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                float: "left",
                cursor: "pointer",
                marginTop: "10px",
                borderRadius: "5px",
                border: "2px solid #e47911",
                backgroundColor: "transparent",
                padding: "4px 15px",
                maxHeight: "32px",
                maxWidth: "8rem",
                color: "#e47911",
                transition: "background-color 0.3s ease",
            }}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            Logout
        </div>
    </Modal>;
}

AccountBlocked.propTypes = {open: PropTypes.bool};
export default AccountBlocked