import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosBaseUrl, setAuthToken } from "../../config/axios-configuration";

const axios = axiosBaseUrl();

export const SignIn = createAsyncThunk(
  "auth/signIn",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/sign-in", data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const SignUp = createAsyncThunk(
  "auth/signUp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post("/auth/sign-up", data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const ForgotPassword = createAsyncThunk(
  'auth/forgot-password',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/forgot-password', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const ResetPassword = createAsyncThunk(
  'auth/reset-password',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/reset-password', data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateBillingInformation = createAsyncThunk(
  "auth/update-billing-information",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/stripe/update-billing-information",
        data
      );
      console.log(response)
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const UpdateBillingInformationAdmin = createAsyncThunk(
    "auth/admin/update-billing-information",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.post(
            "/stripe/admin/update-billing-information",
            data
        );
        return response.data;
      } catch (err) {
        if (err.response && err.response.data) {
          return rejectWithValue({
            err: err.response.data,
            status: err.response.status,
          });
        }
        return rejectWithValue({
          err: "Network Error",
        });
      }
    }
);

export const CreateCustomer = createAsyncThunk(
  "stripe/create-customer",
  async (data, { rejectWithValue }) => {
    try {
      const { token, name, billingInformation, vatNumber } = data;
      const response = await axios.post("/stripe/create-customer", {
        token,
        name,
        billingInformation,
        vatNumber,
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status,
          },
        });
      }
      return rejectWithValue({
        err: {
          message: "Network Error",
          status: 502,
        },
      });
    }
  }
);

export const UpdateCustomer = createAsyncThunk(
  "stripe/update-customer",
  async (data, { rejectWithValue }) => {
    try {
      const { token, name, billingInformation, vatNumber, stripeUserId } = data;

      const response = await axios.post("/stripe/update-customer", {
        stripeUserId,
        token,
        name,
        billingInformation,
        vatNumber,
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: {
            message: err.response.data.message,
            status: err.response.status,
          },
        });
      }
      return rejectWithValue({
        err: {
          message: "Network Error",
          status: 502,
        },
      });
    }
  }
);

export const GetUser = createAsyncThunk(
  "auth/get-user",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get("/auth/get-user");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

const auth = createSlice({
  name: "auth",
  initialState: {
    name: "",
    email: "",
    message: "",
    err: "",
    loading: false,
    token: "",
    userId: "",
    user: {},
    success: false,
    customerCreated: false,
    customerUpdated: false,
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    LogOut: (state) => ({
      name: "",
      email: "",
      message: "",
      err: "",
      token: "",
      userId: "",
      success: false,
    }),
  },
  extraReducers: {
    [SignIn.pending]: (state, action) => ({
      ...state,
      loading: true,
      err: "",
    }),
    [SignIn.fulfilled]: (state, action) => ({
      ...state,
      token: action.payload.token,
      user: action.payload.user,
      loading: false,
      err: "",
    }),
    [SignIn.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error || "Network error",
      success: false,
      loading: false,
    }),
    [SignUp.pending]: (state, action) => ({
      ...state,
      loading: true,
      err: "",
    }),
    [SignUp.fulfilled]: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      setAuthToken(action.payload.token);
      state.success = true;
      state.err = "";
      state.loading = false;
    },
    [SignUp.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error || "Network error",
      success: false,
      loading: false,
    }),
    [CreateCustomer.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true,
    }),
    [CreateCustomer.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message,
      customerCreated: true,
    }),
    [CreateCustomer.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message,
    }),
    [UpdateCustomer.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true,
    }),
    [UpdateCustomer.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message,
      customerUpdated: true,
    }),
    [UpdateCustomer.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      error: action.payload.err.message,
    }),
    [GetUser.pending]: (state, action) => ({
      ...state,
      loading: true,
      err: "",
    }),
    [GetUser.fulfilled]: (state, action) => ({
      ...state,
      user: action.payload.user,
      loading: false,
      err: "",
    }),
    [GetUser.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error || "Network error",
      success: false,
      loading: false,
    }),
  },
});

const { reducer, actions } = auth;

export const { SetState, LogOut } = actions;

export default reducer;
