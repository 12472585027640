import {Button, Col, Divider, Modal, Row} from "antd";
import React, {useEffect, useState} from "react";
import BillingAddress from "../PaymentSetting/BillingAddress";
import {UpdateBillingInformationAdmin} from "../../redux/slices/auth-slice";
import {useDispatch} from "react-redux";
import UserInfo from "../PaymentSetting/UserInfo.js";
import {toast} from "react-toastify";
import axios from "axios";
import ServicesManagement from "../../Components/ServicesManagement";

export const UserModal = ({user, isModalOpen, onCancel, closeModal}) => {
    const dispatch = useDispatch();
    const [billingDetails, setBillingDetails] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [contactInfo, setContactInfo] = useState({});
    const [verified, setVerified] = useState(false);
    const [stores, setStores] = useState([])
    const [textModal, setTextModal] = useState(false);
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
    const [modalCallback, setModalCallback] = useState(null);
    const [modalCheckValue, setModalCheckValue] = useState(false);
    const [modalEmail, setModalEmail] = useState("");
    const [storeInfo, setStoreInfo] = useState({})
    useEffect(() => {
        axios.get(`/store/stores/${user?._id}`).then(x => setStores(x.data.stores))
    }, [user]);


    const sendEmail = async (record, userId) => {
        showConfirmationModal(async () => {
            try {
                axios
                    .get("/auth/email/tec/" + userId)
                    .then(r => toast.success("Email sent successfully."))
                    .catch(err => {
                        console.error(err)
                        toast.error(err.message);
                        toast.success("Error - email not sent.");
                    })

            } catch (err) {
                toast.error(err.message);
            }
        }, record.email, "", <>Vuoi davvero l'email dei termini e condizioni all'email {record.email}?</>);
    }

    const showConfirmationModal = (callback, email, fieldValue, text) => {
        setTextModal(text)
        setIsConfirmationModalVisible(true);
        setModalCallback(() => callback);
        setModalEmail(email);
        setModalCheckValue(fieldValue);
    }

    const saveUserBillingDetails = async () => {
        console.log({
            userId: user._id,
            billingInformation: billingDetails,
            userInfo,
            contactInfo,
            vatNumber: billingDetails.vatId,
            verified
        })
        await dispatch(
            UpdateBillingInformationAdmin({
                userId: user._id,
                billingInformation: billingDetails,
                userInfo,
                contactInfo,
                vatNumber: billingDetails.vatId,
                verified,
            })
        );
        closeModal(null);
        window.location.reload()
    }

    useEffect(() => {
        setVerified(user?.billingDetailsVerified)
        setUserInfo(user)
        setContactInfo(user?.contacts)
        const billingInfo = {
            email: user?.email,
            companyName: user?.payment?.address.companyName || "",
            postCode: user?.payment?.address?.postCode || "",
            addressline1: user?.payment?.address?.addressline1 || "",
            addressline2: user?.payment?.address?.addressline2 || "",
            country: user?.payment?.address?.country || "",
            city: user?.payment?.address?.city || "",
            vatId: user?.payment?.vatNumber || "",
            cardHolderName: user?.payment?.cardHolderName,
            sdi: user?.payment?.sdi,
            province: user?.payment?.address?.province || ""
        }
        setBillingDetails(billingInfo);
    }, [user?._id]);

    return (<>
            <Modal key={"confirmationModal"}
                   title="Conferma"
                   destroyOnHide={true}
                   open={isConfirmationModalVisible}
                   onOk={() => {
                       if (modalCallback) modalCallback();
                       setIsConfirmationModalVisible(false);
                       setModalCallback(null);
                   }}
                   onCancel={() => {
                       setIsConfirmationModalVisible(false);
                       setModalCallback(null);
                   }}
            >
                {textModal}
            </Modal>
            <Modal
                key={`User: ${user?.email}`}
                title={`Modifica utente: ${user?._id}`}
                open={isModalOpen}
                onCancel={onCancel}
                footer={null}
                width="auto"
            >
                <div>
                    <Row>
                        <Col md={8}>
                            <h3><strong>Info</strong></h3>
                            <UserInfo
                                setInfo={setUserInfo}
                                info={userInfo}
                                isDisabled={false}
                                blankInput={false}
                                isAdmin={true}
                                email={user?.email}
                            />
                        </Col>
                        <Col md={8}>
                            <h3><strong>Billing</strong></h3>
                            <BillingAddress
                                setPayment={setBillingDetails}
                                payment={billingDetails}
                                isDisabled={false}
                                blankInput={false}
                                isAdmin={true}
                                email={user?.email}
                                contactInfo={contactInfo}
                                setContactInfo={setContactInfo}
                            />
                            <Divider/>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Row>
                            <h3><strong>Stores</strong></h3>
                        </Row>
                        <Divider/>
                        {stores && stores.map((store) => (
                            <>
                                <Col md={12}>
                                    <Row>
                                        <Col md={5}>
                                            <label><strong>Name</strong></label>
                                            <p>{store.name}</p>
                                        </Col>
                                        <Col md={5}>
                                            <label><strong>Seller ID</strong></label>
                                            <p>{store.sellerId}</p>
                                        </Col>
                                        <Col md={5}>
                                            <label><strong>Status</strong></label>
                                            <p>{store.status}</p>
                                        </Col>
                                        <Col md={2}>
                                            <label><strong>Region</strong></label>
                                            <p>{store.region}</p>
                                        </Col>
                                        <Col md={5}>
                                            <label><strong>Join Date</strong></label>
                                            <p>{store.createdAt}</p>
                                        </Col>
                                        <Col>
                                            <ServicesManagement record={store} color={'blue'}/>
                                        </Col>
                                        <Divider type={'vertical'}/>
                                    </Row>

                                </Col>
                            </>
                        ))}
                    </Row>
                    <Divider/>
                    <Row>
                        <Col md={6}>
                            <Button disabled={!(userInfo?.producer || userInfo?.reseller)} type="primary" onClick={saveUserBillingDetails}>Submit</Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    );
};
