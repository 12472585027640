import React, {useState} from "react";
import {Checkbox, Switch} from "antd";

export const Check = ({value, callback, color, obj, setter}) => {
    const [checked, setChecked] = useState(value)
    return <>
        <Switch checked={checked} onChange={(e) => {
            setChecked(e)
            callback(e, obj, setter)
        }}/>
        </>
}