import React, { useEffect } from "react";
import { DatePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";

import "./History.css";
import Graph from "./Chart";
import { getGraphData } from "../../../redux/slices/dashboard-slice";

const { RangePicker } = DatePicker;
function History() {
  const dispatch = useDispatch();
  const { amount = [], items = [] } = useSelector(
    (state) => state.dashboardStats?.graphDate || {}
  );

  useEffect(() => {
    dispatch(
      getGraphData({
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().startOf("day").format("YYYY-MM-DD"),
      })
    );
  }, []);

  return (
    <div className="history_container">
      <div className="flex">
        <div className="heading">Reimbursement history</div>
        <div className="flex_statistics">
          <div className="statistics_label">Statistics for</div>

          <RangePicker
            defaultValue={[
              dayjs(
                moment().startOf("month").format("YYYY-MM-DD"),
                "YYYY-MM-DD"
              ),
              dayjs(moment().startOf("day").format("YYYY-MM-DD"), "YYYY-MM-DD"),
            ]}
            onChange={(date, date1) => {
              dispatch(
                getGraphData({
                  startDate: date1[0],
                  endDate: date1[1],
                })
              );
            }}
          />
        </div>
      </div>
      <div className="graph_container">
        <Graph
          dataset1={amount?.map((item) => {
            return {
              x: moment(new Date(item[0])).format("MMM Do"),
              y: item[1].EUR,
            };
          })}
          dataset2={items.map((item) => {
            return {
              x: moment(new Date(item[0])).format("MMM Do"),
              y: item[1],
            };
          })}
        />
      </div>
    </div>
  );
}

export default History;
