import React from "react";

import { useNavigate } from "react-router-dom";

import "./ActionRequired.css";
import {
  getLocalDate,
  getCurrencySymbols,
  getCurrencySymbolsForMarket,
} from "../../utils/utils";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { AiOutlineEdit } from "react-icons/ai";

import { SELLER_CENTRAL_URL } from "../../utils/constant";

function AllCasesRows({
  index,
  item
}) {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const isAdmin = user?.role === "Admin";

  const rows = [
    {
      className: "table_items",
      width: "70px",
      labelValue: (
        <Tooltip
          title={<div style={{ width: "100%", color: "black" }}>Update</div>}
          color="white"
        >
          <AiOutlineEdit
            className="editBtn"
            onClick={() => {
              // setIsEditModel(true);
              navigate("/case-overview", {
                state: { item: item }
              });
            }}
          />
        </Tooltip>
      ),
    },
    {
      className: "table_items",
      width: "100px",
      labelValue: item?.isInput ? <input className="w_70" /> : item?.caseId,
      // isAdminOnly: true,
    },
    {
      className: "table_items",
      width: "100px",
      labelValue: "-",
    },
    {
      className: "table_items",
      width: "160px",
      labelValue: `${item?.closeAutomatically} days` || "-",
    },
    {
      className: "table_items",
      width: "150px",
      labelValue: getLocalDate(item?.date || ""),
    },
    {
      className: "table_items",
      width: "160px",
      labelValue: item?.type,
    },
    {
      className: "table_items",
      width: "80px",
      labelValue: item?.totalClaimableUnits,
    },
    {
      className: "table_items",
      width: "110px",
      labelValue: item?.storeName || "-",
    },
    {
      className: "table_items",
      width: "100px",
      labelValue: item?.country,
    },
    {
      className: "table_items",
      width: "120px",
      labelValue: item?.sellerSku,
    },
    {
      className: "table_items",
      width: "120px",
      labelValue: item?.fnsku,
    },
    // {
    //   className: "",
    //   width: "",
    //   toolTip: (
    //     <Tooltip
    //       title={
    //         <div>
    //           <div className="" style={{ width: "100%", color: "black" }}>
    //             <div>
    //               <span className="tooltip_product_label">Product Name</span>
    //               {item?.title}
    //             </div>
    //             <div className="product_details">
    //               <div style={{ marginRight: "15px" }}>
    //                 <span className="tooltip_product_label">SKU </span>
    //                 {item?.sellerSku || "334334343434"}
    //               </div>
    //               <div>
    //                 <span className="tooltip_product_label">FNSKU </span>
    //                 {item?.fnsku || "23423423423423"}
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       }
    //       color="white"
    //     >
    //       <div className="table_items product_description">
    //         <div>
    //           {item?.title ? `${item.title.substring(0, 30)}......` : ""}
    //         </div>
    //         <div className="product_details">
    //           <div style={{ marginRight: "15px" }}>
    //             <span className="product_label">SKU </span>
    //             {item?.sellerSku || ""}
    //           </div>
    //           <div className="">
    //             <span className="product_label">FNSKU </span>
    //             {item?.fnsku || ""}
    //           </div>
    //         </div>
    //       </div>
    //     </Tooltip>
    //   ),
    // },
    {
      className: "table_items",
      width: "120px",
      labelValue: (
        <>
          {item?.isInput ? <input className="w_70" /> : ""}
          <span>
            <a
              target="_blank"
              href={`${SELLER_CENTRAL_URL[item?.country] ||
                "https://sellercentral.amazon.it"
                }/cu/case-dashboard/view-case?ref=sc_cd_lobby_vc_v3&ie=UTF&caseID=${item?.amazonCaseId
                }&View+or+Respond=Submit`}
              className="queryLink"
            >
              {item?.amazonCaseId}
            </a>
          </span>
        </>
      ),
    },
    {
      className: "table_items",
      width: "150px",
      labelValue: item?.reimbursementIds?.toString(),
    },
    {
      className: "table_items",
      width: "180px",
      labelValue: getLocalDate(item?.dateOfCaseSubmitted || ""),
    },
    {
      className: "table_items",
      width: "180px",
      labelValue: getLocalDate(item?.dateOfReimbursement || ""),
    },
    {
      className: "table_items",
      width: "180px",
      labelValue: getCurrencySymbolsForMarket(item?.estimatedAmount),
    },
    {
      className: "table_items",
      width: "180px",
      labelValue: getCurrencySymbols(item?.amountReimbursed),
    },
    {
      className: "table_items",
      width: "120px",
      labelValue: item?.status,
    },
    {
      className: "table_items",
      width: "120px",
      labelValue: item?.reason || "-",
    },
  ];
  return (
    <tr key={index}>
      {rows.map((row) => {
        return row.isAdminOnly ? (
          isAdmin && (
            <td>
              <div className={row.className} style={{ width: row.width }}>
                {row.labelValue || row.toolTip}
              </div>
            </td>
          )
        ) : row.isUserOnly ? (
          !isAdmin && (
            <td>
              <div className={row.className} style={{ width: row.width }}>
                {row.labelValue || row.toolTip}
              </div>
            </td>
          )
        ) : (
          <td>
            <div className={row.className} style={{ width: row.width }}>
              {row.labelValue || row.toolTip}
            </div>
          </td>
        );
      })}
      {/* <EditModel
        isEditModel={isEditModel}
        setIsEditModel={setIsEditModel}
        item={item}
      /> */}
    </tr>
  );
}

export default AllCasesRows;
