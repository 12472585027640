import React, {useEffect, useState} from "react";
import TableView from "../../Components/TableView";
import AllCasesRows from "./AdminAllCasesRows";
import {ALL_CASES_COLOMNS} from "../../utils/constant";

import {useNavigate, useSearchParams} from "react-router-dom";
import {axiosBaseUrl} from "../../config/axios-configuration";
import {toast} from "react-toastify";
import {Button, Card, Col, Row} from "antd";
import {useSelector} from "react-redux";
import {downloadXlsx, transformDataWithColumns} from "../../utils/utils";

function AdminAllCases() {
    const axios = axiosBaseUrl();
    const [pageNo, setPageNo] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [size, setSize] = useState(100);
    const [caseType, setCaseType] = useState("All");
    const [storeValue, setStoreValue] = useState("All");
    const [storeDropdownData, setStoreDropdownData] = useState([
        {
            value: "All",
            label: "All",
        },
    ]);
    const [fnsku, setFnsku] = useState('');
    const [transactionItemId, setTransactionItemId] = useState('');
    const [type, setType] = useState('');
    const [documentId, setDocumentId] = useState('');
    const [country, setCountry] = useState('');
    const [activeOptions, setActiveOptions] = useState({});
    const [allColumns, setAllColumns] = useState(ALL_CASES_COLOMNS);
    const [transactionUpdated, setTransactionUpdated] = useState(false);
    const [allStores, setAllStores] = useState([]);
    const [adjustments, setAdjustments] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {user} = useSelector((state) => state.auth);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false)
    const [downloadEmail, setDownlaodEmail] = useState("")
    const [userData, setUserData] = useState(null)
    const [storeName, setStoreName] = useState("")

    useEffect(() => {
        if (searchParams.get('user')) {
            getStores(searchParams.get('user'));
        } else {
            // navigate("/users");
        }
    }, []);

    const getStores = async (id) => {
        setIsLoading(true)
        await axios.get("/store/get-all-stores?userId=" + id).then(res => {
            setIsLoading(false)
            if (res.status === 200 && res.data && res.data.allStores) {
                setAllStores(res.data.allStores);
            } else {
                setAllStores([]);
            }
        }).catch(err => {
            setIsLoading(false)
            toast.error(err.message);
            navigate("/users");
        });
    }

    // const { adjustments, isLoading, err, total } =
    //   useSelector((state) => state.adjustment) || {};
    // const { allStores } = useSelector((state) => state.stores) || {};

    useEffect(() => {
        getAdjustments({
            userId: searchParams.get('user'),
            storeId: storeValue,
            adjustmentType: caseType,
            skip: (pageNo - 1) * size,
            limit: size,
            filters: activeOptions
        })
        if (transactionUpdated) {
            setTransactionUpdated(false);
        }
        getUser()
    }, [pageNo, size, caseType, storeValue, activeOptions, transactionUpdated]);

    const getAdjustments = async (params) => {
        setIsLoading(true)
        await axios.get('/cases/adjustment-cases', {
            params
        }).then(res => {
            setIsLoading(false)
            if (res && res.status === 200 && res.data && res.data.success) {
                setAdjustments(res.data.cases);
                setTotal(res.data.total);
            } else {
                setAdjustments([]);
                setTotal(0);
            }
        }).catch(err => {
            setIsLoading(false)
            toast.error(err.message);
            navigate("/users");
        });
    }

    useEffect(() => {
        if (allStores && allStores.length) {
            let data = allStores.map(({_id, name, sellerId}) => {
                return {
                    value: _id,
                    label: name || sellerId,
                };
            });
            data = [{value: "All", label: "All"}, ...data];
            const tempAllColumns = [...allColumns];
            const index = tempAllColumns.findIndex(item => item.backendKey == 'storeId');
            tempAllColumns[index].selectOptions = data;
            setAllColumns(tempAllColumns);
            setStoreDropdownData(data);
        } else {
            let data = [];
            const tempAllColumns = [...allColumns];
            const index = tempAllColumns.findIndex(item => item.backendKey == 'storeId');
            if (index > 1) {
                console.log(index)
                tempAllColumns[index].selectOptions = data;
                setAllColumns(tempAllColumns);
            }
            setStoreDropdownData(data);
        }
    }, [allStores]);

    const getUser = async (params) => {
        setIsLoading(true)
        if (searchParams.get('user')) {
            await axios.get('/auth/user/' + searchParams.get('user'))
                .then(res => {
                    setStoreName(res.data.user.storeName)
                    setUserData(res.data.user)
                }).catch(err => {
                    toast.error(err.message);
                });
        }
    }

    const exportFilenameFirst = "AllCases_"
    const exportFilenameLast = ".xlsx"

    return (
        <div>
            <Card>
                <div>
                    <Row>
                        {
                            searchParams.get('user') ?
                                <Col md={6}>
                                    <p>Store: <strong>{storeName}</strong></p>
                                </Col> : <></>
                        }
                    </Row>
                </div>
            </Card>
            {user.role === 'Admin' ? <Card>
                                <Button
                                    type="primary"
                                    style={{marginLeft: "15px",}}
                                    onClick={(e) => {
                                        setDownloadModalOpen(false)
                                        setDownlaodEmail("")
                                        downloadXlsx(e,transformDataWithColumns(ALL_CASES_COLOMNS, adjustments),exportFilenameFirst + (searchParams.get('user') && searchParams.get('user') !== "" ? userData?.email + '_' + userData?.storeName : "") + exportFilenameLast)
                                    }}>
                                    Download Excel Current Data
                                </Button>
                </Card>
                : <></>}
            <TableView
                allColumns={ALL_CASES_COLOMNS}
                setActiveOptions={setActiveOptions}
                activeOptions={activeOptions}
                Rows={AllCasesRows}
                setFnsku={setFnsku}
                setTransactionItemId={setTransactionItemId}
                setType={setType}
                setDocumentId={setDocumentId}
                setCountry={setCountry}
                data={adjustments}
                loading={isLoading}
                setIsModalOpen={setIsModalOpen}
                setPageNo={setPageNo}
                setSize={setSize}
                pageNo={pageNo}
                size={size}
                total={total}
                setTransactionUpdated={setTransactionUpdated}
            />
        </div>
    );
}

export default AdminAllCases;
