import React, { useState } from "react";
import * as XLSX from "xlsx";
import {Button, Input} from "antd";

function ExcelToJsonConverter({callback}) {
    const [file, setFile] = useState(null);

    const handleConvert = () => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                callback(json)
            };
            reader.readAsBinaryString(file);
        }
    };

    return (
        <div>
            <Input
                type="file"
                accept=".xls,.xlsx"
                onChange={(e) => setFile(e.target.files[0])}
            />
            <Button onClick={handleConvert}>Upload</Button>
        </div>
    );
}

export default ExcelToJsonConverter;