import React, {useState, useEffect} from "react";
import TableView from "../../Components/TableView";
import Rows from "./Rows";
import {NEW_CASE_COLUMNS} from "../../utils/constant";

import {useDispatch, useSelector} from "react-redux";
import {GetNewCases} from "../../redux/slices/adjustment-slice";
import {GetAllStores} from "../../redux/slices/store-slice";

function NewCases() {
    const dispatch = useDispatch();
    const [pageNo, setPageNo] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [size, setSize] = useState(100);
    const [caseType, setCaseType] = useState("All");
    const [storeValue, setStoreValue] = useState("All");
    const [storeDropdownData, setStoreDropdownData] = useState([
        {
            value: "All",
            label: "All",
        },
    ]);
    const [fnsku, setFnsku] = useState("");
    const [transactionItemId, setTransactionItemId] = useState("");
    const [type, setType] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [country, setCountry] = useState("");
    const [row, setRow] = useState({});
    const [activeOptions, setActiveOptions] = useState({});
    const [isReload, setIsReload] = useState(false);
    const [stats, setStats] = useState({});
    const [allColumns, setAllColumns] = useState(NEW_CASE_COLUMNS);
    useEffect(() => {
        dispatch(GetAllStores());
    }, []);

    const {
        newCases,
        isLoading,
        err,
        total,
        totalQuantity,
        totalEstimate
    } = useSelector((state) => state.adjustment) || {};
    const {allStores} = useSelector((state) => state.stores) || {};

    useEffect(() => {
        dispatch(
            GetNewCases({
                storeId: storeValue,
                adjustmentType: caseType,
                skip: (pageNo - 1) * size,
                limit: size,
                filters: activeOptions
            })
        );
    }, [pageNo, size, caseType, storeValue, activeOptions, isModalOpen, isReload]);

    useEffect(() => {
        if (allStores && allStores.length) {
            let data = allStores.map(({_id, name, sellerId}) => {
                return {
                    value: _id,
                    label: name || sellerId,
                };
            });
            data = [{value: "All", label: "All"}, ...data];
            const tempAllColumns = [...allColumns];
            const index = tempAllColumns.findIndex(item => item.backendKey === 'storeId');
            tempAllColumns[index].selectOptions = data;
            setAllColumns(tempAllColumns);
            setStoreDropdownData(data);
        }
    }, [allStores]);

    return (
        <>
            {/*<Card>*/}
            {/*    <div>*/}
            {/*        <Row>*/}
            {/*            <Col md={2}>*/}
            {/*                <h3><strong>Riepilogo casi</strong></h3>*/}
            {/*            </Col>*/}
            {/*            <Col md={2}>*/}
            {/*                <p>Totale prodotti: <strong>{totalQuantity}</strong></p>*/}
            {/*            </Col>*/}
            {/*            <Col md={2}>*/}
            {/*                <p>Stima importo prodotti: <strong>€{totalEstimate}</strong></p>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </div>*/}
            {/*</Card>*/}
            <TableView
                allColumns={NEW_CASE_COLUMNS}
                setActiveOptions={setActiveOptions}
                activeOptions={activeOptions}
                setRow={setRow}
                Rows={Rows}
                setFnsku={setFnsku}
                setTransactionItemId={setTransactionItemId}
                setType={setType}
                setDocumentId={setDocumentId}
                setCountry={setCountry}
                pageNo={pageNo}
                size={size}
                data={newCases}
                loading={isLoading}
                setIsModalOpen={setIsModalOpen}
                setPageNo={setPageNo}
                setSize={setSize}
                total={total}
                setIsReload={setIsReload}
                isReload={isReload}
            />
        </>
    );
}

export default NewCases;
