import {Button, Input} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Logo from "../../Components/Logo";
import {ForgotPassword, ResetPassword as Reset} from "../../redux/slices/auth-slice";

import "./ResetPassword.css";

function ResetPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [resetcode, setResetCode] = useState();
    const [isDisabled, setIsdisabled] = useState(false);
    const [isResetDisabled, setIsResetdisabled] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        setEmailSent(false);
        setMessage("");
    }, []);


    const sendEmail = async () => {
        const lowerCaseEmail = email.toLocaleLowerCase();
        const response = await dispatch(ForgotPassword({email: lowerCaseEmail}));
		if (response?.payload?.success === true) {
            setIsdisabled(true);
            setMessage("");
            setEmailSent(true);
        } else if (response?.payload?.err) {
            setIsdisabled(true);
            setMessage(response?.payload?.err.success);
			setEmailSent(false);
		} else {
            setIsdisabled(true);
            setMessage("Try again");
			setEmailSent(false);
		}
    };

    const resetPassword = async () => {
        const lowerCaseEmail = email.toLocaleLowerCase();
        const response = await dispatch(Reset({resetcode, email: lowerCaseEmail, password}));
        if (response?.payload?.success === true) {
            setIsResetdisabled(true);
            setMessage("Your password has been changed");
        } else if (response?.payload?.err) {
            setIsdisabled(true);
            setMessage(response?.payload?.err);
        } else {
            setIsdisabled(true);
            setMessage("Try again");
        }
    };

    return (
        <div className="containerSign">
            <div className="signup_container">
                <div className="signup_wrapper">
                    <Logo/>
                    <h4 className="signup_heading">RESET PASSWORD</h4>
                    <Input
                        size="large"
                        placeholder="Email"
                        className="mt-2"
                        value={email}
                        disabled={emailSent}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {!emailSent ? (
                        <>
                            <Button
                                block
                                size="large"
                                className="mt-3"
                                disabled={isDisabled}
                                onClick={sendEmail}
                            >
                                {!emailSent ? ("SEND EMAIL") : ("CHECK YOUR EMAIL")}
                            </Button>
                            {message && <p style={{
                                color: "red",
                                fontSize: 14,
                                marginTop: 10,
                                marginBottom: 10,
                                textAlign: "center"
                            }}>{message}</p>}
                        </>
                    ) : (
                        <>
                            <Input
                                size="large"
                                placeholder="Reset Code"
                                className="mt-3"
                                value={resetcode}
                                onChange={(e) => setResetCode(e.target.value)}
                            />
                            <Input.Password
                                size="large"
                                placeholder="Password"
                                className="mt-3"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Input.Password
                                size="large"
                                placeholder="Confirm Password"
                                className="mt-3"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <Button
                                block
                                size="large"
                                className="mt-3"
                                disabled={isResetDisabled}
                                onClick={resetPassword}
                            >
                                RESET PASSWORD
                            </Button>
                            {message && <p style={{
                                color: "black",
                                fontSize: 14,
                                marginTop: 10,
                                marginBottom: 10,
                                textAlign: "center"
                            }}>{message}</p>}
                        </>
                    )}

                    <div className="login_forgot_password">
                        <Button
                            type="link"
                            className="mt-4"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Sign In
                        </Button>
                        <Button
                            type="link"
                            className="mt-4"
                            onClick={() => {
                                navigate("/signup");
                            }}
                        >
                            Create Account
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;