import React, {useState} from "react";
import {Button, Col, Divider, Input, Row} from "antd";
import CopyTooltip from "../../Components/CopyTooltip.jsx";
import {ClaimCase, CreateCase} from "../../redux/slices/adjustment-slice.js";
import {toast} from "react-toastify";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";

const SingleCase = ({x,store}) => {
    const [caseId, setCaseId] = useState(undefined)
    const [additionalCaseId, setAdditionalCaseId] = useState(undefined)
    const [reimbursementId, setReimbursementId] = useState(undefined)
    const [rejected, setRejected] = useState(false)
    const dispatch = useDispatch();

    const {user} = useSelector((state) => state.auth);

    const submitCase = (rejected, storeId, _id) => {
        registerCases({caseId, reimbursementId, additionalCaseId, rejected, user: user.email})
        if (caseId || reimbursementId || rejected) {
            dispatch(
                ClaimCase({
                    type: 'LOST_INBOUND',
                    documentId: _id,
                    documentIds: [],
                    caseId: caseId,
                    reimbursementIds: reimbursementId ? [Number(reimbursementId)] : [],
                    rejectedCase: rejected,
                    rejectedCaseReason: ""
                })
            ).then((d) => {
                if (d.type === "adjustment/claim-case/rejected") {
                    toast.error(d.payload.errorMessage ?? "Something went wrong. Please try again!");
                } else if (d.type === "adjustment/claim-case/fulfilled") {
                    toast.success("New case submitted successfully.");
                }
            });
        }
        if (additionalCaseId) {
            dispatch(
                CreateCase({
                    amazonCaseIds: [additionalCaseId],
                    userId: null,
                    storeId: storeId
                })
            ).then((d) => {
                if (d.type === "adjustment/create-case/rejected") {
                    toast.error(d.payload.errorMessage ?? "Something went wrong. Please try again!");
                } else if (d.type === "adjustment/create-case/fulfilled") {
                    for (const cas of d.payload.response) {
                        if (cas.processed) toast.success("New case " + cas.amazonCaseId + " added successfully.");
                        else toast.error(cas.message);
                    }
                }
            });
        }
    };

    const registerCases = (data) => {
        axios.post('/cases/register-cases', data)
            .then(res => {
            }).catch(err => {
            toast.error(err.message);
        });
    }

    let txt = "Si prega di indagare la seguente spedizione " + x.shipmentId + " dove risultano:\n\n"
        + (x.items.map(y => {
            return y.fnsku + " " + (y.quantity > 0 ? "+" + y.quantity : y.quantity) + (y.quantity > 0 ? ' unità in più' : ' unità perse') + '\n'
        })).toString().replaceAll(",", "")
        + "\nAttendiamo il rimborso delle unità perse e la gestione delle unità in surplus.\n" +
        "Si prega di aprire un'indagine interna per quanto riguarda le unità spedite e mai aggiunte all'inventario. La spedizione è stata gestita dal corriere partner in tutte le sue fasi e, secondo le politiche di Amazon, la merce è diventata responsabilità della Logistica una volta confermata la consegna.”";
    txt += x.totalValue < 20 ? "" : (x.reseller ? "Allego la fattura dei prodotti." : "Dato che io sono il produttore, allego come prova il documento attestante la proprietà dell'inventario.")
    return <Row>
        <Divider/>
        <Col md={24}>
            <Row>
                <strong>DATE: </strong> {x.fullfillmentInbound?.inboundPlan?.createdAt}
            </Row>
            <Row>
                <Col>
                    <strong>Products</strong>:
                    {x.items.map(z =>
                        <Row>Quantità: {z.quantity} | FNSKU: <strong>{z.fnsku}</strong> | Valore {z.value ?? 0}
                            <Divider type={'vertical'}/>
                        </Row>)}
                </Col>
            </Row>
            <Row>
                <Col md={7} style={{overflow: 'auto'}}>
                    <strong>REFERENCE IDs</strong>:
                    <CopyTooltip
                        text={x.shipmentId}
                        textToCopy={x.shipmentId}/>
                </Col>
                <Divider type="vertical"/>
                <Col md={2} style={{overflow: 'auto'}}>
                    <strong>Value</strong>: {x?.totalValue ?? 0} €
                </Col>
            </Row>
        </Col>
        <Col md={2}>
            <Button onClick={() =>
                navigator.clipboard.writeText(txt)
            }>Copy Text</Button>
        </Col>
        <Col md={2}>
            <Input
                placeholder="Enter Amazon Case Id"
                size="small"
                value={caseId}
                onChange={(e) => setCaseId(e.target.value)}
            />
        </Col>
        <Divider type="vertical"/>
        <Col md={2}>
            <Input
                placeholder="Enter Amazon Reimbursement ID"
                size="small"
                value={reimbursementId}
                onChange={(e) => setReimbursementId(e.target.value)}
            />
        </Col>
        <Divider type="vertical"/>
        <Col md={2}>
            <Input
                placeholder="Enter Additional Amazon Case Id"
                size="small"
                value={additionalCaseId}
                onChange={(e) => setAdditionalCaseId(e.target.value)}
            />
        </Col>
        <Col md={1}>
            <Button
                type="primary"
                style={{
                    marginLeft: "15px",
                }}
                onClick={() => submitCase(false, store._id, x._id)}
            >
                Submit
            </Button>
        </Col>
        <Col md={1}>
            <Button onClick={() => {
                setRejected(true)
                submitCase(true, store._id, x._id)
            }}
                    danger
                    type="primary"
                    style={{
                        marginLeft: "15px",
                    }}>
                Rejected
            </Button>
        </Col>
    </Row>
}

export default SingleCase