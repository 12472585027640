import React, {useEffect, useState} from "react";
import "./TableView.css";
import {Table, Form} from "antd";

function TabelView({columns, data = [], pagination = true, form, components}) {
    return (
        <div className="transaction_wrapper">
            <Form form={form} component={false}>
                <Table
                    scroll={{y: `calc(100vh - 250px)`}}
                    className="mt-4"
                    dataSource={data}
                    columns={columns}
                    bordered
                    pagination={{defaultCurrent: 1, pageSizeOptions: ['20', '50', '100'], defaultPageSize: 100}}
                    components={components}
                    rowClassName="editable-row"
                    style={{overflow: "auto"}}/>
            </Form>
        </div>
    );
}

export default TabelView;
