import React, {useEffect, useState} from "react";
import TableView from "./TabelView/TableView";
import {Checkbox, Form} from "antd";
import {axiosBaseUrl} from "../../config/axios-configuration";
import {useNavigate} from "react-router-dom";
import './Users.css'

function StoreManagement() {
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [size, setSize] = useState(100);
    const [activeOptions, setActiveOptions] = useState({});
    const axios = axiosBaseUrl();

    const [form] = Form.useForm();
    const [data, setData] = useState([]);

    const STORE_COLUMNS = [
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            editable: false,
        },
        {
            title: "Store",
            dataIndex: "name",
            key: "name",
            editable: false,
        },
        {
            title: "Seller ID",
            dataIndex: "sellerId",
            key: "sellerId",
            editable: false,
        },
        {
            title: "Enabled",
            dataIndex: "enabled",
            key: "enabled",
            editable: false,
            render: (text, record) => <><Checkbox checked={record.enabled}/></>
        },
        {
            title: "Management",
            dataIndex: "managingAccount",
            key: "managingAccount",
            editable: true
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            editable: false,
        },
        {
            title: "Region",
            dataIndex: "region",
            key: "region",
            editable: false,
        },
        {
            title: "Join Date",
            dataIndex: "createdAt",
            key: "createdAt",
            editable: false,
        },
        {
            title: "Lost Warehouse",
            dataIndex: "servicesConfig.lost.warehouse.enabled",
            key: "servicesConfig.lost.warehouse.enabled",
            editable: false,
            render: (text, record) => <Checkbox checked={record.servicesConfig?.lost?.warehouse.enabled}/>
        },
        {
            title: "Lost Inbound",
            dataIndex: "servicesConfig.lost.inbound.enabled",
            key: "servicesConfig.lost.inbound.enabled",
            editable: false,
            render: (text, record) => <Checkbox checked={record.servicesConfig?.lost?.inbound.enabled}/>
        },
        {
            title: "Damaged Warehouse",
            dataIndex: "servicesConfig.damaged.warehouse.enabled",
            key: "servicesConfig.damaged.warehouse.enabled",
            editable: false,
            render: (text, record) => <Checkbox checked={record.servicesConfig?.damaged?.warehouse.enabled}/>
        }, {
            title: "Damaged Inbound",
            dataIndex: "servicesConfig.damaged.inbound.enabled",
            key: "servicesConfig.damaged.inbound.enabled",
            editable: false,
            render: (text, record) => <Checkbox checked={record.servicesConfig?.damaged?.inbound.enabled}/>
        },
        {
            title: "Damaged Outbound",
            dataIndex: "servicesConfig.damaged.outbound.enabled",
            key: "servicesConfig.damaged.outbound.enabled",
            editable: false,
            render: (text, record) => <Checkbox checked={record.servicesConfig?.damaged?.outbound.enabled}/>
        },
        {
            title: "Disposed",
            dataIndex: "servicesConfig.disposed.enabled",
            key: "servicesConfig.disposed.enabled",
            editable: false,
            render: (text, record) => <Checkbox checked={record.servicesConfig?.disposed?.enabled}/>
        },
        {
            title: "Customer Return",
            dataIndex: "servicesConfig.customer_return.enabled",
            key: "servicesConfig.customer_return.enabled",
            editable: false,
            render: (text, record) => <Checkbox checked={record.servicesConfig?.customer_return?.enabled}/>
        }
    ];

    useEffect(() => {
        axios.get("/store/get-all-stores")
            .then(x => setData(x.data))
    }, [pageNo, size, activeOptions, isModalOpen]);

    const mergedColumns = STORE_COLUMNS.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === "commissionDiscount" ? "number" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
            }),
        };
    });

    return (
        <>
            <TableView
                form={form}
                columns={mergedColumns}
                data={data}
            />
        </>
    )
        ;
}

export default StoreManagement;
