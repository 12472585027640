import AdminAddNewCase from "../Pages/AdminNewCases/AddNewCase";

export const DATE_OPTIONS = {year: "numeric", month: "short", day: "numeric"};

export const CURRENCY_SYMBOLS = {
    USD: "$",
    CAD: "C$",
    MXN: "MX$",
    BRL: "R$",
    GBP: "£",
    EUR: "€",
    SEK: "SEK",
    PLN: "zł",
    TRY: "₺",
    AED: "د.إ",
    INR: "₹",
    SGD: "S$",
    AUD: "A$",
    JPY: "¥",
    CZK: "Kč",
};

export const CURRENCY_CODE = {
    US: "USD",
    CA: "CAD",
    MX: "MXN",
    BR: "BRL",
    UK: "GBP",
    DE: "EUR",
    FR: "EUR",
    IT: "EUR",
    ES: "EUR",
    BE: "EUR",
    NL: "EUR",
    SE: "SEK",
    PL: "PLN",
    EG: "EGP",
    TR: "TRY",
    SA: "SA",
    AE: "AED",
    IN: "INR",
    SG: "SGD",
    AU: "AUD",
    JP: "JPY",
};

export const SELLER_CENTRAL_URL = {
    US: "https://sellercentral.amazon.com",
    CA: "https://sellercentral.amazon.ca",
    MX: "https://sellercentral.amazon.com.mx",
    BR: "https://sellercentral.amazon.com.br",
    UK: "https://sellercentral-europe.amazon.com",
    DE: "https://sellercentral.amazon.de",
    FR: "https://sellercentral.amazon.fr",
    IT: "https://sellercentral.amazon.it",
    ES: "https://sellercentral.amazon.es",
    BE: "https://sellercentral.amazon.com.be",
    NL: "https://sellercentral.amazon.nl",
    SE: "https://sellercentral.amazon.se",
    PL: "https://sellercentral.amazon.pl",
    EG: "https://sellercentral.amazon.eg",
    TR: "https://sellercentral.amazon.com.tr",
    SA: "https://sellercentral.amazon.sa",
    AE: "https://sellercentral.amazon.ae",
    IN: "https://sellercentral.amazon.in",
    SG: "https://sellercentral.amazon.sg",
    AU: "https://sellercentral.amazon.com.au",
    JP: "https://sellercentral.amazon.co.jp",
};

export const NEW_CASE_COLUMNS = [
    // {
    //     className: "table_edit_heading",
    //     width: 70,
    //     labelValue: "",
    // },
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "Case ID",
        // isAdminOnly: true,
        backendKey: "_id",
        hasSort: false,
    },
    // {
    //   className: "table_heading",
    //   width: 100, overflow: "scroll",
    //   labelValue: "Expires In",
    //   backendKey: "expiresIn",
    //   hasSort: true,
    // },
    // {
    //   className: "table_heading",
    //   width: 160, overflow: "scroll",
    //   labelValue: "Closes Automatically In",
    //   backendKey: "closeAutomaticallyIn",
    //   hasSort: true,
    // },
    {
        className: "table_heading",
        width: 300, overflow: "scroll",
        labelValue: "Creation date",
        backendKey: "date",
        hasSort: true,
        inputValue: "date",
    },
    {
        className: "table_heading",
        width: 160, overflow: "scroll",
        labelValue: "Case type",
        backendKey: "type",
        hasSort: true,
        inputValue: "select",
        selectOptions: [
            {
                value: "LOST",
            },
            {
                value: "DAMAGED",
            }
        ],
    },
    {
        className: "table_heading",
        width: 80,
        labelValue: "Quantity",
        backendKey: "totalClaimableUnits",
        hasSort: true,
        type:'number'
    },
    // {
    //   className: "table_heading",
    //   width: 130,
    //   labelValue: "Transaction Id's",
    //   backendKey: "referenceId",
    //   hasSort: true,
    // },
    // {
    //   className: "table_heading",
    //   width: 120, overflow: "scroll",
    //   labelValue: "Status",
    //   backendKey: "status",
    //   hasSort: false,
    //   inputValue: "select",
    //   selectOptions: [
    //     { value: "TO_BE_CLAIMED" },
    //     { value: "CLAIMED" }
    //   ],
    // },
    {
        className: "table_heading",
        width: 110, overflow: "scroll",
        labelValue: "Account",
        backendKey: "storeId",
        hasSort: true,
        // inputValue: "select",
        // selectOptions: [
        // { value: "Amazon ADSFASDFA" },
        // ],
    },
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "Marketplace",
        backendKey: "country",
        hasSort: true,
        inputValue: "select",
        selectOptions:
            [
                {value: "DE"},
                {value: "IT"},
                {value: "FR"},
                {value: "ES"},
                {value: "SK"},
                {value: "GB"},
                {value: "US"},
                {value: "CZ"},
                {value: "PL"}
            ],
    },
    {
        className: "table_heading product_description",
        width: 250,
        labelValue: "Product",
        backendKey: "title+sellerSku+fnsku",
        hasSort: true,
    },
    // {
    //     className: "table_heading",
    //     width: 120, overflow: "scroll",
    //     labelValue: "Estimated reimbursement",
    //     backendKey: "estimatePrice",
    //     hasSort: true,
    //     type:'number'
    // },
    {
        className: "table_heading",
        width: 300, overflow: "scroll",
        labelValue: "",
        disabled: false,
    },
    // {
    //   className: "table_heading",
    //   width: 100, overflow: "scroll",
    //   labelValue: "Action",
    //   disabled: false,
    // },
];

export const NEW_CASE_COLUMNS_ADMIN = [
    {
        className: "table_edit_heading",
        width: 70,
        labelValue: <AdminAddNewCase/>,
        isButton: true,
        isAdminOnly: true,
        checkOnUserId: true
    },
    {
        className: "table_heading",
        width: 130, overflow: "scroll",
        labelValue: "Case ID",
        // isAdminOnly: true,
        backendKey: "caseId",
        hasSort: false,
        type:'number'
    },
    // {
    //   className: "table_heading",
    //   width: 100, overflow: "scroll",
    //   labelValue: "Expires In",
    //   backendKey: "expiresIn",
    //   hasSort: true,
    // },
    // {
    //   className: "table_heading",
    //   width: 160, overflow: "scroll",
    //   labelValue: "Closes Automatically In",
    //   backendKey: "closeAutomaticallyIn",
    //   hasSort: true,
    // },
    {
        className: "table_heading",
        width: 300, overflow: "scroll",
        labelValue: "Creation date",
        backendKey: "date",
        hasSort: true,
        inputValue: "date",
    },
    {
        className: "table_heading",
        width: 160, overflow: "scroll",
        labelValue: "Case type",
        backendKey: "type",
        hasSort: true,
        inputValue: "select",
        selectOptions: [
            {
                value: "LOST_WAREHOUSE",
            },
            {
                value: "LOST_INBOUND",
            },
            {
                value: "DAMAGED_INBOUND",
            },
            {
                value: "DAMAGED_OUTBOUND",
            },
            {
                value: "DAMAGED_WAREHOUSE",
            },
            {
                value: "DISPOSED",
            },
            {
                value: "CUSTOMER_RETURN",
            }
        ],
    },
    {
        className: "table_heading",
        width: 80,
        labelValue: "Quantity",
        backendKey: "totalClaimableUnits",
        hasSort: true,
        type:'number'
    },
    // {
    //   className: "table_heading",
    //   width: 130,
    //   labelValue: "Transaction Id's",
    //   backendKey: "referenceId",
    //   hasSort: true,
    // },
    // {
    //   className: "table_heading",
    //   width: 120, overflow: "scroll",
    //   labelValue: "Status",
    //   backendKey: "status",
    //   hasSort: false,
    //   inputValue: "select",
    //   selectOptions: [
    //     { value: "TO_BE_CLAIMED" },
    //     { value: "CLAIMED" }
    //   ],
    // },
    {
        className: "table_heading",
        width: 110, overflow: "scroll",
        labelValue: "Account",
        backendKey: "user",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "Marketplace",
        backendKey: "country",
        hasSort: true,
        inputValue: "select",
        selectOptions:
            [
                {value: "DE"},
                {value: "IT"},
                {value: "FR"},
                {value: "ES"},
                {value: "SK"},
                {value: "GB"},
                {value: "US"},
                {value: "CZ"},
                {value: "PL"}
            ],
    },
    {
        className: "table_heading product_description",
        width: 250,
        labelValue: "Product",
        backendKey: "title+sellerSku+fnsku",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Estimated reimbursement",
        backendKey: "estimatePrice",
        hasSort: true,
        type:'number'
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Transaction Item Id",
        backendKey: "referenceId",
        hasSort: false,
        type:'number'
    },
    {
      className: "table_heading",
      width: 300, overflow: "scroll",
      labelValue: "",
      disabled: false,
    },
];

export const TRANSACTION_DETAIL_COLOMNS = [
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Transaction ID",
        // isAdminOnly: true,
        backendKey: "transactionId",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "Case ID",
        backendKey: "caseId",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 160, overflow: "scroll",
        labelValue: "Amazon Case ID",
        backendKey: "amazonCaseId",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 160, overflow: "scroll",
        labelValue: "Reimbursement ID",
        backendKey: "reimbursementIds",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 160, overflow: "scroll",
        labelValue: "SKU",
        backendKey: "sellerSku",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 160, overflow: "scroll",
        labelValue: "Type",
        backendKey: "type",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 150, overflow: "scroll",
        labelValue: "Amount",
        backendKey: "amount",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 150, overflow: "scroll",
        labelValue: "Fee",
        backendKey: "fee",
        hasSort: true,
    },
    {
        className: "table_heading product_description",
        width: 160, overflow: "scroll",
        labelValue: "Invoice Number",
        backendKey: "invoiceNumber",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 160, overflow: "scroll",
        labelValue: "Transaction Date",
        backendKey: "transactionDate",
        hasSort: true,
        inputValue: "date",
    }
];

export const USERS_COLUMNS = [
    {
        title: "Commission Discount",
        dataIndex: "commissionDiscount",
        key: "commissionDiscount",
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
    },
    {
        title: "Join Date",
        dataIndex: "createdAt",
        key: "createdAt",
    }
];

export const DASHBOARD_NEW_CASE_COLOMNS = [
    {
        className: "table_edit_heading",
        width: 70,
        labelValue: "",
    },
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "Case ID",
        // isAdminOnly: true,
        backendKey: "caseId",
        hasSort: true,
    },
    // {
    //   className: "table_heading",
    //   width: 100, overflow: "scroll",
    //   labelValue: "Expires In",
    //   backendKey: "expiresIn",
    //   hasSort: true,
    // },
    // {
    //   className: "table_heading",
    //   width: 160, overflow: "scroll",
    //   labelValue: "Closes Automatically In",
    //   backendKey: "closeAutomatically",
    //   hasSort: true,
    // },
    {
        className: "table_heading",
        width: 150, overflow: "scroll",
        labelValue: "Date Created",
        backendKey: "date",
        hasSort: true,
        inputValue: "date",
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Case type",
        backendKey: "type",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 80,
        labelValue: "Quantity",
        backendKey: "totalClaimableUnits",
        hasSort: true,
    },
    // {
    //   className: "table_heading",
    //   width: 130,
    //   labelValue: "Transaction Id's",
    //   backendKey: "referenceId",
    //   hasSort: true,
    // },
    // {
    //   className: "table_heading",
    //   width: 120, overflow: "scroll",
    //   labelValue: "Status",
    //   backendKey: "status",
    //   hasSort: false,
    //   inputValue: "select",
    //   selectOptions: [
    //     { value: "TO_BE_CLAIMED" },
    //     { value: "CLAIMED" }
    //   ],
    // },
    {
        className: "table_heading",
        width: 110, overflow: "scroll",
        labelValue: "Account",
        backendKey: "storeName",
        hasSort: true,
        inputValue: "select",
        selectOptions: [
            // { value: "Amazon ADSFASDFA" },
        ],
    },
    // {
    //   className: "table_heading",
    //   width: 120, overflow: "scroll",
    //   labelValue: "Estimated reimbursement",
    //   backendKey: "estimatePrice",
    //   hasSort: true,
    //   render: (data) => {
    //     return (
    //       <div>{data?.unit ? `${CURRENCY_SYMBOLS[data.unit]} ${data.value}` : ""}</div>
    //     )
    //   },
    // },
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "Marketplace",
        backendKey: "country",
        hasSort: true,
        inputValue: "select",
        selectOptions:
            [
                {value: "DE"},
                {value: "IT"},
                {value: "FR"},
                {value: "ES"},
                {value: "SK"},
                {value: "GB"},
                {value: "US"},
                {value: "CZ"},
                {value: "PL"}
            ],
    },
    // {
    //   className: "table_heading",
    //   width: 250,
    //   labelValue: "Seller SKU",
    //   backendKey: "sellerSku",
    //   hasSort: true,
    // },
    // {
    //   className: "table_heading",
    //   width: 250,
    //   labelValue: "FNSKU",
    //   backendKey: "fnsku",
    //   hasSort: true,
    // },
    // {
    //   className: "table_heading",
    //   width: 100, overflow: "scroll",
    //   labelValue: "Action",
    //   disabled: false,
    // },
];

//TODO split in two for admins and users
export const ALL_CASES_COLOMNS = [
    {
        className: "table_edit_heading",
        width: 70,
        labelValue: "",
    },
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "Case ID",
        // isAdminOnly: true,
        backendKey: "caseId",
        hasSort: false,
        type:'number'
    },
    // {
    //   className: "table_heading",
    //   width: 100, overflow: "scroll",
    //   labelValue: "Expires In",
    //   backendKey: "expiresIn",
    //   hasSort: true,
    // },
    // {
    //   className: "table_heading",
    //   width: 160, overflow: "scroll",
    //   labelValue: "Closes Automatically In",
    //   backendKey: "closeAutomaticallyIn",
    //   hasSort: true,
    // },
    {
        className: "table_heading",
        width: 300, overflow: "scroll",
        labelValue: "Creation date",
        backendKey: "date",
        hasSort: true,
        inputValue: "date",
    },
    {
        className: "table_heading",
        width: 160, overflow: "scroll",
        labelValue: "Case type",
        backendKey: "type",
        hasSort: false,
        inputValue: "select",
        selectOptions: [
            {
                value: "LOST_WAREHOUSE",
            },
            {
                value: "LOST_INBOUND",
            },
            {
                value: "DAMAGED_INBOUND",
            },
            {
                value: "DAMAGED_OUTBOUND",
            },
            {
                value: "DAMAGED_WAREHOUSE",
            },
            {
                value: "DISPOSED",
            },
            {
                value: "CUSTOMER_RETURN",
            },
            {
                value: "Manual",
            }
        ],
    },
    {
        className: "table_heading",
        width: 80,
        labelValue: "Quantity",
        backendKey: "totalClaimableUnits",
        hasSort: true,
        type:'number'
    },
    // {
    //   className: "table_heading",
    //   width: 130,
    //   labelValue: "Transaction Id's",
    //   backendKey: "referenceId",
    //   hasSort: true
    // },
    {
        className: "table_heading",
        width: 110, overflow: "scroll",
        labelValue: "Account",
        backendKey: "storeId",
        hasSort: false
    },
    // {
    //     className: "table_heading",
    //     width: 110, overflow: "scroll",
    //     labelValue: "Account",
    //     backendKey: "storeId",
    //     hasSort: false,
    //     hidden: true
    // },
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "Marketplace",
        backendKey: "country",
        hasSort: false,
        inputValue: "select",
        selectOptions:
            [
                {value: "DE"},
                {value: "IT"},
                {value: "FR"},
                {value: "ES"},
                {value: "SK"},
                {value: "GB"},
                {value: "US"},
                {value: "CZ"},
                {value: "PL"}
            ],
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "SKU",
        backendKey: "sellerSku",
        hasSort: false,
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "FNSKU",
        backendKey: "fnsku",
        hasSort: false,
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Amazon Case ID",
        backendKey: "amazonCaseId",
        hasSort: false,
        type:'number'
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Reimbursement ID's",
        backendKey: 'reimbursementIds',
        hasSort: false,
        type:'number'
    },
    {
        className: "table_heading",
        width: 300,
        labelValue: "Submitted to Amazon",
        backendKey: "dateOfCaseSubmitted",
        hasSort: true,
        inputValue: "date",
    },
    {
        className: "table_heading",
        width: 300,
        labelValue: "Reimbursement Date",
        backendKey: "dateOfReimbursement",
        hasSort: true,
        inputValue: "date",
    },
    // {
    //   className: "table_heading",
    //   width: 180,
    //   labelValue: "Estimated reimbursement",
    //   backendKey: "estimatePrice",
    //   hasSort: true,
    // },
    {
        className: "table_heading",
        width: 180,
        labelValue: "Rimborso Ottenuto",
        backendKey: "amountReimbursed",
        hasSort: true,
        type:'number'
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Status",
        backendKey: "status",
        hasSort: false,
        inputValue: "select",
        selectOptions: [
            {value: "In Progress"},
            {value: "Resolved"},
            {value: "Customer Action Required"},
            {value: "Rejected"},
        ],
    },
    // {
    //   className: "table_heading",
    //   width: 120, overflow: "scroll",
    //   labelValue: "Reason",
    //   backendKey: "reason",
    //   hasSort: false,
    // },
    // {
    //   className: "table_heading",
    //   width: 100, overflow: "scroll",
    //   labelValue: "Action",
    //   disabled: true,
    //   isUserOnly: true
    // },
];

export const ALL_CASES_COLOMNS_USER = [
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "Case ID",
        // isAdminOnly: true,
        backendKey: "caseId",
        hasSort: false,
        type:'number'
    },
    {
        className: "table_heading",
        width: 300, overflow: "scroll",
        labelValue: "Creation date",
        backendKey: "date",
        hasSort: true,
        inputValue: "date",
    },
    {
        className: "table_heading",
        width: 160, overflow: "scroll",
        labelValue: "Case type",
        backendKey: "type",
        hasSort: false,
        inputValue: "select",
        selectOptions: [
            {
                value: "LOST",
            },
            {
                value: "DAMAGED",
            },
            {
                value: "Manual",
            },
        ],
    },
    {
        className: "table_heading",
        width: 80,
        labelValue: "Quantity",
        backendKey: "totalClaimableUnits",
        hasSort: true,
        type:'number'
    },
    // {
    //   className: "table_heading",
    //   width: 130,
    //   labelValue: "Transaction Id's",
    //   backendKey: "referenceId",
    //   hasSort: true
    // },
    {
        className: "table_heading",
        width: 110, overflow: "scroll",
        labelValue: "Account",
        backendKey: "storeId",
        hasSort: false
    },
    // {
    //     className: "table_heading",
    //     width: 110, overflow: "scroll",
    //     labelValue: "Account",
    //     backendKey: "storeId",
    //     hasSort: false,
    //     hidden: true
    // },
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "Marketplace",
        backendKey: "country",
        hasSort: false,
        inputValue: "select",
        selectOptions:
            [
                {value: "DE"},
                {value: "IT"},
                {value: "FR"},
                {value: "ES"},
                {value: "SK"},
                {value: "GB"},
                {value: "US"},
                {value: "CZ"},
                {value: "PL"}
            ],
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "SKU",
        backendKey: "sellerSku",
        hasSort: false,
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "FNSKU",
        backendKey: "fnsku",
        hasSort: false,
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Amazon Case ID",
        backendKey: "amazonCaseId",
        hasSort: false,
        type:'number'
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Reimbursement ID's",
        backendKey: 'reimbursementIds',
        hasSort: false,
        type:'number'
    },
    {
        className: "table_heading",
        width: 300,
        labelValue: "Submitted to Amazon",
        backendKey: "dateOfCaseSubmitted",
        hasSort: true,
        inputValue: "date",
    },
    {
        className: "table_heading",
        width: 300,
        labelValue: "Reimbursement Date",
        backendKey: "dateOfReimbursement",
        hasSort: true,
        inputValue: "date",
    },
    // {
    //   className: "table_heading",
    //   width: 180,
    //   labelValue: "Estimated reimbursement",
    //   backendKey: "estimatePrice",
    //   hasSort: true,
    // },
    {
        className: "table_heading",
        width: 180,
        labelValue: "Rimborso Ottenuto",
        backendKey: "amountReimbursed",
        hasSort: true,
        type:'number'
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Status",
        backendKey: "status",
        hasSort: false,
        inputValue: "select",
        selectOptions: [
            {value: "In Progress"},
            {value: "Resolved"},
            {value: "Customer Action Required"},
            {value: "Rejected"},
        ],
    },
    // {
    //   className: "table_heading",
    //   width: 120, overflow: "scroll",
    //   labelValue: "Reason",
    //   backendKey: "reason",
    //   hasSort: false,
    // },
    // {
    //   className: "table_heading",
    //   width: 100, overflow: "scroll",
    //   labelValue: "Action",
    //   disabled: true,
    //   isUserOnly: true
    // },
];

export const REPORTS_COLUMNS = [
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "Name",
        backendKey: "name",
    },
    {
        className: "table_heading",
        width: 200, overflow: "scroll",
        labelValue: "Last Run",
        backendKey: "lastRunAt",
        inputValue: "lastRunAt",
    },
    {
        className: "table_heading",
        width: 200, overflow: "scroll",
        labelValue: "Next Run",
        backendKey: "nextRunAt",
        inputValue: "nextRunAt",
    },
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "State",
        backendKey: "state",
        type:'number'
    },
    {
        className: "table_heading",
        width: 100, overflow: "scroll",
        labelValue: "Account",
        backendKey: "data.storeId",
    }
];

export const ACTION_REQUIRED_COLOMNS = [
    {
        className: "table_edit_heading",
        width: 70,
        labelValue: "",
    },
    {
        className: "table_heading",
        width: 110, overflow: "scroll",
        labelValue: "Case ID",
        backendKey: "caseId",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 150, overflow: "scroll",
        labelValue: "Creation date",
        backendKey: "date",
        hasSort: true,
        inputValue: "date",
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Case type",
        backendKey: "type",
        hasSort: true,
        inputValue: "select",
        selectOptions: [
            {value: "Order"},
            {value: "Lost return in warehouse"},
            {value: "Stock"},
            {value: "Damaged/Destroyed"},
            {value: "FBA fees"},
            {value: "Inbound shipment"},
        ],
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Transaction Id's",
        backendKey: "referenceId",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 130,
        labelValue: "Status",
        backendKey: "status",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 80,
        labelValue: "Quantity",
        backendKey: "claimableUnits",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 110, overflow: "scroll",
        labelValue: "Account",
        backendKey: "storeName",
        hasSort: true,
        inputValue: "select",
        selectOptions: [{value: "Amazon ADSFASDFA"}],
    },
    {
        className: "table_heading",
        width: 80,
        labelValue: "Country",
        backendKey: "country",
        hasSort: true,
    },
    {
        className: "table_heading product_description",
        width: 250,
        labelValue: "Reference Ids",
        backendKey: "refrenceIds",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Submitted to amazon",
        backendKey: "dateOfCaseSubmitted",
        hasSort: true,
    },
    {
        className: "table_heading product_description",
        width: 250,
        labelValue: "Product",
        backendKey: "title+sku+fnsku",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 110, overflow: "scroll",
        labelValue: "Reimbursment date",
        backendKey: "resolvedAt",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Estimated reimbursment",
        backendKey: "claimableAmount",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Rimborso Ottenuto",
        backendKey: "actualReimbursment",
        hasSort: true,
    },
];

export const DASHBOARD_ACTION_REQUIRED_COLOMNS = [
    {
        className: "table_edit_heading",
        width: 70,
        labelValue: "",
    },
    {
        className: "table_heading",
        width: 110, overflow: "scroll",
        labelValue: "Case ID",
        backendKey: "caseId",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 150, overflow: "scroll",
        labelValue: "Creation date",
        backendKey: "date",
        hasSort: true,
        inputValue: "date",
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Case type",
        backendKey: "type",
        hasSort: true,
        inputValue: "select"
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Amazon Case ID",
        backendKey: "amazonCaseId",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 80,
        labelValue: "Quantity",
        backendKey: "totalClaimableUnits",
        hasSort: true,
    },
    {
        className: "table_heading",
        width: 110, overflow: "scroll",
        labelValue: "Account",
        backendKey: "storeName",
        hasSort: true,
        inputValue: "select",
        selectOptions: [{value: "Amazon ADSFASDFA"}],
    },
    {
        className: "table_heading",
        width: 80,
        labelValue: "Country",
        backendKey: "country",
        hasSort: true,
    },
    // {
    //   className: "table_heading",
    //   width: 250,
    //   labelValue: "Seller SKU",
    //   backendKey: "sellerSku",
    //   hasSort: true,
    // },
    // {
    //   className: "table_heading",
    //   width: 250,
    //   labelValue: "FNSKU",
    //   backendKey: "fnsku",
    //   hasSort: true,
    // },
    // {
    //   className: "table_heading",
    //   width: 110, overflow: "scroll",
    //   labelValue: "Reimbursment date",
    //   backendKey: "resolvedAt",
    //   hasSort: true,
    // },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Estimated reimbursment",
        backendKey: "estimatedAmount",
        hasSort: true,
        render: (data) => {
            return (
                <div>{data.value === 0 || isNaN(data.value) ? "N.D" : (data?.unit ? `${CURRENCY_SYMBOLS[data.unit]} ${data.value}` : "")}</div>
            )
        },
    },
    {
        className: "table_heading",
        width: 120, overflow: "scroll",
        labelValue: "Rimborso Ottenuto",
        backendKey: "actualReimbursment",
        hasSort: true,
        render: (data) => {
            return (
                <div>{data?.unit ? `${CURRENCY_SYMBOLS[data.unit]} ${data.value}` : ""}</div>
            )
        },
    },
];

export const TRANSACTION_COLOMNS = [
    {
        title: "Transaction ID",
        dataIndex: "transactionId",
        key: "transactionId",
    },
    {
        title: "Case ID",
        dataIndex: "caseId",
        key: "caseId",
    },
    {
        title: "Amazon Case ID",
        dataIndex: "amazonCaseId",
        key: "amazonCaseId",
    },
    {
        title: "Reimbursement ID",
        dataIndex: "reimbursementIds",
        key: "reimbursementIds",
    },
    {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
    },
    {
        title: "Fee",
        dataIndex: "fee",
        key: "fee",
    },
    {
        title: "Transaction Date",
        dataIndex: "transactionDate",
        key: "transactionDate",
        inputValue: "date"
    },
];

export const DEMO_DATA = [
    {
        _id: '63d14b5ae449a10013e4b776',
        asin: 'N/A',
        sellerSku: 'V2-7SQ6-466K',
        fnsku: 'X001DM74GP',
        title: 'N/A',
        imageUrl: 'N/A',
        type: 'DESTROYED',
        status: 'In Progress',
        date: '2023-01-10T23:59:59.999Z',
        caseId: 79631297,
        amazonCaseId: null,
        country: 'IT',
        dateOfCaseSubmitted: '2023-01-26T18:56:07.354Z',
        totalClaimableUnits: 1,
        estimatedAmount: {
            unit: 'EUR',
            value: 5
        },
        reimbursementIds: [
            '4681965562'
        ],
        storeName: 'AMZ-AD8IZCNNTPGND',
        expiresIn: 61,
        closeAutomatically: 61
    },
    {
        _id: '63d14b5ae449a10013e4b777',
        asin: 'N/A',
        sellerSku: 'V2-7SQ6-466K',
        fnsku: 'X001DM74GP',
        title: 'N/A',
        imageUrl: 'N/A',
        type: 'DESTROYED',
        status: 'In Progress',
        date: '2023-01-10T23:59:59.999Z',
        caseId: 83766872,
        amazonCaseId: null,
        country: 'IT',
        dateOfCaseSubmitted: '2023-01-26T18:57:33.915Z',
        totalClaimableUnits: 1,
        estimatedAmount: {
            unit: 'EUR',
            value: 5
        },
        reimbursementIds: [
            '4681965562'
        ],
        storeName: 'AMZ-AD8IZCNNTPGND',
        expiresIn: 61,
        closeAutomatically: 61
    },
    {
        _id: '63d14b5ae449a10013e4b777',
        asin: 'N/A',
        sellerSku: 'V2-7SQ6-466K',
        fnsku: 'X001DM74GP',
        title: 'N/A',
        imageUrl: 'N/A',
        type: 'DESTROYED',
        status: 'In Progress',
        date: '2023-01-10T23:59:59.999Z',
        caseId: 83766872,
        amazonCaseId: null,
        country: 'IT',
        dateOfCaseSubmitted: '2023-01-26T18:57:33.915Z',
        totalClaimableUnits: 1,
        estimatedAmount: {
            unit: 'EUR',
            value: 5
        },
        reimbursementIds: [
            '4681965562'
        ],
        storeName: 'AMZ-AD8IZCNNTPGND',
        expiresIn: 61,
        closeAutomatically: 61
    },
    {
        _id: '63d14b5ae449a10013e4b777',
        asin: 'N/A',
        sellerSku: 'V2-7SQ6-466K',
        fnsku: 'X001DM74GP',
        title: 'N/A',
        imageUrl: 'N/A',
        type: 'DESTROYED',
        status: 'In Progress',
        date: '2023-01-10T23:59:59.999Z',
        caseId: 83766872,
        amazonCaseId: null,
        country: 'IT',
        dateOfCaseSubmitted: '2023-01-26T18:57:33.915Z',
        totalClaimableUnits: 1,
        estimatedAmount: {
            unit: 'EUR',
            value: 5
        },
        reimbursementIds: [
            '4681965562'
        ],
        storeName: 'AMZ-AD8IZCNNTPGND',
        expiresIn: 61,
        closeAutomatically: 61
    },
    {
        _id: '63d14b5ae449a10013e4b777',
        asin: 'N/A',
        sellerSku: 'V2-7SQ6-466K',
        fnsku: 'X001DM74GP',
        title: 'N/A',
        imageUrl: 'N/A',
        type: 'DESTROYED',
        status: 'In Progress',
        date: '2023-01-10T23:59:59.999Z',
        caseId: 83766872,
        amazonCaseId: null,
        country: 'IT',
        dateOfCaseSubmitted: '2023-01-26T18:57:33.915Z',
        totalClaimableUnits: 1,
        estimatedAmount: {
            unit: 'EUR',
            value: 5
        },
        reimbursementIds: [
            '4681965562'
        ],
        storeName: 'AMZ-AD8IZCNNTPGND',
        expiresIn: 61,
        closeAutomatically: 61
    },
    {
        _id: '63d14b5ae449a10013e4b777',
        asin: 'N/A',
        sellerSku: 'V2-7SQ6-466K',
        fnsku: 'X001DM74GP',
        title: 'N/A',
        imageUrl: 'N/A',
        type: 'DESTROYED',
        status: 'In Progress',
        date: '2023-01-10T23:59:59.999Z',
        caseId: 83766872,
        amazonCaseId: null,
        country: 'IT',
        dateOfCaseSubmitted: '2023-01-26T18:57:33.915Z',
        totalClaimableUnits: 1,
        estimatedAmount: {
            unit: 'EUR',
            value: 5
        },
        reimbursementIds: [
            '4681965562'
        ],
        storeName: 'AMZ-AD8IZCNNTPGND',
        expiresIn: 61,
        closeAutomatically: 61
    }
];