import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineCopy, AiOutlineEdit, AiOutlineCheck } from "react-icons/ai";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import { message, Pagination, Button, Input } from "antd";

import { getLocalDate, getCurrencySymbolsForMarket } from "../../utils/utils";
import Logo from "../../assets/logo.png";
import "./CaseOverview.scss";

function CaseOverview({}) {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [isAmazonIdEdit, setIsAmazonIdEdit] = useState(false);

  const { state = {} } = useLocation();
  const { item = {} } = state;

  const [amazonCaseId, setAmazonCaseId] = useState(item?.amazonCaseId || "N/A");

  return (
    <div className="overview_container">
      {contextHolder}
      <div className="wrapper">
        <div className="header">
          <BiArrowBack
            className="back_icon"
            onClick={() => navigate("/all-cases")}
          />
          <span className="text">Case Overview</span>
        </div>
        <Row>
          <Col sm={12} md={12} lg={6}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Case ID: {item?.caseId}</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col span={12}>Status</Col>
                    <Col span={12}>{item?.status}</Col>
                  </Row>
                  <Row>
                    <Col span={12}>Account</Col>
                    <Col span={12}>{item?.storeName || "N/A"}</Col>
                  </Row>
                  <Row>
                    <Col span={12}>Marketplace</Col>
                    <Col span={12}>{item?.country || "N/A"}</Col>
                  </Row>
                  <Row>
                    <Col span={12}>Case type</Col>
                    <Col span={12}>{item?.type || "N/A"}</Col>
                  </Row>
                  {/* <Row>
                    <Col span={12}>Estimated reimbursement</Col>
                    <Col span={12}>
                      {getCurrencySymbolsForMarket(item?.estimatedAmount) ||
                        "N/A"}
                    </Col>
                  </Row> */}
                  <Row>
                    <Col span={12}>Creation Date</Col>
                    <Col span={12}>{getLocalDate(item?.date || "")}</Col>
                  </Row>
                  <Row>
                    <Col span={12}>Submit To Amazon</Col>
                    <Col span={12}>
                      {getLocalDate(
                        moment().add(item?.dateOfCaseSubmitted).toDate() || ""
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>Amzon Case ID </Col>
                    <Col span={12}>
                      {isAmazonIdEdit ? (
                        <div className="amazon_id_container">
                          <input
                            className="amazon_id_input"
                            type="number"
                            value={amazonCaseId}
                            onChange={(e) => {
                              const value = e?.target?.value;
                              setAmazonCaseId(value);
                            }}
                          />
                          <AiOutlineCheck
                            style={{
                              position: "relative",
                              left: "-40px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsAmazonIdEdit(false);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="amazon_id_container">
                          {amazonCaseId}
                          <AiOutlineEdit
                            className="editBtn"
                            onClick={() => {
                              setIsAmazonIdEdit(true);
                            }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>FNSKU</Col>
                    <Col span={12}>{item?.fnsku || "N/A"}</Col>
                  </Row>
                  <Row>
                    <Col span={12}>SKU</Col>
                    <Col span={12}>{item?.sellerSku || "N/A"}</Col>
                  </Row>
                  <Row>
                    <Col span={12}>Quantity</Col>
                    <Col span={12}>{item?.totalClaimableUnits || "N/A"}</Col>
                  </Row>
                  {/* <Row>
                    <Col span={12}>FNSKU Price</Col>
                    <Col span={12}>
                      {getCurrencySymbolsForMarket(item?.estimatedAmount) ||
                        "N/A"}
                    </Col>
                  </Row> */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Col sm={12} className="mt-4">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Case Description</Accordion.Header>
                  <Accordion.Body>
                    <div style={{ margin: "20px 0px" }}>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <h5 style={{ margin: "0", fontSize: "18px" }}>
                          Case Title
                        </h5>
                        <AiOutlineCopy
                          className="copyIcon"
                          onClick={() => {
                            navigator.clipboard
                              .writeText(`FNSKU: ${item?.fnsku}{" "}
                    / SKU: ${item?.sellerSku}`);
                            messageApi.open({
                              type: "success",
                              content: `title copied to clipboard`,
                            });
                          }}
                        />
                      </span>
                      Please initiate an inventory reconciliation - FNSKU:{" "}
                      {item?.fnsku} / SKU: {item?.sellerSku}
                    </div>

                    <div style={{ margin: "20px 0px" }}>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <h5 style={{ margin: "0", fontSize: "18px" }}>
                          Case Description
                        </h5>
                        <AiOutlineCopy
                          className="copyIcon"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `FNSKU: ${item.fnsku}\nUsing this start: ${item?.date} \n Using this end: ${item?.date} \nActual starting inventory: 0 \n(+) Receipts: 25 \n(+) Customer Returns: 0\n(-) Shipments: -11\n(-) Removals: -1\n(+/-) Net Adjustments: -1\n\n(=) Expected Ending Inventory: 12\n(=) Actual Ending Inventory: 12\n(=) Inventory Discrepency: 0\n---------------------------\n(-) Amazon misplaced inventory: -1\n() Pending misplaced inventory processing: 0 \n(+) Found inventory: 0\n(+) Reimbursed misplaced inventory: 0\n\n(=) Misplaced inventory not yet reimbursed: 1\n----------------------------\nNeeds to be reimbursed: \n----------------------------`
                            );
                            messageApi.open({
                              type: "success",
                              content: `description copied to clipboard`,
                            });
                          }}
                        />
                      </span>
                    </div>
                    <pre>
                      FNSKU: {item.fnsku}
                      <br />
                      Using this start: {item?.date} <br />
                      Using this end: {item?.date} <br />
                      Actual starting inventory: 0 <br />
                      (+) Receipts: 25 <br />
                      (+) Customer Returns: 0<br />
                      (-) Shipments: -11
                      <br />
                      (-) Removals: -1
                      <br />
                      (+/-) Net Adjustments: -1
                      <br />
                      <br />
                      (=) Expected Ending Inventory: 12
                      <br />
                      (=) Actual Ending Inventory: 12
                      <br />
                      <br />
                      (=) Inventory Discrepency: 0<br />
                      ---------------------------
                      <br />
                      (-) Amazon misplaced inventory: -1
                      <br />
                      () Pending misplaced inventory processing: 0 <br />
                      (+) Found inventory: 0<br />
                      (+) Reimbursed misplaced inventory: 0<br />
                      <br />
                      (=) Misplaced inventory not yet reimbursed: 1<br />
                      ----------------------------
                      <br />
                      Needs to be reimbursed: 1<br />
                      ----------------------------
                    </pre>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Message</Accordion.Header>
                <Accordion.Body>
                  <div className="tile_container">
                    <div className="logo">
                      <img src={Logo} alt="" className="img" />
                    </div>
                    <div className="description">
                      <div className="tile_header">
                        <div className="title">System</div>
                        <div className="time">20:50:18</div>
                      </div>
                      <div className="tile_details">
                        <div>Status: In progress</div>
                        Amazon has confirmed that order
                      </div>
                    </div>
                  </div>

                  <div className="tile_container">
                    <div className="logo">
                      <img src={Logo} alt="" className="img" />
                    </div>
                    <div className="description">
                      <div className="tile_header">
                        <div className="title">System</div>
                        <div className="time">20:50:18</div>
                      </div>
                      <div className="tile_details">
                        <div>Status: In progress</div>
                        Amazon has confirmed that order
                      </div>
                    </div>
                  </div>
                  <div className="tile_container">
                    <div className="logo">
                      <img src={Logo} alt="" className="img" />
                    </div>
                    <div className="description">
                      <div className="tile_header">
                        <div className="title">System</div>
                        <div className="time">20:50:18</div>
                      </div>
                      <div className="tile_details">
                        <div>Status: In progress</div>
                        Amazon has confirmed that order
                      </div>
                    </div>
                  </div>
                  <div className="tile_container">
                    <div className="logo">
                      <img src={Logo} alt="" className="img" />
                    </div>
                    <div className="description">
                      <div className="tile_header">
                        <div className="title">System</div>
                        <div className="time">20:50:18</div>
                      </div>
                      <div className="tile_details">
                        <div>Status: In progress</div>
                        Amazon has confirmed that order
                      </div>
                    </div>
                  </div>

                  <Pagination
                    defaultCurrent={1}
                    total={30}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "33px",
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default CaseOverview;
