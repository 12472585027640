import React, {useEffect, useState} from "react";
import TableView from "../../Components/TableView";
import {ALL_CASES_COLOMNS, NEW_CASE_COLUMNS_ADMIN, REPORTS_COLUMNS} from "../../utils/constant";

import {useNavigate, useSearchParams} from "react-router-dom";
import {axiosBaseUrl} from "../../config/axios-configuration";
import {toast} from "react-toastify";
import Rows from './Rows'
import {Card, Col, Row} from "antd";
import {CheckCircleFilled, ExclamationCircleFilled} from "@ant-design/icons";

function CronReports() {
    const axios = axiosBaseUrl();
    const [pageNo, setPageNo] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [size, setSize] = useState(100);
    const [caseType, setCaseType] = useState("All");
    const [storeValue, setStoreValue] = useState("All");
    const [activeOptions, setActiveOptions] = useState({});
    const [transactionUpdated, setTransactionUpdated] = useState(false);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [reportsAdj, setReportsAdj] = useState([])

    useEffect(() => {
        getReports()
    }, []);

    const getReports = async () => {
        setIsLoading(true)
        await axios.get('/reports', {}).then(res => {
            setIsLoading(false)
            console.log(res.data)
            setReportsAdj(res.data.jobs)
        }).catch(err => {
            setIsLoading(false)
            toast.error(err.message);
            navigate("/users");
        });
    }

    const calculateIfDaysIsCorrect = (date) => {
        const date1 = new Date(date)
        const date2 = Date.now()
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays <= 1
    }
    return (
        <>
            <Row>
                <Card>
                    <h1>Status</h1>
                    <Row>
                        Report Adjustment Status:
                        {reportsAdj.filter(x => !x.data.storeId && x.name === 'create-adjustment-report')[0]?.state === '_COMPLETED_'
                            && calculateIfDaysIsCorrect(reportsAdj.filter(x => !x.data.storeId && x.name === 'create-adjustment-report')[0]?.lastRunAt) ?
                            <CheckCircleFilled style={{color:"green"}}/> : <ExclamationCircleFilled style={{color:"red"}}/>}
                    </Row>
                    <Row>
                        Report Reimbursement Status:
                        {reportsAdj.filter(x => !x.data.storeId && x.name === 'create-reimbursement-report')[0]?.state === '_COMPLETED_'
                        && calculateIfDaysIsCorrect(reportsAdj.filter(x => !x.data.storeId && x.name === 'create-reimbursement-report')[0]?.lastRunAt) ?
                            <CheckCircleFilled style={{color:"green"}}/> : <ExclamationCircleFilled style={{color:"red"}}/>}
                    </Row>
                </Card>
            </Row>
            <Row>
                <Col md={24}>
                    <label><strong>Adjustment Report</strong></label>
                    <TableView
                        allColumns={REPORTS_COLUMNS}
                        setActiveOptions={setActiveOptions}
                        activeOptions={activeOptions}
                        Rows={Rows}
                        data={reportsAdj}
                        loading={isLoading}
                        setIsModalOpen={setIsModalOpen}
                        setPageNo={setPageNo}
                        setSize={setSize}
                        pageNo={pageNo}
                        size={size}
                        total={total}
                        setTransactionUpdated={setTransactionUpdated}
                    />
                </Col>
            </Row>
        </>
    );
}

export default CronReports;
