import React, {useEffect, useState} from "react";
import axios from "axios";
import {Collapse} from "antd";
import {CSVLink} from "react-csv";
import SingleCase from "./SingleCase.jsx";

const LostInbound = () => {
    const [stores, setStores] = useState([])

    const getStores = async () => {
        axios.get("/cases/get-lost-inbound")
            .then(x => setStores(x.data))
    }
    useEffect(() => {
        getStores()
    }, []);

    const groupByManagingAccount = (items) => {
        return Object.entries(
            items.reduce((acc, item) => {
                const key = item.managingAccount;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(item);
                return acc;
            }, {})
        ).map(([key, values]) => ({key, items: values}))
    }
    const createCsv = (cases) => {
        const textItems = cases.flatMap(y => y.items.map(x => {
            return [
                y.shipmentId,
                x.fnsku,
                x.asin,
                x.foundReceipts ? x?.foundReceipts[0]?.title : "",
                y.fullfillmentInbound.inboundPlan.createdAt,
                x.quantity]
        }))
        const items = cases.flatMap(x => x.items)
        const totalMissing = items.reduce((acc, item) => acc + (item.quantity < 0 ? item.quantity : 0), 0)
        const totalSurplus = items.reduce((acc, item) => acc + (item.quantity > 0 ? item.quantity : 0), 0)
        const textTotal = [`Totale quantità persa ${totalMissing}`, `Totale quantità surplus ${totalSurplus}`]
        const headerItems = ['ShipmentId', 'FNSKU', 'ASIN', 'Titolo', 'Data smarrimento', 'Quantità']
        const emptyRow = []
        const csv = []
        csv.push(headerItems)
        for (const item of textItems) {
            csv.push(item)
        }
        csv.push(emptyRow)
        csv.push(textTotal)
        return csv
    }
    return <>
        <Collapse items={groupByManagingAccount(stores).map((managingAccount, index) => ({
            key: index,
            label: managingAccount.key,
            children: <Collapse items={managingAccount.items.map((store, index) => ({
                key: index,
                label: <>{store.name}</>,
                children: <>
                    <CSVLink data={createCsv(store.cases)}>Download CSV</CSVLink>
                    {store?.cases?.map(x => {
                        return <SingleCase x={x} store={store}></SingleCase>
                    })}
                    </>
            }))}/>
        }))}/>
    </>
}

export default LostInbound;