import {Button, Modal} from "antd";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {SELLER_CENTRAL_URL} from "../../utils/constant";

const CaseDetailModal = ({caseId}) => {
    const [open, setOpen] = useState(false);
    const [reimbursements, setReimbursements] = useState([])

    const fetchReimbursements = () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}cases/reimbursements-case/${caseId}`)
            .then(response => {
                setReimbursements(response.data);
            })
            .catch(error => {
                // Handle errors here
                console.error('Error fetching reimbursements:', error);
            });
    };

    useEffect(() => {
        if (open) {
            fetchReimbursements()
        }
    }, [open]);

    return (<>
        <Button onClick={() => setOpen(true)}>Details</Button>
        <Modal
            title={"Case details: " + caseId}
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            okText="Close"
            cancelButtonProps={{style: {display: 'none'}}}
        >
            {reimbursements && reimbursements.map(x => <p><a
                target="_blank"
                href={`${SELLER_CENTRAL_URL[x?.case[0].country] ||
                "https://sellercentral.amazon.it"
                }/reportcentral/REIMBURSEMENTS/0/%7B"filters":["","","${x.reimbursementId}",""],"pageOffset":1,"searchDays":365%7D`}
                className="queryLink">
                {x.reimbursementId}
            </a> - {x.fnsku} - {x.reason} - {x.currencyUnit} {x.amountTotal} - {x.quantityReimbursedTotal} pz. </p>)}
        </Modal>
    </>)
}

export default CaseDetailModal;