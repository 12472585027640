import {Modal} from "antd";
import * as PropTypes from "prop-types";
import React from "react";

const CronReport = (props) => {
    return <div>

        {props.loaded && props.user && props.user.role === "Admin" ?
            <Modal
                title="Last Cron Error"
                open={!props.lastCronStatus}
                onOk={props.onOk}
                onCancel={props.onOk}
                okText="Ok"
                closable={false}
                cancelButtonProps={{style: {display: "none"}}}
            >
                <p>
                    Yesterday Cronjob didn't run
                </p>
            </Modal> : <></>}
    </div>;
}

CronReport.propTypes = {
    loaded: PropTypes.bool,
    user: PropTypes.any,
    lastCronStatus: PropTypes.bool,
    onOk: PropTypes.func
};
export default CronReport