import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import {
    SetState,
    CreateStore,
    amazonStoreInfo,
} from "../../redux/slices/store-slice";
import {toast} from "react-toastify";

const ConnectMarketPlaces = () => {
    const dispatch = useDispatch();
    const {region, state, storeId, storeSuccess, err} = useSelector(
        (state) => state.stores
    );
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        dispatch(SetState({field: "storeSuccess", value: false}));
        dispatch(SetState({field: "err", value: ""}));
        dispatch(SetState({field: "storeId", value: ""}));

        const params = new URLSearchParams(location.search);
        if (params.get("selling_partner_id") && state === params.get("state")) {
            dispatch(
                CreateStore({
                    authCode: params.get("spapi_oauth_code"),
                    sellerId: params.get("selling_partner_id"),
                    region,
                })
            ).then(d => {
                console.log(d)
                if (d.type === "store/create-store/rejected") {
                    toast.error(d.payload.err ?? "Something went wrong. Please try again!");
                } else if (d.type === "store/create-store/fulfilled") {
                    toast.success(d.payload.message);
                }
            })
        } else {
            dispatch(SetState({field: "err", value: "Error in store creation"}));
        }

        return () => {
            dispatch(
                amazonStoreInfo({
                    state: "",
                    region: "",
                    storeId: "",
                })
            );
            dispatch(SetState({field: "storeId", value: ""}));
            dispatch(SetState({field: "storeSuccess", value: false}));
        };
    }, []);

    useEffect(() => {
        console.log(err, storeSuccess, storeId)
        if (err) {
            navigate("/stores");
            console.error(err)
        } else if (storeSuccess) {
            navigate("/stores");
            window.location.reload()
        }
    }, [err, storeSuccess, storeId]);

    return (
        <div className="store_container">
            <Button variant="primary" disabled>
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                Connecting Marketplaces...
            </Button>
        </div>
    );
};

export default ConnectMarketPlaces;
