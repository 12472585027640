import React, {useEffect, useState} from "react";
import {Modal, Divider, Row, Col} from "antd";
import {CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import {useSelector, useDispatch} from "react-redux";
import {json, useNavigate} from "react-router-dom";
import {isEmpty} from "lodash";

import BillingAddress from "./BillingAddress";
import Setting from "./setting";
import "./PaymentSetting.css";
import {toast} from "react-toastify";


import {
    UpdateCustomer,
    CreateCustomer,
    SetState,
    GetUser,
    LogOut,
    UpdateBillingInformation,
} from "../../redux/slices/auth-slice";

function PaymentModal({
                          open,
                          handleClose,
                          payment,
                          setPayment,
                          isNextButtonDisabled,
                          setIsNextButtonDisabled,
                          setOpen,
                          isLogout = false,
                          paymentState,
                          setPaymentState,
                          fromPage = "setting",
                      }) {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {user, customerCreated, customerUpdated} = useSelector(
        (state) => state.auth
    );
    const [paymentAlreadySet, setPaymentAlreadySet] = useState(!!user?.payment?.vatNumber)
    const [disable, setDisable] = useState(false);
    const [firstClickSet, setFirstClickSet] = useState(false);
    const [secondClickSet, setSecondClickSet] = useState(false);
    const [needsCard, setNeedsCard] = useState(false)
    const [paymentNextButtonDisabled, setPaymentNextButtonDisabled] = useState(true);
    const [contactInfo, setContactInfo] = useState([])

    useEffect(() => {
        if (customerCreated || customerUpdated) {
            setOpen(false);
            setDisable(false);
            setPaymentState("billing_address");
            dispatch(SetState({field: "customerCreated", value: false}));
            dispatch(SetState({field: "customerUpdated", value: false}));
            dispatch(GetUser());
        }
        setNeedsCard(!(user?.payment?.cardLast4Digits || user?.role === 'Iban'))
    }, [customerCreated, customerUpdated, user]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(fromPage, paymentState);
        console.log("Payment is", payment)

        if (fromPage === "dashboard" || needsCard) {
            if (paymentState === "billing_address") {
                setPaymentState("setting");
                dispatch(UpdateBillingInformation({
                    billingInformation: payment,
                    vatNumber: payment.vatId,
                    contactInfo
                })).then((d) => {
                    console.log(d)
                    if (d.type === "auth/update-billing-information/rejected") {
                        toast.error("Something went wrong. Please try again!");
                    } else if (d.type === "auth/update-billing-information/fulfilled") {
                        toast.success("Billing data saved successfully.");
                        setPaymentAlreadySet(true)
                    }
                });
            } else {
                if (!stripe || !elements) {
                    // Stripe.js has not yet loaded.
                    // Make sure to disable form submission until Stripe.js has loaded.
                    return;
                }
                if (paymentState === "billing_address") {
                    setDisable(true);
                    setIsNextButtonDisabled(true);
                }
                const card = elements.getElement(CardElement);
                const {error, token} = await stripe.createToken(card);
                if (error) {
                    toast.error(error.message);
                }
                if (token) {
                    if (user && !isEmpty(user.payment) && user.payment.stripeUserId) {
                        dispatch(
                            UpdateCustomer({
                                token,
                                stripeUserId: user?.payment?.stripeUserId,
                                name: payment.companyName,
                                billingInformation: payment,
                                vatNumber: payment.vatId,
                            })
                        ).then((d) => {
                            if (d.type === "stripe/update-customer/rejected") {
                                toast.error("Something went wrong. Please try again!");
                            } else if (d.type === "store/delete-store/fulfilled") {
                                toast.success("Customer updated successfully.");
                            }
                        })
                    } else {
                        dispatch(
                            CreateCustomer({
                                token,
                                name: payment.companyName,
                                billingInformation: payment,
                                vatNumber: payment.vatId,
                            })
                        ).then((d) => {
                            if (d.type === "stripe/create-customer/rejected") {
                                toast.error("Something went wrong. Please try again!");
                            } else if (d.type === "stripe/create-customer/fulfilled") {
                                toast.success("Customer created successfully.");
                            }
                        });
                    }
                } else {
                    alert("You have incorrect card number");
                }
            }
        } else {
            if (paymentState === "billing_address") {
                const response = await dispatch(
                    UpdateBillingInformation({
                        billingInformation: payment,
                        vatNumber: payment.vatId,
                        contactInfo
                    })
                );

                setOpen(false);

                if (response) {
                    dispatch(GetUser());
                }
            } else {
                const card = elements.getElement(CardElement);
                const {error, token} = await stripe.createToken(card);
                if (error) {
                    toast.error(error.message);
                }
                //TODO create customer also for iban
                if (token) {
                    if (user && !isEmpty(user.payment) && user.payment.stripeUserId) {
                        setDisable(true);
                        dispatch(
                            UpdateCustomer({
                                token,
                                stripeUserId: user?.payment?.stripeUserId,
                                name: payment.companyName,
                            })
                        ).then((d) => {
                            if (d.type === "stripe/update-customer/rejected") {
                                toast.error("Something went wrong. Please try again!");
                            } else if (d.type === "store/delete-store/fulfilled") {
                                toast.success("Customer updated successfully.");
                            }
                        })
                    } else {
                        dispatch(
                            CreateCustomer({
                                token,
                                name: payment.companyName,
                                billingInformation: payment,
                                vatNumber: payment.vatId,
                            })
                        ).then((d) => {
                            if (d.type === "stripe/create-customer/rejected") {
                                toast.error("Something went wrong. Please try again!");
                            } else if (d.type === "stripe/create-customer/fulfilled") {
                                toast.success("Customer created successfully.");
                            }
                        });
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (payment && payment.cardHolderName) {
            setPaymentNextButtonDisabled(false);
        } else {
            setPaymentNextButtonDisabled(true);
        }
    }, [payment]);

    return (<>
            <Modal
                style={{top: 50}}
                disabled={paymentNextButtonDisabled}
                maskClosable={false}
                title={
                    <div className="model_header">
                        <div style={{fontWeight: "bold", fontSize: "18px"}}>
                            {paymentState === "billing_address"
                                ? `Billing Information`
                                : "Payment Method"}
                        </div>
                        {paymentAlreadySet ?
                            <div
                                className="logout_btn"
                                onClick={() => {
                                    setOpen(false)
                                }}>
                                X
                            </div> : <></>}
                    </div>
                }
                open={open}
                onOk={(e) => {
                    if (!firstClickSet) {
                        handleSubmit(e)
                        setFirstClickSet(true)
                    }
                    if (firstClickSet && !secondClickSet) {
                        handleSubmit(e)
                        setSecondClickSet(true)
                    }
                }}
                okText={
                    paymentState === "billing_address" && needsCard
                        ? "Next"
                        : "Submit"
                }
                onClick
                cancelText="Cancel"
                closable={false}
                okButtonProps={{
                    disabled: (paymentState === "billing_address" ? isNextButtonDisabled : false),
                }}
                cancelButtonProps={{style: {display: "none"}}}
                onCancel={() => {
                    setFirstClickSet(false)
                    setSecondClickSet(false)
                    handleClose();
                    if (fromPage === "dashboard" && user?.payment) {
                        setPaymentState("setting");
                    } else {
                        setPaymentState("billing_address");
                    }

                }}
                width={800}
            >
                {paymentState === "billing_address" ? (
                    <p>
                        In questa schermata potete cambiare i vostri dati di fatturazione.
                    </p>
                ) : (
                    <>
                        <p style={{fontWeight: 600}}>No subscription required</p>
                        <p>
                            The {user?.commissionDiscount}% payment will be applied when your products are refund by
                            Amazon
                        </p>
                        <p>The payment will be made at the end of each month.</p>
                    </>
                )}
                <Divider/>
                {paymentState === "billing_address" ? (
                    <BillingAddress
                        setPayment={setPayment}
                        payment={payment}
                        setPaymentState={setPaymentState}
                        isDisabled={paymentAlreadySet}
                        blankInput={fromPage !== "dashboard"}
                        contactInfo={contactInfo}
                        setContactInfo={setContactInfo}
                    />
                ) : (
                    <Setting
                        setPaymentNextButtonDisabled={setPaymentNextButtonDisabled}
                        setPayment={setPayment}
                        payment={payment}
                    />
                )}
                <div className="mt-3">
                    <Col span={6}
                         style={{
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center',
                             float: 'left',
                             cursor: 'pointer',
                             marginTop: '8px',
                             backgroundColor: 'transparent',
                             padding: '4px 15px',
                             maxHeight: '32px',
                             color: 'black',
                         }}>* campo obligatorio</Col>
                </div>
                {isLogout && (
                    <div
                        onClick={() => {
                            dispatch(LogOut());
                            navigate('/');
                        }}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            float: 'left',
                            cursor: 'pointer',
                            marginTop: '10px',
                            borderRadius: '5px',
                            border: '2px solid #e47911',
                            backgroundColor: 'transparent',
                            padding: '4px 15px',
                            maxHeight: '32px',
                            maxWidth: '8rem',
                            color: '#e47911',
                            transition: 'background-color 0.3s ease',
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.backgroundColor = '#ffc67c';
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'transparent';
                        }}
                    >
                        Logout
                    </div>
                )}
            </Modal>
        </>
    );
}

export default PaymentModal;