import {Button, Col, Divider, Input, Row, Select, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import axios from "axios";
import {Check} from "./Check.jsx";
import * as PropTypes from "prop-types";

function ServiceConfig(props) {
    const [enabled, setEnabled] = useState(props.obj.enabled)
    const [status, setStatus] = useState(props.obj.status)
    const [lastUpdate, setLastUpdate] = useState(props.obj.last_status_update)

    return <>
        {props.obj && <>
            <Row>
                <Col md={3}>
                    <p><strong>{props.name}</strong></p>
                </Col>
                <Divider type={"vertical"}></Divider>
                <Col md={6}>
                    <Check
                        value={enabled}
                        callback={props.callback}
                        color={props.color}
                        setter={props.setter}
                        obj={props.obj}>
                    </Check>
                </Col>
                <Divider type={"vertical"}/>
                <Col md={5}>
                    <label><strong>Status</strong></label>
                    <Select
                        value={props.statuses.filter(x => x.value === status)[0]}
                        style={{width: 120}}
                        options={props.statuses}
                        onChange={(e) => {
                            setStatus(e)
                            setLastUpdate(Date.now())
                            props.onChange(e, props.obj, props.setter)
                        }
                        }/>
                </Col>
                <Col md={7}>
                    <label><strong>Last Update</strong>:</label>
                    <p>{new Date(lastUpdate ?? Date.now()).toISOString()}</p>
                </Col>
            </Row>
        </>}
    </>;
}

ServiceConfig.propTypes = {
    name: PropTypes.string,
    obj: PropTypes.shape({enabled: PropTypes.bool}),
    callback: PropTypes.func,
    color: PropTypes.any,
    statuses: PropTypes.any,
    onChange: PropTypes.func,
    setter: PropTypes.func
};

const ServicesManagement = ({record, color}) => {
    const [value, setValue] = useState(record.servicesConfig)
    const [modalOpen, setModalOpen] = useState(false)
    const [name, setName] = useState(record.name)
    const [enabled, setEnabled] = useState(record.enabled)
    const [managingAccount, setManagingAccount] = useState(record.managingAccount)
    const [lostWarehouse, setlostWarehouse] = useState(record.servicesConfig?.lost?.warehouse)
    const [lostInbound, setlostInbound] = useState(record.servicesConfig?.lost?.inbound)
    const [damagedWarehouse, setdamagedWarehouse] = useState(record.servicesConfig?.damaged?.warehouse)
    const [damagedInbound, setdamagedInbound] = useState(record.servicesConfig?.damaged?.inbound)
    const [damagedOutbound, setdamagedOutbound] = useState(record.servicesConfig?.damaged?.outbound)
    const [disposed, setdisposed] = useState(record.servicesConfig?.disposed)

    const submit = () => {
        axios.post("/store/update-store", {
            _id: record._id,
            servicesConfig: {
                lost: {warehouse: lostWarehouse, inbound: lostInbound},
                damaged: {warehouse: damagedWarehouse, inbound: damagedInbound, outbound: damagedOutbound},
                disposed: disposed
            },
            managingAccount: managingAccount ?? ':praticheamz',
            name: name,
            enabled
        })
            .then(res => {
                toast.success(`Services modified successfully for seller ${record.sellerId}`);
                setModalOpen(false)
            })
            .catch(err => {
                toast.error(err.message);
            });
    }
    const callback = (val, obj, setter) => {
        const tmp = obj
        obj.enabled = val
        setter(tmp)
    }

    const callbackStatus = (val, obj, setter) => {
        const tmp = obj
        obj.status = val
        obj.last_status_update = Date.now()
        setter(tmp)
    }
    const statuses = [
        {value: "", label: ''},
        {value: "Progress", label: "Progress"},
        {value: "Done", label: "Done"},
        {value: "Done - Nulla", label: "Done - Nulla"},
        {value: "Pausa", label: "Pausa"},
        {value: "Non vuole il servizio", label: "Non vuole il servizio"},
    ]
    const managingAccounts = [
        {value: "", label: ''},
        {value: ':praticheamz', label: ':praticheamz'},
        {value: ':praticheamz1', label: ':praticheamz1'},
        {value: ':praticheamz2', label: ':praticheamz2'},
        {value: ':praticheamz3', label: ':praticheamz3'},
        {value: ':praticheamz4', label: ':praticheamz4'},
        {value: ':praticheamz5', label: ':praticheamz5'},
        {value: ':casiatlantik', label: ':casiatlantik'},
    ]
    return (<>
                <Row>
                    <Divider/>
                    <Col md={16}>
                        <h3>Services</h3>
                        <ServiceConfig
                            name={'LOST - WAREHOUSE'}
                            obj={lostWarehouse ?? {}}
                            callback={callback}
                            color={color}
                            statuses={statuses}
                            setter={setlostWarehouse}
                            onChange={callbackStatus}/>
                        <ServiceConfig
                            name={'LOST - INBOUND'}
                            obj={lostInbound ?? {}}
                            callback={callback}
                            color={color}
                            statuses={statuses}
                            setter={setlostInbound}
                            onChange={callbackStatus}/>
                        <ServiceConfig
                            name={'DAMAGED - WAREHOUSE'}
                            obj={damagedWarehouse ?? {}}
                            callback={callback}
                            color={color}
                            statuses={statuses}
                            setter={setdamagedWarehouse}
                            onChange={callbackStatus}/>
                        <ServiceConfig
                            name={'DAMAGED - INBOUND'}
                            obj={damagedInbound ?? {}}
                            callback={callback}
                            color={color}
                            statuses={statuses}
                            setter={setdamagedInbound}
                            onChange={callbackStatus}/>
                        <ServiceConfig
                            name={'DAMAGED - OUTBOUND'}
                            obj={damagedOutbound ?? {}}
                            callback={callback}
                            color={color}
                            statuses={statuses}
                            setter={setdamagedOutbound}
                            onChange={callbackStatus}/>
                        <ServiceConfig
                            name={'DISPOSED'}
                            obj={disposed ?? {}}
                            callback={callback}
                            color={color}
                            statuses={statuses}
                            setter={setdisposed}
                            onChange={callbackStatus}/>
                    </Col>
                    <Col md={8}>
                        <h3>Info</h3>
                        <label><strong>Managing Account</strong></label>
                        <Select
                            style={{width: 200}}AMA
                            value={managingAccounts.filter(x => x.value === managingAccount)[0]}
                            options={managingAccounts}
                            onChange={setManagingAccount}
                        />
                        <Divider/>
                        <label><strong>Name</strong></label>
                        <Input
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                        <Divider/>
                        <label><strong>Enabled</strong></label>
                        <Switch checked={enabled} onChange={(e) => setEnabled(e)} />
                        <Divider/>
                        <Button type="primary" onClick={submit}>Save Store</Button>
                    </Col>
                </Row>
        </>
    );
}

export default ServicesManagement
