import React, {useState} from "react";
import {Row, Col, Input} from "antd";
import StripForm from "./StripeForm";
import {toast} from "react-toastify";

function Setting({
                     setPayment,
                     payment
                 }) {
    const [tempName, setTempName] = useState(payment?.cardHolderName || '');

    const handleNameChange = (e) => {
        const value = e.target.value;

        // Controlla se il valore contiene soltanto lettere
        if (/^[a-zA-Z\s]*$/.test(value)) {
            setTempName(value);
            console.log("CardHolder's name is", value)
            setPayment({
                ...payment,
                cardHolderName: value
            });
        } else {
            // Mostra il toast solo se non è già aperto
            if (!toast.isActive('nameError')) {
                toast.error("Cardholder name should contain only letters.", {
                    toastId: 'nameError'
                });
            }
        }
    };

    return (
        <div>
            <Row>
                <Col span={6}>Cardholder name *</Col>
                <Col span={12}>
                    <Input
                        placeholder="Cardholder name"
                        onChange={handleNameChange}
                        value={tempName}
                        style={{width: "134%"}}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Credit Card</Col>
                <Col span={12}>
                    <StripForm/>
                </Col>
            </Row>
        </div>
    );
}

export default Setting;