import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Card, Col, Divider, Input, Row, Table} from "antd";
import {CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";

const Leads = () => {
    const [vat, setVat] = useState("")
    const [info, setInfo] = useState()
    const [data, setData] = useState([])

    useEffect(() => {
        fetchBillingInfos()
    }, []);

    const fetchBillingInfo = async () => {
        axios.get(`/auth/billing-info/${vat}`)
            .then(res => {
                const data = res.data.billingInfo.billingInfo
                setInfo(data)
            })
    }

    const fetchBillingInfos = async () => {
        axios.get(`/auth/billing-info`)
            .then(res => {
                const data = res.data
                setData(data)
            })
    }

    const handleChangeVat = async (e) => {
        const vat = e.target.value
        setVat(vat)
    }

    return <>
        <Row className="mt-3">
            <Col span={6}>Partita IVA</Col>
            <Col span={12}>
                <Input
                    onChange={handleChangeVat}
                    value={vat}
                    style={{width: "100%"}}
                    placeholder="Partita IVA - Es. IT13055980018"
                    min={6}
                />
            </Col>
            <Col span={6}>
                <Button
                    disabled={vat.length !== 13}
                    onClick={fetchBillingInfo}
                >
                    Cerca
                </Button>
            </Col>
        </Row>
        <Divider/>
        <Row>
            <Card>
                <label>Name</label>
                <p>{info?.companyName}</p>
                <label>Status</label>
                <p>{info?.activityStatus}</p>
                <label>Start date</label>
                <p>{info?.startDate}</p>
            </Card>
        </Row>
        <Divider/>
        <Row>
            <label>Balance Sheets</label>
            <Divider/>
            {info && info?.balanceSheets.all.sort((a, b) => a.year - b.year).map(x => <>
                <Col md={3}>
                    <p>
                        Year {x.year}
                    </p>
                    <p>
                        Turnover {x.turnover} €
                    </p>
                    <p>
                        NetWorth {x.netWorth} €
                    </p>
                    <p>
                        Employees {x.employees}
                    </p>
                </Col>
            </>)}
        </Row>
        <Divider/>
        <Row>
            <label>Shareholders</label>
            <Divider/>
            {info && info?.shareHolders.map(x => <>
                <Col md={3}>
                    <p>
                        Name {x.name}
                    </p>
                    <p>
                        Surname {x.surname}
                    </p>
                    <p>
                        Share Percentage {x.percentShare}
                    </p>
                    <p>
                        Tax Code {x.taxCode}
                    </p>
                </Col></>)}
        </Row>
        <h2>History</h2>
        {data && data.map(x => {
            const firm = x.billingInfo;
            return <>
                <Row>
                    <Col md={5}>
                        <label>Info</label>
                        <Card>
                            <label>Name</label>
                            <p>{firm?.companyName}</p>
                            <label>Status</label>
                            <p>{firm?.activityStatus}</p>
                            <label>Start date</label>
                            <p>{firm?.startDate}</p>
                            <label>VAT</label>
                            <p>{firm?.vatCode}</p>
                        </Card>
                    </Col>
                    <Col md={5}>
                        <label>Shareholders</label>
                        <Divider/>
                        {firm && firm?.shareHolders?.map(x => <>
                            <Col md={3}>
                                <p>
                                    Name {x.name}
                                </p>
                                <p>
                                    Surname {x.surname}
                                </p>
                                <p>
                                    Share Percentage {x.percentShare}
                                </p>
                                <p>
                                    Tax Code {x.taxCode}
                                </p>
                            </Col></>)}
                    </Col>
                    <Col md={8}>
                        <label>Balance Sheets</label>
                        <Divider/>
                        {/*{firm && firm?.balanceSheets?.all.sort((a, b) => a.year - b.year).map(x => <>*/}
                        {/*    <Col md={3}>*/}
                        {/*        <p>*/}
                        {/*            Year {x.year}*/}
                        {/*        </p>*/}
                        {/*        <p>*/}
                        {/*            Turnover {x.turnover} €*/}
                        {/*        </p>*/}
                        {/*        <p>*/}
                        {/*            NetWorth {x.netWorth} €*/}
                        {/*        </p>*/}
                        {/*        <p>*/}
                        {/*            Employees {x.employees}*/}
                        {/*        </p>*/}
                        {/*    </Col>*/}
                        {/*</>)}*/}
                        <LineChart width={700} height={500} data={firm?.balanceSheets?.all?.length && firm?.balanceSheets?.all?.map(x => ({
                            name: x.year,
                            turnover: x.turnover,
                            netWorth: x.netWorth,
                            employees: x.employees
                        }))} accessibilityLayer>
                            <XAxis dataKey="name"/>
                            <YAxis/>
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                            <Line type="monotone" dataKey="turnover" stroke="#8884d8"/>
                            <Line type="monotone" dataKey="netWorth" stroke="#82ca9d" />
                            <Line type="monotone" dataKey="employees" stroke="#82ca9d" />
                            <Tooltip/>
                        </LineChart>
                    </Col>
                </Row>
                <Divider/>
            </>
        })}

    </>
}

export default Leads