import React, {useEffect, useState} from "react";
import "./Login.css";
import {Input} from "antd";
import {Button} from "antd";
import {SignIn} from "../../redux/slices/auth-slice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Loader from "../../Components/Spin";
import Logo from "../../Components/Logo";
import {toast} from "react-toastify";

function Login() {
    const [loginValues, setLoginValues] = useState({email: "", password: ""});
    const [isDisabled, setIsdisabled] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token, user, loading, err} = useSelector((state) => state.auth);

    useEffect(() => {
        if (token) {
            if (user?.role === "Admin" || user?.role === 'SuperAdmin') {
                navigate("/users");
            } else if (user?.role === 'Operator') {
                navigate("/manage-new-cases");
            } else if (user?.role === 'Seller') {
                navigate("/seller/users");
            }else {
                navigate("/all-cases");
            }
        }
    }, [token]);

    useEffect(() => {
        if (err && !isDisabled) {
            toast.error(err, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [err]);

    useEffect(() => {
        const {email, password} = loginValues;
        if (email && password) {
            setIsdisabled(false);
        } else {
            setIsdisabled(true);
        }
    }, [loginValues]);

    const inputChangeHandle = (e) => {
        const {name = "", value = ""} = e?.target || {};
        const newValue = value.replace(/\s/g, "");
        setLoginValues({
            ...loginValues,
            [name]: newValue,
        });
    };

    const signInHandle = () => {
        dispatch(SignIn(loginValues));
    };

    return (
        <div className="login_container">
            <div className="login_wrapper">
                <Logo/>
                <h4 className="login_heading">SIGN IN TO YOUR ACCOUNT</h4>
                <Input
                    size="large"
                    placeholder="Email"
                    className="mt-2"
                    name="email"
                    onChange={inputChangeHandle}
                    value={loginValues.email}
                />
                <Input.Password
                    size="large"
                    placeholder="Password"
                    className="mt-3"
                    name="password"
                    onChange={inputChangeHandle}
                    value={loginValues.password}
                />
                <Button
                    block
                    size="large"
                    className="mt-3"
                    onClick={signInHandle}
                    disabled={isDisabled}
                >
                    {loading ? <Loader/> : "SIGN IN"}
                </Button>
                <div className="login_forgot_password">
                    <Button type="link" className="mt-4 forgot_password_btn"
                            onClick={() => {
                                navigate("/reset-password");
                            }}
                    >
                        Forgot your password?

                    </Button>
                    <Button
                        type="link"
                        className="mt-4 create_account"
                        onClick={() => {
                            navigate("/signup");
                        }}
                    >
                        Create Account
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Login;
