import React, {useEffect} from "react";
import {Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select, Switch} from "antd";
import {useSelector} from "react-redux";
import TextArea from "antd/es/input/TextArea";
import {Check} from "../../Components/Check";
import Verified from "../../assets/Instagram-Verification Badge-24.png";

function UserInfo({
                      info,
                      setInfo,
                      blankInput,
                      isDisabled,
                      isAdmin,
                      email
                  }) {
    useEffect(() => {
        if (blankInput) {
            setInfo({});
        }
    }, []);

    const handleInputChange = (key, value) => {
        const change = {
            ...info,
            [key]: value
        }
        setInfo(change)
    }
    const roles = [
        {value: 'Card', label: 'Card'},
        {value: 'Iban', label: 'Iban'},
        {value: 'Cash', label: 'Cash'},
        {value: 'Operator', label: 'Operator'},
        {value: 'Seller', label: 'Seller'},
        {value: 'Admin', label: 'Admin', disabled: true},
        {value: 'SuperAdmin', label: 'SuperAdmin', disabled: true}
    ]
    const statuses = [
        {value: 'Trial', label: 'Trial'},
        {value: 'Active', label: 'Active'},
    ]

    const TaxRates = [
        {value: "0.22", label: "22%"},
        {value: "0.1", label: "10%"},
        {value: "0.05", label: "5%"},
        {value: "0.04", label: "4%"},
        {value: "0", label: "0%"},
    ]

    const ExemptionReasons = [
        {value: "N1", label: "N1"},
        {value: "N2", label: "N2"},
        {value: "N2.1", label: "N2.1"},
        {value: "N2.2", label: "N2.2"},
        {value: "N3", label: "N3"},
        {value: "N3.1", label: "N3.1"},
        {value: "N3.2", label: "N3.2"},
        {value: "N3.3", label: "N3.3"},
        {value: "N3.4", label: "N3.4"},
        {value: "N3.5", label: "N3.5"},
        {value: "N3.6", label: "N3.6"},
        {value: "N4", label: "N4"},
        {value: "N5", label: "N5"},
        {value: "N6", label: "N6"},
        {value: "N6.1", label: "N6.1"},
        {value: "N6.2", label: "N6.2"},
        {value: "N6.3", label: "N6.3"},
        {value: "N6.4", label: "N6.4"},
        {value: "N6.5", label: "N6.5"},
        {value: "N6.6", label: "N6.6"},
        {value: "N6.7", label: "N6.7"},
        {value: "N6.8", label: "N6.8"},
        {value: "N6.9", label: "N6.9"},
        {value: "N7", label: "N7"},
        {value: "S293B", label: "S293B"},
        {value: "S262.1", label: "S262.1"},
        {value: "S259", label: "S259"},
        {value: "S283", label: "S283"},
        {value: "S261", label: "S261"},
        {value: "S262", label: "S262"},
        {value: "S263", label: "S263"},
        {value: "S19.1", label: "S19.1"},
        {value: "S4.1B", label: "S4.1B"},
        {value: "S4.1A", label: "S4.1A"},
        {value: "S4", label: "S4"},
        {value: "S13B", label: "S13B"},
        {value: "S122", label: "S122"},
        {value: "S25", label: "S25"},
        {value: "S21", label: "S21"},
        {value: "S69", label: "S69"},
        {value: "S20", label: "S20"},
        {value: "S84.1.2", label: "S84.1.2"},
    ]

    let {user} = useSelector(state => state.auth)
    return (
        <>
            <Row>
                <Col span={6}>Email *</Col>
                <Col span={12}>
                    <Input
                        placeholder="Email"
                        value={isAdmin ? info?.email : user?.email}
                        style={{width: '100%'}}
                        onChange={(e) => {
                            handleInputChange('email', e.target.value)
                        }}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Role</Col>
                <Col span={12}>
                    <Select
                        value={roles.filter(x => x.value === info?.role)[0]}
                        style={{width: '100%'}}
                        options={roles}
                        onChange={(e) => {
                            handleInputChange('role', e)
                        }}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Commission Discount</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('commissionDiscount', e.target.value)}
                        value={info?.commissionDiscount}
                        style={{width: '100%'}}
                        disabled={isDisabled}
                        type="number"
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Invoice Payment Threshold</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('invoicePaymentThreshold', e.target.value)}
                        value={info?.invoicePaymentThreshold}
                        style={{width: '100%'}}
                        disabled={isDisabled}
                        type="number"
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Status</Col>
                <Col span={12}>
                    <Select
                        disabled
                        value={statuses.filter(x => x.value === info?.status)[0]}
                        style={{width: '100%'}}
                        options={statuses}
                        onChange={(e) => {
                            handleInputChange('status', e)
                        }}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Tax</Col>
                <Col span={12}>
                    <Select
                        value={TaxRates.filter(x => x.value === info?.tax)[0]}
                        style={{width: '100%'}}
                        options={TaxRates}
                        onChange={(e) => {
                            handleInputChange('tax', e)
                        }}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Tax Exemption</Col>
                <Col span={12}>
                    <Select
                        value={ExemptionReasons.filter(x => x.value === info?.taxExemption)[0]}
                        style={{width: '100%'}}
                        options={ExemptionReasons}
                        onChange={(e) => {
                            handleInputChange('taxExemption', e)
                        }}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Producer</Col>
                <Col span={12}>
                    <Switch checked={info?.producer} onChange={(e) => {
                        handleInputChange('producer', e)
                    }}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Notes producer</Col>
                <Col span={12}>
                    <TextArea
                        onChange={(e) => handleInputChange('notes_producer', e.target.value)}
                        value={info?.notes_producer}
                        type="text"
                        size={'middle'}
                        style={{width: "134%"}}
                        disabled={isDisabled}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Reseller</Col>
                <Col span={12}>
                    <Switch checked={info?.reseller} onChange={(e) => {
                        handleInputChange('reseller', e)
                    }}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Notes reseller</Col>
                <Col span={12}>
                    <TextArea
                        onChange={(e) => handleInputChange('notes_reseller', e.target.value)}
                        value={info?.notes_reseller}
                        type="text"
                        size={'middle'}
                        style={{width: "134%"}}
                        disabled={isDisabled}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Notes</Col>
                <Col span={12}>
                    <TextArea
                        onChange={(e) => handleInputChange('notes', e.target.value)}
                        value={info?.notes}
                        type="text"
                        size={'middle'}
                        style={{width: "134%"}}
                        disabled={isDisabled}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={6}>Website</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('website', e.target.value)}
                        value={info?.website}
                        type="text"
                        style={{width: "134%"}}
                        disabled={isDisabled}
                    />
                </Col>
            </Row>
            <Col md={7}>
                <h3><strong>Modals</strong></h3>
                <Col>
                    <label>Modal Call </label>
                    <Switch checked={info?.showConsultation} onChange={(e) => {
                        handleInputChange('showConsultation', e)
                    }}/>
                </Col>
                <Divider type={"vertical"}/>
                <Col>
                    <label>Update payment method </label>
                    <Switch checked={info?.showPaymentModal} onChange={(e) => {
                        handleInputChange('showPaymentModal', e)
                    }}/>
                </Col>
                <Divider type={"vertical"}/>
                <Col>
                    <label>Last Invoice Status </label>
                    <Switch checked={info?.lastInvoicePaid} onChange={(e) => {
                        handleInputChange('lastInvoicePaid', e)
                    }}/>
                </Col>
                <Divider type={"vertical"}/>
                <Col>
                    <label>Account Blocked </label>
                    <Switch checked={info?.blocked} onChange={(e) => {
                        handleInputChange('blocked', e)
                    }}/>
                </Col>
                <Divider type={"vertical"}/>
                <Col>
                    <label>NewCases visible </label>
                    <Switch checked={info?.newCasesVisible} onChange={(e) => {
                        handleInputChange('newCasesVisible', e)
                    }}/>
                </Col>
            </Col>
        </>
    );
}

export default UserInfo;
