import {Button, Modal} from "antd";
import ExcelToJsonConverter from "../../Components/CSVImporter";
import React, {useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";

const BulkUploadCaseIDs = () => {
    const [modalCsvOpen, setModalCsvOpen] = useState(false)

    const bulkUploadCaseIDs = (data) => {
        axios.post('/adjustments/bulk-create-cases', data)
            .then(res => {
                setModalCsvOpen(false)
            }).catch(err => {
            toast.error(err.message);
        });
    }
    return (<>
            <Button
                type="primary"
                style={{marginLeft: "15px",}}
                onClick={(e) => {
                    setModalCsvOpen(true)
                }}>
                Upload Excel CaseIds
            </Button>
            <Modal
                title="Upload Case Ids"
                open={modalCsvOpen}
                onCancel={() => {
                    setModalCsvOpen(false)
                }}
                footer={[
                    <Button key="cancel" onClick={() => {
                        setModalCsvOpen(false)
                    }}>
                        Cancel
                    </Button>,
                    <Button
                        type="primary"
                        style={{marginLeft: "15px",}}
                        onClick={() => {
                            setModalCsvOpen(false)
                        }}>
                        Ok
                    </Button>
                ]}
            >
                <ExcelToJsonConverter callback={bulkUploadCaseIDs}/>
            </Modal>
        </>
    )
}
export default BulkUploadCaseIDs