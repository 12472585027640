import React, {useEffect, useMemo, useState} from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import ActionRequired from "./Pages/ActionRequired/ActionRequired";
import Dashboard from "./Pages/Dashboard/Dashboard";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Login from "./Pages/Login/Login";
import SignUp from "./Pages/SignUp/SignUp";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import Stores from "./Pages/Stores/Stores";
import PaymentSetting from "./Pages/PaymentSetting/PaymentSetting";
import {useDispatch, useSelector} from "react-redux";
import StoreConnection from "./Pages/Stores/StoreConnection";
import AllCases from "./Pages/AllCases/AllCases";
import {toast, ToastContainer} from "react-toastify";
import Users from "./Pages/Users/Users";
import {loadStripe} from "@stripe/stripe-js";
import {axiosBaseUrl, setAuthToken} from "./config/axios-configuration";
import {Elements} from "@stripe/react-stripe-js";
import CaseOverview from "./Pages/CaseOverview/CaseOverview";
import {PrivacyPolicy} from "./Pages/PrivacyPolicy/PrivacyPolicy";
import {TermsAndConditions} from "./Pages/TermsAndConditions/TermsAndConditions";
import {PopupModal, useCalendlyEventListener} from "react-calendly";
import AdminAllCases from "./Pages/AdminAllCases/AdminAllCases";
import AdminNewCases from "./Pages/AdminNewCases/AdminNewCases";
import NewCases from "./Pages/NewCases/NewCases";
import Invoices from "./Pages/Invoices/Invoices";
import CronReports from "./Pages/CronReports/CronReports";
import ManageNewCases from "./Pages/NewCases/ManageNewCases";
import AdminDashboard from "./Pages/Dashboard/AdminDashboard";
import {TermsApproval} from "./Pages/TermsAndConditions/TermsApproval.js";
import {UsersSeller} from "./Pages/Users/UsersSeller.jsx";
import {LogOut} from "./redux/slices/auth-slice.js";
import {nanoid} from "nanoid";
import {amazonStoreInfo} from "./redux/slices/store-slice.js";
import {ReimbursementsRecheck} from "./Pages/Reimbursements/ReimbursementsRecheck";
import StoreManagement from "./Pages/StoreManagement/StoreManagement";
import AccountBlocked from "./Components/Modals/AccountBlocked.jsx";
import TermsConditions from "./Components/Modals/TermsConditions.jsx";
import CronReport from "./Components/Modals/CronReport.jsx";
import AmazonAccount from "./Components/Modals/AmazonAccount.jsx";
import Leads from "./Pages/Leads/Lead.jsx";
import LostInbound from "./Pages/LostInbound/LostInbound.jsx";

function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const [open, setOpen] = useState(false);
    const [showConsultation, setShowConsultation] = useState("");
    const [isModalPaymentOpen, setIsModalPaymentOpen] = useState(false);
    const [isModalBlockedOpen, setIsModalBlockedOpen] = useState(false);
    const [isNewCasesVisible, setIsNewCasesVisible] = useState(false);
    const [isLatestTecApproved, setLatestTecApproved] = useState(true);
    const [lastCronStatus, setLastCronStatus] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const {token, user} = useSelector((state) => state.auth);
    setAuthToken(token);
    const isAdministrativeAccount = user?.role === "Admin" || user?.role === "SuperAdmin" || user?.role === "Operator"
    const availablePathsWithoutToken = useMemo(() => {
        return ["/signup", "/subscribe", "/reset-password", "/privacy-policy", "/termini-e-condizioni", "/disable-calendly"];
    }, []);
    const location = useLocation();
    const isPaymentSettingPage = location.pathname === "/payment-setting";
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [region, setRegion] = useState("eu");

    const axios = axiosBaseUrl();

    const getStores = async () => {
        const response = await axios.get("/store/get-stores");
        console.log(response)
        if (!response?.data || !response?.data?.stores?.length > 0) {
            setIsModalOpen(true);
        }
    }

    const handleConnect = () => {
        const state = nanoid(64);
        const payload = {
            state,
            region,
        };
        dispatch(amazonStoreInfo(payload));

        let url;
        if (region === "eu") {
            url = `https://sellercentral.amazon.it/apps/authorize/consent?application_id=amzn1.sp.solution.d3f1dc25-6c1f-40ed-a5e2-e76ed049a085&state=${state}&version=beta&redirect_uri=https://app.sosfba.it/auth/store-connection`;
        } else if (region === "na") {
            url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.d3f1dc25-6c1f-40ed-a5e2-e76ed049a085&state=${state}&version=beta&redirect_uri=https://app.sosfba.it/auth/store-connection`;
        } else {
            url = `https://sellercentral-japan.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.d3f1dc25-6c1f-40ed-a5e2-e76ed049a085&state=${state}&version=beta&redirect_uri=https://app.sosfba.it/auth/store-connection`;
        }
        window.open(url, "_self");
    };

    useEffect(() => {
        if (!token) {
            if (window.location.pathname.includes("/reset-password")) {
                navigate(window.location.pathname);
            } else if (availablePathsWithoutToken.includes(window.location.pathname)) {
                navigate(window.location.pathname);
            } else {
                navigate("/signin");
            }
        } else {
            if (user?.role === "Admin") {
                // navigate("/users");
            } else {
                window.location.pathname === "/"
                    ? navigate("/new-cases")
                    : navigate(window.location.pathname);
            }
        }
        console.log(token)
        if (token) getUserDetails()
        if (user?.role === 'Admin' || user?.role === 'Operator') getReportsStatus()
        if (token) getStores();
    }, [token]);

    useEffect(() => {
        if(process.env.NODE_ENV === 'production') {
            const handleContextmenu = e => {
                e.preventDefault()
            }
            document.addEventListener('contextmenu', handleContextmenu)
            return function cleanup() {
                document.removeEventListener('contextmenu', handleContextmenu)
            }
        }
    }, []);

    const getReportsStatus = async () => {
        if (user) {
            await axios.get("/reports/status").then(res => {
                setLastCronStatus(res.data.adjustment && res.data.reimbursement)
                setLoaded(true)
            });
        }
    };

    const updatePaymentModalFlag = async () => {
        await axios.post("/auth/disable-user-payment-modal", {_id: user._id}).then(res => {
            console.log(res, "Update User");
        }).catch(err => {
            toast.error(err.message)
        })
    }

    const getUserDetails = () => {
        if (user) {
            axios.get("/auth/get-user")
                .then(res => {
                    const user = res.data.user
                    if (res.status === 200 && res.data && res.data.success) {
                        setIsModalBlockedOpen(user.blocked)
                        setLatestTecApproved(user?.termsAndConditionsApproval?.latestApproved)
                        console.log('setting')
                        setIsNewCasesVisible(user.newCasesVisible)
                        if (!user.lastInvoicePaid) {
                            setIsModalPaymentOpen(true);
                        } else {
                            setIsModalPaymentOpen(false);
                        }
                        if (user.showConsultation) {
                            setShowConsultation(user.showConsultation)
                            if (user.showConsultation) {
                                setOpen(true)
                            } else {
                                setOpen(false)
                            }
                        }
                    } else {
                        setOpen(false)
                    }
                }).catch((err) => console.error(err));
        }
    }

    const updateConsultation = async () => {
        await axios.post("/auth/update-user", {_id: user._id}).then(res => {
            console.log(res, "Update User");
        }).catch(err => {
            toast.error(err.message)
        })
    }

    useCalendlyEventListener({
        onEventScheduled: (e) => {
            setShowConsultation(false)
            updateConsultation();
            setTimeout(() => {
                setOpen(false)
            }, 1000)
        }
    });

    const handleSettings = () => {
        updatePaymentModalFlag()
        setIsModalPaymentOpen(false)
        setTimeout(() => {
            navigate("/payment-setting");
        }, 100)
    };

    const handleTec = () => {
        setIsModalPaymentOpen(false)
        setTimeout(() => {
            navigate("/terms-approval/" + user._id);
        }, 100)
    };

    return (
        <>
            <div>
                <Elements stripe={stripe}>
                    <Routes>
                        {token && (
                            <>
                                {isAdministrativeAccount ?
                                    <>
                                        <Route
                                            exact
                                            path="/admin/dashboard"
                                            element={
                                                <div>
                                                    <Header/>
                                                    <AdminDashboard/>
                                                </div>
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/actions"
                                            element={
                                                <div>
                                                    <Header/>
                                                    <ActionRequired/>
                                                </div>
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/admin-all-cases"
                                            element={
                                                <div>
                                                    <Header/>
                                                    <AdminAllCases/>
                                                </div>
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/admin-new-cases"
                                            element={
                                                <div>
                                                    <Header/>
                                                    <AdminNewCases/>
                                                </div>
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/manage-new-cases"
                                            element={
                                                <div>
                                                    <Header/>
                                                    <ManageNewCases/>
                                                </div>
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/lost-inbound"
                                            element={
                                                <div>
                                                    <Header/>
                                                    <LostInbound/>
                                                </div>
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/cron-reports"
                                            element={
                                                <div>
                                                    <Header/>
                                                    <CronReports/>
                                                </div>
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/users"
                                            element={
                                                <div>
                                                    <Header/>
                                                    <Users/>
                                                </div>
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/reimbursements"
                                            element={
                                                <div>
                                                    <Header/>
                                                    <ReimbursementsRecheck/>
                                                </div>
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/stores"
                                            element={
                                                <div>
                                                    <Header/>
                                                    <StoreManagement/>
                                                </div>
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/leads"
                                            element={
                                                <div>
                                                    <Header/>
                                                    <Leads/>
                                                </div>
                                            }
                                        />
                                    </>
                                    :
                                    (user.role === 'Seller' ? <><Route
                                        exact
                                        path="/seller/users"
                                        element={
                                            <div>
                                                <Header/>
                                                <UsersSeller/>
                                            </div>
                                        }
                                    /><Route
                                        path="/seller/new-cases"
                                        element={
                                            <div>
                                                <Header/>
                                                <AdminNewCases/>
                                            </div>
                                        }
                                    /></> : <></>)}
                                <Route
                                    exact
                                    path="/dashboard"
                                    element={
                                        <div>
                                            <Header/>
                                            <Dashboard/>
                                        </div>
                                    }
                                />
                                <Route
                                    exact
                                    path="/payment-setting"
                                    element={
                                        <div>
                                            <Header/>
                                            <PaymentSetting/>
                                        </div>
                                    }
                                />
                                <Route
                                    exact
                                    path="/auth/store-connection"
                                    element={
                                        <div>
                                            <Header/>
                                            <StoreConnection/>
                                        </div>
                                    }
                                />
                                <Route
                                    exact
                                    path="/case-overview"
                                    element={
                                        <div>
                                            <Header/>
                                            <CaseOverview/>
                                        </div>
                                    }
                                />
                                <Route
                                    exact
                                    path="/stores"
                                    element={
                                        <div>
                                            <Header/>
                                            <Stores/>
                                        </div>
                                    }
                                />
                                <Route
                                    exact
                                    path="/all-cases"
                                    element={
                                        <div>
                                            <Header/>
                                            <AllCases/>
                                        </div>
                                    }
                                />
                                {isNewCasesVisible ?
                                    <Route
                                        exact
                                        path="/new-cases"
                                        element={
                                            <div>
                                                <Header/>
                                                <NewCases/>
                                            </div>
                                        }
                                    /> : <></>}
                                <Route
                                    exact
                                    path="/invoices"
                                    element={
                                        <div>
                                            <Header/>
                                            <Invoices/>
                                        </div>
                                    }
                                />
                            </>
                        )}
                        <Route exact path="/" element={<Login/>}/>
                        <Route exact path="/signin" element={<Login/>}/>
                        <Route exact path="/signup" element={<SignUp/>}/>
                        {/*<Route exact path="/subscribe" element={<Subscribe/>}/>*/}
                        <Route exact path="/reset-password" element={<ResetPassword/>}/>
                        <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>
                        <Route
                            exact
                            path="/termini-e-condizioni"
                            element={<TermsAndConditions/>}
                        />
                        <Route
                            exact
                            path="/terms-approval/:token"
                            element={<TermsApproval/>}
                        />
                    </Routes>
                </Elements>
                {!isAdministrativeAccount && user?.role !== 'Seller' ?
                    <div id="whatsapp"
                         className="qlwapp-free qlwapp-bubble qlwapp-bottom-right qlwapp-all qlwapp-rounded qlwapp-js-ready desktop">
                        <a className="qlwapp-toggle" data-action="open" data-phone="3716973339"
                           data-message="Ciao! Vorrei provare gratuitamente il software 😄"
                           href="https://web.whatsapp.com/send?phone=3716973339&amp;text=Ciao! Vorrei provare gratuitamente il software 😄"
                           target="_blank" rel="noreferrer">
                            <i className="qlwapp-icon qlwapp-whatsapp-icon"/>
                            <i className="qlwapp-close" data-action="close"/>
                        </a>
                    </div> : <></>}
            </div>

            {/*<AccountInactive modalPaymentOpen={isModalPaymentOpen} paymentSettingPage={isPaymentSettingPage}*/}
            {/*                 onOk={handleSettings}/>*/}

            <AccountBlocked open={isModalBlockedOpen} onClick={() => {
                dispatch(LogOut());
                navigate('/');
            }} onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#ffc67c';
            }} onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'transparent';
            }}/>

            <TermsConditions user={user} latestTecApproved={isLatestTecApproved} onOk={handleTec}/>

            <CronReport loaded={loaded} user={user} lastCronStatus={lastCronStatus}
                        onOk={() => setLastCronStatus(true)}/>

            <PopupModal
                url="https://calendly.com/supportofba/45min"
                open={open}
                rootElement={document.getElementById("root")}
                onModalClose={console.log}/>
            {/*<BrevoMeeting open={open} onOk={() => setOpen(false)}/>*/}

            <AmazonAccount user={user} open={isModalOpen} onOk={handleConnect} value={region}
                           onChange={(value) => setRegion(value)} filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} onClick={() => {
                dispatch(LogOut());
                navigate('/');
            }} onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#ffc67c';
            }} onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'transparent';
            }}/>

            <ToastContainer/>
        </>
    );
}

export default App;
