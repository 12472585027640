import React, {useEffect, useState} from "react";
import axios from "axios";
import {Button, Col, Collapse, Divider, Input, InputNumber, Row, Tooltip} from "antd";
import {toast} from "react-toastify";
import {ClaimCase, CreateCase} from "../../redux/slices/adjustment-slice";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {Badge} from "antd";
import CopyTooltip from "../../Components/CopyTooltip.jsx";

const ShowSingleCase = ({fullCase, type}) => {
    const [fc, setFc] = useState({cases: []})
    const dispatch = useDispatch();
    const [caseId, setCaseId] = useState(undefined)
    const [additionalCaseId, setAdditionalCaseId] = useState(undefined)
    const [reimbursementId, setReimbursementId] = useState(undefined)
    const [rejected, setRejected] = useState(false)
    const [fnsku, setfnsku] = useState("")
    const [order, setOrder] = useState("")
    const [sku, setsku] = useState("")
    const [docIds, setdocIds] = useState([])
    const [trid, settrid] = useState("")
    const [trids, settridss] = useState("")
    const [tridsToCopy, settridssToCopy] = useState("")
    const [caseValue, setCaseValue] = useState(0)
    const {user} = useSelector((state) => state.auth);

    useEffect(() => {
        setFc(fullCase)
        setfnsku(fullCase._id)
        setsku(fullCase?.cases[0].sellerSku)
        setOrder(fullCase.cases.map(x => x.orderId))
        setdocIds(fullCase.cases.map(x => x._id))
        settrid(fullCase.cases.map(x => x.referenceId))
        settridssToCopy((fullCase.cases.map(x => x.referenceId)))
        settridss((fullCase.cases.map(x => '\n' + x.referenceId + ' - ' + x.date)))
        setCaseValue((fullCase.cases.reduce((acc, item) => acc + (item?.estimatedAmount?.value ?? 0), 0)))
    }, [fullCase])

    const registerCases = (data) => {
        axios.post('/cases/register-cases', data)
            .then(res => {
            }).catch(err => {
            toast.error(err.message);
        });
    }

    const submitCase = (rejected) => {
        registerCases({caseId, reimbursementId, additionalCaseId, rejected, user: user.email})
        if (caseId || reimbursementId || rejected) {
            dispatch(
                ClaimCase({
                    type: fc.cases[0]?.type,
                    documentId: fc.cases[0]?._id,
                    documentIds: docIds,
                    caseId: caseId,
                    reimbursementIds: reimbursementId ? [Number(reimbursementId)] : [],
                    rejectedCase: rejected,
                    rejectedCaseReason: ""
                })
            ).then((d) => {
                if (d.type === "adjustment/claim-case/rejected") {
                    toast.error(d.payload.errorMessage ?? "Something went wrong. Please try again!");
                } else if (d.type === "adjustment/claim-case/fulfilled") {
                    toast.success("New case submitted successfully.");
                }
            });
        }
        if (additionalCaseId) {
            dispatch(
                CreateCase({
                    amazonCaseIds: [additionalCaseId],
                    userId: null,
                    storeId: fc.cases[0].storeId
                })
            ).then((d) => {
                if (d.type === "adjustment/create-case/rejected") {
                    toast.error(d.payload.errorMessage ?? "Something went wrong. Please try again!");
                } else if (d.type === "adjustment/create-case/fulfilled") {
                    for (const cas of d.payload.response) {
                        if (cas.processed) toast.success("New case " + cas.amazonCaseId + " added successfully.");
                        else toast.error(cas.message);
                    }
                }
            });
        }
    };

    let txt;
    switch (type) {
        case 'LOST_WAREHOUSE' :
            txt = "Si prega di indagare il seguente FNSKU " + fnsku + " nel marketplace " + fc?.cases[0]?.country + " perso dalla Logistica di Amazon e mai riconciliato o rimborsato negli ultimi 18 mesi secondo le politiche Amazon, con questi TransactionID e date: \n"
                + trids + ".\n" +
                "\nAttendiamo il rimborso delle unità contrassegnate come perse, smarrite o diversamente non più presenti a inventario."
            break
        case 'LOST_INBOUND' :
            txt = "Si prega di indagare la seguente spedizione " + fullCase?.cases[0].shipmentId + " dove risultano " + fullCase?.cases[0]?.totalClaimableUnits + " articoli persi"
                + "\nAttendiamo il rimborso delle unità."
            break
        case 'DAMAGED_WAREHOUSE' :
            txt = "L'FNSKU " + fnsku + " negli eventi " + trids + " è stato identificato come danneggiato nel marketplace " + fc?.cases[0]?.country + ".\n" +
                "Ti invitiamo a indagare\n"
            break
        case 'DAMAGED_INBOUND' :
            txt = "L'FNSKU " + fnsku + " è stato identificato come danneggiato nel marketplace " + fc?.cases[0]?.country + ".\n" +
                "Id Spedizioni: " + fc.cases.map(x => x.referenceId) + "\n" +
                "Ti invitiamo a indagare\n"
            break
        case 'DAMAGED_OUTBOUND' :
            txt = "L'FNSKU " + fnsku + " negli eventi " + trids + " è stato identificato come danneggiato nel marketplace " + fc?.cases[0]?.country + ".\n" +
                "Ti invitiamo a indagare\n"
            break
        case 'DISPOSED' :
            txt = "L'FNSKU " + fnsku + " negli eventi " + trids + " è stato identificato come distrutto senza possibilità di rimozione nel marketplace " + fc?.cases[0]?.country + ".\n" +
                "Ti invitiamo a indagare\n"
            break
        case 'CUSTOMER_RETURN' :
            txt = fc.cases.map(x => x.caseText)
            break
        default:
            txt = '';
    }
    return <>
        <Row>
            <Divider/>
            <Col md={12}>
                <Row>
                    <strong>DATE: </strong> {fc.cases[0]?.date}
                </Row>
                <Row>
                    <Col md={7} style={{overflow: 'auto'}}>
                        <strong>REFERENCE IDs</strong>:
                        <CopyTooltip
                            text={type === 'LOST_WAREHOUSE'
                                ? fnsku
                                : (type === 'CUSTOMER_RETURN'
                                    ? order
                                    : (trid.length === 1 ? trid : trid[0] + ' + ' + (trid.length - 1) + ' other items'))}
                            textToCopy={type === 'LOST_WAREHOUSE' ? fnsku : (type === 'CUSTOMER_RETURN'
                                ? order : tridsToCopy)}/>
                    </Col>
                    <Divider type="vertical"/>
                    <Col md={7} style={{overflow: 'auto'}}>
                        <strong>SKU</strong>: <CopyTooltip text={sku} textToCopy={sku}/>
                        <strong>Available
                            SKUs</strong>: {fullCase.cases?.flatMap(x => x.relatedInventory?.map(y =>
                        <p>{y}</p>))}
                    </Col>
                    <Divider type="vertical"/>
                    <Col md={2} style={{overflow: 'auto'}}>
                        <strong>Country</strong>: {fc.cases[0]?.country}
                    </Col>
                    <Divider type="vertical"/>
                    <Col md={2} style={{overflow: 'auto'}}>
                        <strong>Value</strong>: {caseValue} €
                    </Col>
                    <Col md={2} style={{overflow: 'auto'}}>
                        {fc.cases[0]?.isSuppressed ?
                            <p style={{color: 'red'}}><strong>Not in inventory!</strong></p> : ""}
                    </Col>
                </Row>
            </Col>
            <Col md={2}>
                <Button onClick={() =>
                    navigator.clipboard.writeText(txt)
                }>Copy Text</Button>
            </Col>
            <Col md={2}>
                <Input
                    placeholder="Enter Amazon Case Id"
                    size="small"
                    value={caseId}
                    onChange={(e) => setCaseId(e.target.value)}
                />
            </Col>
            <Divider type="vertical"/>
            <Col md={2}>
                <Input
                    placeholder="Enter Amazon Reimbursement ID"
                    size="small"
                    value={reimbursementId}
                    onChange={(e) => setReimbursementId(e.target.value)}
                />
            </Col>
            <Divider type="vertical"/>
            <Col md={2}>
                <Input
                    placeholder="Enter Additional Amazon Case Id"
                    size="small"
                    value={additionalCaseId}
                    onChange={(e) => setAdditionalCaseId(e.target.value)}
                />
            </Col>
            <Col md={1}>
                <Button
                    type="primary"
                    style={{
                        marginLeft: "15px",
                    }}
                    onClick={() => submitCase(false)}
                >
                    Submit
                </Button>
            </Col>
            <Col md={1}>
                <Button onClick={() => {
                    setRejected(true)
                    submitCase(true)
                }}
                        danger
                        type="primary"
                        style={{
                            marginLeft: "15px",
                        }}>
                    Rejected
                </Button>
            </Col>
        </Row>
    </>
}

const SingleCaseView = ({
                            fullCase, k, type
                        }) => {
    const [fc, setFc] = useState(fullCase)
    const [t, sett] = useState(k)
    const [divided, setDivided] = useState([])
    useEffect(() => {
        setFc(fullCase);
        sett(k)
        const divided = splitArrayIntoParts(fullCase.cases,
            fullCase.cases[0]?.type === 'LOST_INBOUND'
            || fullCase.cases[0]?.type === 'CUSTOMER_RETURN'
            || fullCase.cases[0]?.type === 'DAMAGED_INBOUND'
                ? 1
                : k)
        setDivided(divided)
    }, [fullCase, k])

    function splitArrayIntoParts(arr, k) {
        if (!Array.isArray(arr) || !arr.length || k <= 0) {
            return [[]]; // Return an array with an empty array if the input is invalid
        }

        const result = [];
        for (let i = 0; i < arr.length; i += k) {
            result.push(arr.slice(i, i + k));
        }
        return result;
    }

    return <>
        {divided.filter(x => x.length > 0).map(x => {
            const fc = fullCase
            fc.cases = x
            console.log(x)
            console.log(fc.cases)
            return <><strong>{type}</strong><ShowSingleCase type={type} fullCase={fc}/><Divider/></>;
        })}
    </>;
}

const StoreView = ({id, t}) => {
    const [newCases, setNewCases] = useState([])
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('')
    const [k, setK] = useState(type === 'LOST_WAREHOUSE' ? 10 : 5)
    const [store, setStore] = useState({})

    const changeType = (type) => {
        setType(type)
        setK(type === 'LOST_WAREHOUSE' ? 10 : 5)
    }
    const fetchNewCases = () => {
        let url = `${process.env.REACT_APP_API_BASE_URL}cases/new-cases-aggregated/store/${id}/${type}`
        axios.get(url)
            .then(response => {
                setNewCases(response.data.response);
                setLoading(false)
            })
            .catch(error => {
                console.error('Error fetching reimbursements:', error);
            });
    };
    const fetchStore = () => {
        let url = `${process.env.REACT_APP_API_BASE_URL}store/${id}`
        axios.get(url)
            .then(response => {
                setStore(response.data)
            })
            .catch(error => {
                console.error('Error fetching reimbursements:', error);
            });
    };

    useEffect(() => {
        setLoading(true)
        fetchNewCases()
        fetchStore()
    }, [type])

    function LabelSelector() {
        return <Row>
            <Divider type={'vertical'}/>
            {store?.servicesConfig?.lost.warehouse.enabled && <Col md={3}>
                <Button type={type === 'LOST_WAREHOUSE' ? 'primary' : ""}
                        onClick={() => changeType('LOST_WAREHOUSE')}>LOST_WAREHOUSE
                </Button>
            </Col>}
            {/*{store?.servicesConfig?.lost.inbound.enabled && <Col md={3}>*/}
            {/*    <Button type={type === 'LOST_INBOUND' ? 'primary' : ""}*/}
            {/*            onClick={() => changeType('LOST_INBOUND')}>LOST_INBOUND*/}
            {/*    </Button>*/}
            {/*</Col>}*/}
            <Divider type={'vertical'}/>
            {store?.servicesConfig?.damaged.warehouse.enabled && <Col md={5}>
                <Button type={type === 'DAMAGED_WAREHOUSE' ? 'primary' : ""}
                        onClick={() => changeType('DAMAGED_WAREHOUSE')}>DAMAGED_WAREHOUSE
                </Button>
            </Col>}
            <Divider type={'vertical'}/>
            {store?.servicesConfig?.damaged.inbound.enabled && <Col md={5}>
                <Button type={type === 'DAMAGED_INBOUND' ? 'primary' : ""}
                        onClick={() => changeType('DAMAGED_INBOUND')}>DAMAGED_INBOUND
                </Button>
            </Col>}
            <Divider type={'vertical'}/>
            {store?.servicesConfig?.damaged.outbound.enabled && <Col md={5}>
                <Button type={type === 'DAMAGED_OUTBOUND' ? 'primary' : ""}
                        onClick={() => changeType('DAMAGED_OUTBOUND')}>DAMAGED_OUTBOUND
                </Button>
            </Col>}
            {store?.servicesConfig?.disposed?.enabled && <Col md={5}>
                <Button type={type === 'DISPOSED' ? 'primary' : ""}
                        onClick={() => changeType('DISPOSED')}>DISPOSED
                </Button>
            </Col>}
            {store?.servicesConfig?.customer_return?.enabled && <Col md={5}>
                <Button type={type === 'CUSTOMER_RETURN' ? 'primary' : ""}
                        onClick={() => changeType('CUSTOMER_RETURN')}>CUSTOMER RETURN
                </Button>
            </Col>}
            <Divider type={'vertical'}/>
            <Col md={2}>
                <h6>Group By</h6>
                <InputNumber
                    max={type === 'LOST_WAREHOUSE' ? 10 : 5}
                    placeholder="Enter Group By"
                    type={'number'}
                    value={k}
                    onChange={(e) => {
                        setK(e)
                    }}
                />
            </Col>
        </Row>;
    }

    return <>
        <LabelSelector/>
        {loading && (
            <div className={'box content'}><Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner></div>
        )}
        {
            !loading && newCases.sort((a, b) => {
                return a._id.localeCompare(b._id)
            }).map((x, index) => <SingleCaseView key={index} fullCase={x} k={t} type={type}/>)
        }
        {!loading && newCases.length === 0 && 'No cases'}
    </>

}

const LabelSelector = ({x}) => {
    const [store, setStore] = useState({})

    const fetchStore = () => {
        let url = `${process.env.REACT_APP_API_BASE_URL}store/${x._id}`
        axios.get(url)
            .then(response => {
                setStore(response.data)
            })
            .catch(error => {
                // Handle errors here
                console.error('Error fetching reimbursements:', error);
            });
    };
    useEffect(() => {
        fetchStore()
    }, []);
    return <Row>
        <Col md={2} style={{overflow: "scroll"}}>
            <p><strong>{store.name ?? x.email}</strong></p>
        </Col>
        <Divider type={'vertical'}/>
        {store?.servicesConfig?.lost.warehouse.enabled && <Col md={3}>
            LOST_WAREHOUSE
            <Badge size={"default"} color={'red'} overflowCount={1000000}
                   count={x.types?.filter(x => x.type === 'LOST_WAREHOUSE')[0] ? x.types.filter(x => x.type === 'LOST_WAREHOUSE')[0]?.count : 0}/>
        </Col>}
        <Divider type={'vertical'}/>
        {/*{store?.servicesConfig?.lost.inbound.enabled && <Col md={3}>*/}
        {/*    LOST_INBOUND*/}
        {/*    <Badge size={"default"} color={'red'} overflowCount={1000}*/}
        {/*           count={x.types?.filter(x => x.type === 'LOST_INBOUND')[0] ? x.types.filter(x => x.type === 'LOST_INBOUND')[0]?.count : 0}/>*/}
        {/*</Col>}*/}
        {store?.servicesConfig?.damaged.warehouse.enabled && <Col md={3}>
            DAMAGED_WAREHOUSE
            <Badge size={"default"} color={'red'} overflowCount={1000000}
                   count={x.types?.filter(x => x.type === 'DAMAGED_WAREHOUSE')[0] ? x.types.filter(x => x.type === 'DAMAGED_WAREHOUSE')[0]?.count : 0}/>
        </Col>}
        <Divider type={'vertical'}/>
        {store?.servicesConfig?.damaged.inbound.enabled && <Col md={3}>
            DAMAGED_INBOUND
            <Badge size={"default"} color={'red'} overflowCount={1000000}
                   count={x.types?.filter(x => x.type === 'DAMAGED_INBOUND')[0] ? x.types.filter(x => x.type === 'DAMAGED_INBOUND')[0]?.count : 0}/>
        </Col>}
        <Divider type={'vertical'}/>
        {store?.servicesConfig?.damaged.outbound.enabled && <Col md={3}>
            DAMAGED_OUTBOUND
            <Badge size={"default"} color={'red'} overflowCount={1000000}
                   count={x.types?.filter(x => x.type === 'DAMAGED_OUTBOUND')[0] ? x.types.filter(x => x.type === 'DAMAGED_OUTBOUND')[0]?.count : 0}/>
        </Col>}
        {store?.servicesConfig?.disposed?.enabled && <Col md={3}>
            DISPOSED
            <Badge size={"default"} color={'red'} overflowCount={1000000}
                   count={x.types?.filter(x => x.type === 'DISPOSED')[0] ? x.types.filter(x => x.type === 'DISPOSED')[0]?.count : 0}/>
        </Col>}
        {store?.servicesConfig?.customer_return?.enabled && <Col md={3}>
            CUSTOMER RETURN
            <Badge size={"default"} color={'red'} overflowCount={1000000}
                   count={x.types?.filter(x => x.type === 'CUSTOMER_RETURN')[0] ? x.types.filter(x => x.type === 'CUSTOMER_RETURN')[0]?.count : 0}/>
        </Col>}
        <Divider type={'vertical'}/>
    </Row>;
}

const ManageNewCases = () => {
    const [stores, setStores] = useState([])
    const [k, setK] = useState(10)
    const [stats, setStats] = useState([])
    const {user} = useSelector((state) => state.auth);

    const fetchStores = () => {
        let url = `${process.env.REACT_APP_API_BASE_URL}cases/new-cases-aggregated/${user.managingAccounts ?? 'All'}`
        axios.get(url)
            .then(response => {
                setStores(response.data.response.aggregatedCases);
                setStats(response.data.response.numberOfCases)
            })
            .catch(error => {
                // Handle errors here
                console.error('Error fetching reimbursements:', error);
            });
    };

    useEffect(() => {
        fetchStores()
    }, []);

    return (
        <>
            <h3>Manage cases</h3>
            <Divider/>
            <Collapse items={stores.filter(x => {
                return x._id !== null && x._id !== "" && x._id !== undefined
            }).sort((a, b) => { //Managing account
                return JSON.stringify(a._id).localeCompare(JSON.stringify(b._id))
            }).map((x, index) => ({
                key: x._id,
                label: <><Row>
                    <p>{x._id ?? 'None'}</p>
                    {stats.length && stats
                        .filter(y => {
                            return y._id.managingAccount === x._id
                        })
                        .sort((a, b) => b._id.type.localeCompare(a._id.type))
                        .map(y => {
                            return <>
                                <Divider type={'vertical'}/>
                                <Divider type={'vertical'}/>
                                <div>
                                    <Row>
                                        <p>{y._id.type}</p>
                                    </Row>
                                    <Row>
                                        <Badge size={"default"} color={'blue'} overflowCount={1000000} showZero={true}
                                               count={y.total ?? 0}/>
                                        <Divider type={'vertical'}/>
                                        <Badge size={"default"} color={'green'} overflowCount={1000000} showZero={true}
                                               count={y.oneDay ?? 0}/>
                                        <Divider type={'vertical' ?? 0}/>
                                        <Badge size={"default"} color={'yellow'} overflowCount={1000000} showZero={true}
                                               count={y.twoDays ?? 0}/>
                                        <Divider type={'vertical'}/>
                                        <Badge size={"default"} color={'red'} overflowCount={1000000} showZero={true}
                                               count={y.aboveThreeDays ?? 0}/>
                                    </Row>
                                </div>
                            </>
                        })}
                </Row>
                    <Divider type={'vertical'}/></>,
                children:
                    <Collapse items={x.stores.sort((a, b) => {
                        return a.name?.localeCompare(b.name)
                    }).map((x, index) => ({
                        key: x._id,
                        label: <LabelSelector key={index} x={x}/>,
                        children:
                            <StoreView key={index} id={x._id} t={k} types={x.types}/>
                    }))} defaultActiveKey={[]}/>
            }))} defaultActiveKey={[]}/>
        </>
    );
}

export default ManageNewCases;
