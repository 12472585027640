import React from "react";
import { CardElement  } from "@stripe/react-stripe-js";
import "./PaymentSetting.css";

export default function CheckoutForm() {
  return (
    <div style={{ width: "134%" }}>
      <form
        style={{
          display: "block",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardElement
            className="card"
            options={{
                hidePostalCode:true,
              style: {
                base: {
                  backgroundColor: "white",
                },
                invalid: {
                  color: '#fa755a',
                  iconColor: '#fa755a'
                }
              },
            }}
          />
        </div>
      </form>
    </div>
  );
}
