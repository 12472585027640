import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosBaseUrl } from "../../config/axios-configuration";

const axios = axiosBaseUrl();

export const GetStores = createAsyncThunk(
  "store/get-stores",
  async (data, { rejectWithValue }) => {
    try {
      let filter = {};
      if (data.searchKeyword) {
        filter = {
          keyword: {
            value: data.searchKeyword,
          },
        };
      }
      const response = await axios.get("/store/get-stores", {
        params: {
          skip: data.skip || 0,
          sort: data.sort,
          limit: data.limit,
          filters: filter,
        },
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error || err.response.data.errorMessage,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const GetAllStores = createAsyncThunk(
  "store/get-all-stores",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get("/store/get-all-stores");
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data.error || err.response.data.errorMessage,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const GetAllStoresForUser = createAsyncThunk(
    "store/get-all-stores-users",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.get("/store/get-all-stores?userId="+data.data);
        return response.data;
      } catch (err) {
        if (err.response && err.response.data) {
          return rejectWithValue({
            err: err.response.data.error || err.response.data.errorMessage,
            status: err.response.status,
          });
        }
        return rejectWithValue({
          err: "Network Error",
        });
      }
    }
);

export const CreateStore = createAsyncThunk(
  "store/create-store",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post("/store/create-store", data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        console.log(err.response.data)
        return rejectWithValue({
          err: err.response.data.message || err.response.data.errorMessage || err.response.data.err,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const DeleteStore = createAsyncThunk(
    "store/delete-store",
    async (data, { rejectWithValue }) => {
      try {
        const response = await axios.post("/store/delete-store", data);
        return response.data;
      } catch (err) {
        if (err.response && err.response.data) {
          return rejectWithValue({
            err: err.response.data.message || err.response.data.errorMessage,
            status: err.response.status,
          });
        }
        return rejectWithValue({
          err: "Network Error",
        });
      }
    }
);

const store = createSlice({
  name: "stores",
  initialState: {
    success: false,
    loading: false,
    total: null,
    storesData: [],
    allStores: [],
    err: "",
    state: "",
    region: "",
    sellerId: "",
    storeId: "",
    amazonState: "",
    amazonUrl: "",
    message: "",
    storeSuccess: false,
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    amazonStoreInfo(state, action) {
      return {
        ...state,
        state: action.payload.state,
        region: action.payload.region,
      };
    },
    clearState(state, action) {
      return {
        ...state,
        storeNames: [],
      };
    },
  },
  extraReducers: {
    [GetStores.pending]: (state, action) => ({
      ...state,
      getStoreFlag: false,
      loading: true,
    }),
    [GetStores.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      total: action.payload.total,
      getStoreFlag: true,
      storesData: action.payload.stores,
    }),
    [GetStores.rejected]: (state, action) => ({
      ...state,
      total: 0,
      storesData: [],
      loading: false,
      getStoreFlag: false,
      err: action.payload.err,
    }),
    [GetAllStores.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      allStores: action.payload.allStores,
    }),

    [GetAllStoresForUser.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      allStores: action.payload.allStores,
    }),

    [CreateStore.fulfilled]: (state, action) => ({
      ...state,
      storeId: action.payload.data,
      loading: false,
      storeSuccess: true,
    }),
    [CreateStore.rejected]: (state, action) => ({
      ...state,
      loading: false,
      storeId: "",
      storeSuccess: false,
      err: action.payload.err,
    }),
    [CreateStore.pending]: (state, action) => ({
      ...state,
      storeSuccess: false,
      storeId: "",
      loading: true,
    }),
    [DeleteStore.fulfilled]: (state, action) => ({
      ...state,
      storeId: "",
      loading: false,
      storeSuccess: true,
    }),
    [DeleteStore.rejected]: (state, action) => ({
      ...state,
      loading: false,
      storeId: "",
      storeSuccess: false,
      err: action.payload.err,
    }),
    [DeleteStore.pending]: (state, action) => ({
      ...state,
      storeSuccess: false,
      storeId: "",
      loading: true,
    }),
  },
});

const { reducer, actions } = store;

export const { SetState, amazonStoreInfo, setMarketplaceEdit, clearState } =
  actions;

export default reducer;
