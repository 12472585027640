import React, { useState } from "react";
import SortIcon from "./SortIcon";
import { Input } from "antd";
import "./TableTh.css";
import { getSearchTerm, getIsClearFilter } from "../../utils/utils";
import { RiFilterOffLine } from "react-icons/ri";
import DatePicker from "./DatePicker";
import SelectInput from "./SelectInput";
import DefaultInput from "./DefaultInput";
import DatePickerRange from "./DatePickerRange";

function TableTh({ col, setActiveOptions, activeOptions }) {
  const [searchClick, setSearchClick] = useState(false);

  return (
      <th className="headerCell" >
      <div className="tableThLabel" style={{ width: col.width }}>
        {!col.disabled ? (
          col.labelValue ? (
            col.isButton ? col.labelValue : col.inputValue === "date" ? (
              <DatePickerRange
                col={col}
                setActiveOptions={setActiveOptions}
                activeOptions={activeOptions}
                searchClick={searchClick}
                setSearchClick={setSearchClick}
              />
            ) : col.inputValue === "select" ? (
              <SelectInput
                col={col}
                setActiveOptions={setActiveOptions}
                activeOptions={activeOptions}
                searchClick={searchClick}
                setSearchClick={setSearchClick}
              />
            ) : (
              <DefaultInput
                col={col}
                setActiveOptions={setActiveOptions}
                activeOptions={activeOptions}
                searchClick={searchClick}
                setSearchClick={setSearchClick}
              />
            )
          ) : getIsClearFilter(activeOptions) ? (
            <div
              className={col.className}
              style={{ width: col.width }}
              onClick={() => setActiveOptions({})}
            >
              <RiFilterOffLine style={{ cursor: "pointer" }} />
            </div>
          ) : null
        ) : (
          <div className={col.className} style={{ width: col.width }}>
            {col.labelValue}
          </div>
        )}
      </div>
    </th>
  );
}

export default TableTh;
