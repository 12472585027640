import React, {useState, useEffect, Fragment} from "react";
import "./Stores.css";
import {Button, Table, Space, Modal, Select} from "antd";
import {nanoid} from "nanoid";
import {useDispatch, useSelector} from "react-redux";

import {amazonStoreInfo, CreateStore, DeleteStore, GetStores} from "../../redux/slices/store-slice";
import {toast} from "react-toastify";
import {PopupModal, useCalendlyEventListener} from "react-calendly";
import {GetUser} from "../../redux/slices/auth-slice";
import {axiosBaseUrl} from "../../config/axios-configuration";
import {useNavigate} from "react-router-dom";

const RegionsList = {
    eu: "Europe",
    na: "North America",
    fe: "Far East",
};

function Stores() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Region",
            dataIndex: "region",
            key: "region",
            render: (_, record) => {
                return RegionsList[record?.region];
            },
        },
        {
            title: "Merchant / Seller ID",
            dataIndex: "sellerId",
            key: "sellerId",
        },
        {
            title: "Connection Status",
            dataIndex: "status",
            key: "connectionStatus",
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => showDeleteModal(record?.sellerId)}>Delete</a>
                </Space>
            ),
        },
    ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteSellerId, setDeleteSellerId] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [region, setRegion] = useState("na");
    const {success, storesData} = useSelector((state) => state.stores);
    const [open, setOpen] = useState(false);
    const {user} = useSelector((state) => state.auth);
    const axios = axiosBaseUrl();

    useEffect(() => {
        dispatch(GetStores({}));
        dispatch(GetUser());
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onChange = (value) => {
        setRegion(value);
    };
    const onSearch = (value) => {
    };

    const handleConnect = () => {
        const state = nanoid(64);
        const payload = {
            state,
            region,
        };
        dispatch(amazonStoreInfo(payload));

        let url;
        if (region === "eu") {
            url = `https://sellercentral.amazon.it/apps/authorize/consent?application_id=amzn1.sp.solution.d3f1dc25-6c1f-40ed-a5e2-e76ed049a085&state=${state}&version=beta&redirect_uri=https://app.sosfba.it/auth/store-connection`;
        } else if (region === "na") {
            url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.d3f1dc25-6c1f-40ed-a5e2-e76ed049a085&state=${state}&version=beta&redirect_uri=https://app.sosfba.it/auth/store-connection`;
        } else {
            url = `https://sellercentral-japan.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.d3f1dc25-6c1f-40ed-a5e2-e76ed049a085&state=${state}&version=beta&redirect_uri=https://app.sosfba.it/auth/store-connection`;
        }
        window.open(url, "_self");
    };

    const showDeleteModal = (sellerId) => {
        setDeleteSellerId(sellerId);
        setIsDeleteModalOpen(true);
    };

    const handleDelete = () => {
        dispatch(
            DeleteStore({
                sellerId: deleteSellerId,
            })
        ).then((d) => {
            if (d.type === "store/delete-store/rejected") {
                toast.error("Something went wrong while deleting account. Please try again!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else if (d.type === "store/delete-store/fulfilled") {
                toast.success("Account deleted successfully.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        });
        setDeleteSellerId(null)
        setIsDeleteModalOpen(false);
        dispatch(GetStores({}));

        setTimeout(() => {
            window.location.reload();
        }, 1500)
    }

    const handleDeleteCancel = () => {
        setDeleteSellerId(null);
        setIsDeleteModalOpen(false);
    }

    useEffect(() => {
        if (success && storesData.length) {
            setDataSource(storesData);
        } else {
            setDataSource([]);
        }
    }, [success, storesData]);

    const createQueryString = (data) => {
        const params = new URLSearchParams();

        // Loop through the key-value pairs and append them to the URLSearchParams
        for (const [key, value] of Object.entries(data)) {
            params.append(key, value);
        }

        return params.toString();
    };
    return (
        <div className="store_container">
            <div className="add_store_btn">
                <Button type="primary" ghost
                        onClick={() => window.open('https://sellercentral.amazon.it/gp/account-manager/home.html/ref=xx_userperms_dnav_xx')}
                        className="mt-2">
                    User Permissions
                </Button><Fragment>&nbsp;</Fragment>
                <Button type="primary" ghost onClick={showModal} className="mt-2">
                    Add Amazon Account
                </Button>
            </div>
            <Table className="mt-4" dataSource={dataSource} columns={columns}/>
            <Modal
                title="Add Amazon Account"
                open={isModalOpen}
                onOk={handleConnect}
                //For testing purposes
                // onOk={() => {
                //   console.log('navigation')
                //   navigate('/auth/store-connection?' + createQueryString({selling_partner_id:"AD8IZCNNTPGND" ,region: 'eu', state:true, spapi_oauth_code: ""}));
                // }}
                okText="Connect"
                onCancel={handleCancel}
            >
                <label className="mt-3">Region </label>
                <Select
                    showSearch
                    placeholder="Select Region"
                    optionFilterProp="children"
                    value={region}
                    defaultValue={{
                        value: "eu",
                        label: "Europe",
                    }}
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    options={[
                        {
                            value: "eu",
                            label: "Europe",
                        },
                        {
                            value: "na",
                            label: "North America",
                        },
                        {
                            value: "fe",
                            label: "Japan",
                        },
                    ]}
                    className="store_select_container"
                />
            </Modal>

            <Modal
                title="Are you sure. You want to delete this account?"
                open={isDeleteModalOpen}
                onOk={handleDelete}
                okText="Confirm"
                onCancel={handleDeleteCancel}
            >
            </Modal>

            {/*<PopupModal*/}
            {/*    url="https://calendly.com/supportofba/45min"*/}
            {/*    open={open}*/}
            {/*    onModalClose={() => closeConsultation()}*/}
            {/*    rootElement={document.getElementById("root")}*/}
            {/*/>*/}
        </div>
    );
}

export default Stores;
