import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getLocalDate} from "../../utils/utils";
import {isEmpty} from "lodash";

function Rows({
                  index,
                  item,
              }) {
    const {user} = useSelector((state) => state.auth);
    const isAdmin = user?.role === "Admin";


    const rows = [
        {
            className: "table_items",
            width: "100px",
            labelValue: item.name,
        },
        {
            className: "table_items",
            width: "200px",
            labelValue: item?.lastRunAt,
        },
        {
            className: "table_items",
            width: "200px",
            labelValue: item?.nextRunAt,
        },
        {
            className: "table_items",
            width: "100px",
            labelValue: item?.state,
        },
        {
            className: "table_items",
            width: "100px",
            labelValue: item?.user[0]?.storeName ?? item?.user[0]?.email,
        }
    ];
    return (
        <tr key={index}>
            {rows.map((row) => {
                return row.isAdminOnly ? (
                    isAdmin && (
                        <td className="responsivePadding">
                            <div className={row.className} style={{width: row.width, overflow: "auto"}}>
                                {row.labelValue || row.toolTip}
                            </div>
                        </td>
                    )
                ) : row.isUserOnly ? (
                    !isAdmin && (
                        <td className="responsivePadding">
                            <div className={row.className} style={{width: row.width, overflow: "auto"}}>
                                {row.labelValue || row.toolTip}
                            </div>
                        </td>
                    )
                ) : (
                    <td className="responsivePadding">
                        <div className={row.className} style={{width: row.width, overflow: "auto"}}>
                            {row.labelValue || row.toolTip}
                        </div>
                    </td>
                );
            })}
        </tr>
    );
}

export default Rows;