import React from "react";
import {DatePicker, Button} from "antd";
import SortIcon from "./SortIcon";
import {getSearchTerm} from "../../utils/utils";
import "./TableTh.css";
import {useState} from "react";
import moment from 'moment';

const DatePickerRange = ({
                             col,
                             setActiveOptions,
                             activeOptions,
                             searchClick,
                             setSearchClick,
                         }) => {
    const [selectedTimestamps, setSelectedTimestamps] = useState(
        activeOptions[col?.backendKey]?.value || []
    );
    const [openState, setOpenState] = useState(false);

    const handleDateChange = (dates) => {
        setSelectedTimestamps(dates ? dates?.map((date) => date.valueOf()) : []);
    };

    const handleClear = () => {
        setSelectedTimestamps([]);
        setSearchClick(false);
        setOpenState(false);
        const updateOptions = {
            ...activeOptions,
            [col.backendKey]: {
                ...activeOptions[col.backendKey],
                value: [],
            },
        };
        setActiveOptions(updateOptions);
    };

    const handleSave = () => {
        if (selectedTimestamps.length === 2) {
            const updateOptions = {
                ...activeOptions,
                [col.backendKey]: {
                    ...activeOptions[col.backendKey],
                    value: selectedTimestamps,
                },
            };
            setOpenState(false);
            setActiveOptions(updateOptions);
            setSearchClick(false);
        }
    };

    return (
        <div>
            <div
                className="tableThInput"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: `${col.width}px`,
                }}
            >
                {col.hasSort && (
                    <SortIcon
                        col={col}
                        setActiveOptions={setActiveOptions}
                        activeOptions={activeOptions}
                        className="sortIconWithDate"
                    />
                )}
                <DatePicker.RangePicker
                    allowClear={false}
                    onChange={handleDateChange}
                    open={openState}
                    value={
                        selectedTimestamps.length === 2
                            ? [
                                moment(selectedTimestamps[0]),
                                moment(selectedTimestamps[1]),
                            ]
                            : []
                    }
                    onBlur={(e) => {
                        e.stopPropagation();
                        e.preventDefault()
                        setOpenState(false);
                    }}
                    bordered={false}
                    showTime={false}
                    placeholder=""
                    onClick={(e) => {
                        e.stopPropagation();
                        setSearchClick(true);
                        setOpenState(true);
                    }}
                    onFocus={(e)=>{
                        setOpenState(true)
                    }}
                    style={{zIndex: 150, width: '100%', marginLeft: '30px'}}
                    renderExtraFooter={() => (
                        <div className="date-picker-footer">
                            <Button
                                className="date-picker-footer-clear-btn"
                                disabled={selectedTimestamps.length === 0}
                                onClick={handleClear}
                            >
                                Clear
                            </Button>
                            <Button
                                type="primary"
                                className="date-picker-footer-save-btn"
                                disabled={selectedTimestamps.length !== 2}
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </div>
                    )}
                />
            </div>
            <div
                className={
                    getSearchTerm(col.backendKey, activeOptions) || searchClick
                        ? 'labelValue'
                        : 'labelValueWithoutTerm'
                }
                // style={{ maxWidth: `${col.width - 25}px` }}
            >
                {col.labelValue}
            </div>
        </div>
    );
};

export default DatePickerRange;
