import {Button, Col, Divider, Modal, Row} from "antd";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Select from "react-select";

export const WhatsappModal = ({user, isModalOpen, onCancel, closeModal}) => {
    const [templates, setTemplates] = useState([])
    const [senders, setSenders] = useState([])
    const [contacts, setContacts] = useState([])
    const [template, setTemplate] = useState("")
    const [sender, setSender] = useState("")
    const [contact, setContact] = useState("")
    const sendMessage = async () => {
        axios.post('/brevo/whatsapp/send-message', {templateId: template, senderNumber: sender, contactNumber: contact})
            .then(r => toast.success(`Message sent: ${JSON.stringify(r.data)}`))
            .catch(r => toast.error('Sorry there was an error, please retry later.'))
    }

    const fetchSenders = async () => {
        axios.get('/brevo/whatsapp/senders', {})
            .then(r => {
                setSenders(r.data)
            })
    }

    const fetchTemplates = async () => {
        axios.get('/brevo/whatsapp/templates', {})
            .then(r => {
                setTemplates(r.data.templates)
            })
    }

    const fetchContacts = async () => {
        axios.get('/brevo/whatsapp/contacts', {})
            .then(r => {
                setContacts(r.data?.filter(x => x.attributes.SMS || x.attributes.WHATSAPP))
            })
    }

    useEffect(() => {
        fetchSenders()
        fetchTemplates()
        fetchContacts()
    }, []);

    return (<>
            <Modal
                key={`Send Whatsapp message`}
                title={'Send Whatsapp message'}
                open={isModalOpen}
                onCancel={onCancel}
                footer={null}
                width="auto"
            >
                <div>
                    <Row>
                        <Col md={2}>
                            <label>Contatto</label>
                        </Col>
                        <Col md={22}>
                            <Select
                                isSearchable={true}
                                value={contacts?.filter(x => user?.contacts.map(y => y.brevo_id).includes(x.id)).filter(x => x.value === contact)[0]}
                                options={contacts?.filter(x => user?.contacts.map(y => y.brevo_id).includes(x.id)).map(x => ({label: x.email, value: x.attributes.SMS || x.attributes.WHATSAPP}))}
                                onChange={(e) => {
                                    setContact(e.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col md={2}>
                            <label>Template</label>
                        </Col>
                        <Col md={22}>
                            <Select
                                style={{width: '100%'}}
                                options={templates?.map(x => ({label: x.name, value: x.id}))}
                                onChange={(e) => {
                                    setTemplate(e.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Divider/>
                    <Row>
                        <Col md={2}>
                            <label>Sender</label>
                        </Col>
                        <Col md={22}>
                            <Select
                                style={{width: '100%'}}
                                options={senders?.map(x => ({label: x.name, value: x.number}))}
                                onChange={(e) => {
                                    setSender(e.value)
                                }}
                            />
                        </Col>
                    </Row>

                    <Divider/>
                    <Row>
                        <Col md={6}>
                            <Button type="primary" onClick={sendMessage}>Submit</Button>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    );
};
