import React, {useState, useMemo, useEffect} from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'

function CountrySelector({setPayment, isDisabled, initValue}) {
    const [value, setValue] = useState(initValue)
    const options = useMemo(() => countryList().getData(), [])

    const changeHandler = value => {
        setValue(value)
        setPayment('country', value.value)
    }
    useEffect(() => {
        setValue(options.filter(x => x.value === (initValue ?? 'IT'))[0])
        if(!initValue) {
            setPayment('country', "IT")
        }
    }, [setPayment, initValue, options]);

    return <Select
        defaultValue={{value: "IT", label: "Italy"}}
        isDisabled={isDisabled}
        options={options}
        value={value}
        onChange={changeHandler}/>
}

export default CountrySelector