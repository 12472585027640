import React from "react";
import { Typography } from "antd";
import "./PrivacyPolicy.css";

const { Title, Text } = Typography;

export const CookiePolicy = () => {
  return (
    <div>
      <Title level={3} className="policy-title mt-60">
        GESTIONE DEI COOKIE
      </Title>
      <br />
      <br />
      <Text>
        Il Titolare del trattamento dei dati personali degli utenti del Sito
        www.sosfba.it è la società Solaris Group S.R.L., P.iva: <strong>IT</strong>13055980018, con sede in Torino (TO), Via Nizza, 262/1, CAP 10126 e-mail:{" "} <a href="mailto:supporto@sosfba.it">supporto@sosfba.it</a>.
      </Text>

      <br />
      <br />
      <Title level={4} className="policy-title mt-20">
        Cookie
      </Title>
      <Text>
        Questo sito web utilizza i cookie. Con il consenso alla Privacy Policy,
        l'Utente acconsente all'uso dei cookie da parte del sito.
      </Text>
      <br />
      <br />
      <Text>
        I cookie sono piccoli file di testo che vengono memorizzati sul
        dispositivo dell’utente quando questo naviga su determinati siti web
        tramite l’utilizzo del proprio browser preferito e vengono memorizzati
        nella directory dei file del browser. Essi sono utilizzati dalle
        applicazioni web lato server per archiviare e reperire informazioni sul
        lato cliente.
      </Text>
      <br />
      <br />
      <Text>
        I cookie si distinguono in “tecnici”, “analitici” e “di profilazione”. A
        loro volta, poi, i cookie possono essere divisi in cookie di “prima” e
        di “terza parte”.
      </Text>
      <br />
      <br />
      <Text>
        L’utilizzo di cookie e di tecnologie affini da parte del Sito avviene in
        conformità alla normativa nazionale ed europea, nonché nel rispetto del
        Provvedimento dell’8 maggio 2014, intitolato “Individuazione delle
        modalità semplificate per l’informativa e l’acquisizione del consenso
        per l’uso dei cookie” del Garante Privacy Italiano.
      </Text>
      <br />
      <br />
      <Text>
        Il Sito è ospitato dalla piattaforma di proprietà di “WordPress”. Per
        approfondimenti circa i cookie utilizzati da tale piattaforma e per
        disattivare gli stessi, si rinvia al seguente{" "}
        <a href="https://it.wordpress.org/about/privacy/">link</a>.
      </Text>

      <br />
      <br />
      <Title level={4} className="policy-title mt-20">
        Il Sito utilizza i seguenti cookie:
      </Title>

      <Title level={4} className="policy-title mt-20">
        Cookie Tecnici
      </Title>
      <Text>
        I cookie tecnici hanno la finalità principale di rendere più agevole la
        navigazione del Sito. Quasi tutti i browser sono impostati per accettare
        i cookie; tuttavia, l’utente può autonomamente modificare la
        configurazione del proprio browser o avvalersi di specifici componenti
        aggiuntivi e bloccarli: in tal caso, la fruizione del portale web e
        l’utilizzo di alcuni servizi possono risultare limitati.
      </Text>
      <br />
      <br />
      <Text>
        I cookie tecnici si distinguono a loro volta in cookie di{" "}
        <b>
          "sessione” e “<b>persistenti”</b>
        </b>
        : entrambi vengono memorizzati sul dispositivo dell’utente ma i primi
        vengono eliminati alla chiusura del browser, mentre i secondi restano
        memorizzati fino alla loro scadenza.
      </Text>
      <br />
      <br />
      <Text>
        <b>Il Sito utilizza cookie tecnici “di sessione”</b> per il
        funzionamento della navigazione all’interno delle pagine, come per
        esempio permettere l’autenticazione ad aree riservate o memorizzare
        preferenze temporanee dell’utente; questi cookie sono cancellati una
        volta chiuso il browser.
      </Text>
      <br />
      <br />
      <Text>
        L’uso dei cookie di sessione (che, in ogni caso, non vengono memorizzati
        in modo persistente sul computer dell’utente e sono cancellati
        automaticamente non appena il browser viene chiuso) è strettamente
        limitato ai fini della trasmissione di dati (costituito da numeri
        casuali generati dal server) che identificano la sessione specifica e
        sono necessari per consentire l’esplorazione sicura ed efficiente.
      </Text>
      <br />
      <br />
      <Text>
        <b>
          Il Sito in alcuni casi utilizza anche cookie tecnici “persistenti”
        </b>
        , per memorizzare le scelte dell’utente relative, ad esempio, alla
        lingua o al tipo di dispositivo.
      </Text>
      <br />
      <br />
      <Text>
        <b>I cookie persistenti</b> vengono memorizzati sul dispositivo degli
        utenti tra distinte sessioni del browser e consentono di ricordare le
        azioni dell’utente in un sito. I cookie persistenti possono essere
        utilizzati per una varietà di scopi, tra cui ricordare le preferenze
        dell’utente (es. la lingua del sito) quando si utilizza un sito.
      </Text>

      <Title level={4} className="policy-title mt-20">
        Cookie Analitici
      </Title>
      <Text>
        Tali cookie sono utilizzati per tracciare le preferenze di navigazione
        dell’utente e per la raccolta di dati statistici in forma anonima.
      </Text>
      <br />
      <br />
      <Text>
        Il Sito utilizza cookie Analitici solo di terze parti, provenienti da
        altri siti web.
      </Text>
      <br />
      <br />
      <Text>
        L’utente può disattivare tali cookie accedendo alle impostazioni del
        proprio browser.
      </Text>
      <br />
      <br />
      <Text>Si rinvia ai link dei singoli browser:</Text>
      <ul>
        <li>
          <a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies">
            <b>Internet Explorer</b>
          </a>
        </li>

        <li>
          <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences?redirect=no">
            <b>Firefox</b>
          </a>
        </li>

        <li>
          <a href="https://www.apple.com/legal/privacy/it/">
            <b>Safari</b>
          </a>
        </li>

        <li>
          <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en">
            <b>Chrome</b>
          </a>
        </li>

        <li>
          <a href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/">
            <b>Opera</b>
          </a>
        </li>
      </ul>
      <br />
      <br />
      <Text>
        Inoltre, è possibile disattivare i cookies presenti sui siti web
        scaricando appositi software (quali{" "}
        <a href="http://www.ghostery.com">Ghostery</a>) oppure attivare la
        modalità di “navigazione anonima”: si tratta di una funzione che
        consente di navigare senza lasciare traccia nel browser dei dati di
        navigazione. Tale funzione consente unicamente di non mantenere i dati
        di navigazione nel browser.
      </Text>

      <Title level={4} className="policy-title mt-20">
        Cookie di plug in di social network
      </Title>
      <Text>
        Il Sito potrebbe utilizzare, inoltre, cookie di{" "}
        <b>plug in di social network</b> di terze parti, al fine di consentirti
        di condividere i contenuti su diversi social network. Essi permettono
        all’utente di interagire tramite le reti sociali (es. funzione condividi
        su Facebook, Twitter, YouTube o LinkedIn). Tali cookie possono essere
        disattivati tramite le opzioni del proprio browser (v. i link riportati
        sopra).
      </Text>
      <br />
      <br />
      <Text>
        I cookie di social network non sono necessari alla navigazione. Per
        ulteriori informazioni sulle politiche di utilizzo dei cookie da parte
        dei Social Network, è possibile consultare le rispettive privacy e
        cookie policy:
      </Text>
      <ul>
        <li>
          <a href="https://it-it.facebook.com/about/privacy/">
            <b>Facebook</b>
          </a>
        </li>

        <li>
          <a href="http://www.google.com/intl/it/policies/privacy/">
            <b>Google</b>
          </a>
        </li>

        <li>
          <a href="https://twitter.com/it/privacy">
            <b>Twitter</b>
          </a>
        </li>

        <li>
          <a href="https://help.instagram.com/1896641480634370?ref=ig">
            <b>Instagram</b>
          </a>
        </li>

        <li>
          <a href="https://www.linkedin.com/legal/cookie-policy">
            <b>LinkedIn</b>
          </a>
        </li>

        <li>
          <a href="https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&hl=it">
            <b>YouTube</b>
          </a>
        </li>
      </ul>

      <Title level={4} className="policy-title mt-20">
        Cookie di profilazione solo terze parti
      </Title>
      <Text>
        I cookie di profilazione sono utilizzati per tracciare la navigazione
        dell’utente e creare un profilo dello stesso sulla base dei suoi
        comportamenti, che può poi essere utilizzato per inviare messaggi
        pubblicitari al dispositivo dell’utente. I cookie di profilazione terze
        parti sono cookie provenienti da altri siti web o da circuiti
        pubblicitari (Google AdSense, etc.) che possono essere inviati al
        dispositivo
      </Text>

      <Title level={4} className="policy-title mt-20">
        ***
      </Title>
      <Title level={4} className="policy-title">
        Cookie utilizzati dal Sito
      </Title>
      <Text>Si comunica che il Sito utilizza i seguenti Cookie:</Text>
      <div class="table_container">
        <table class="table">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Finalità</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <a href="https://www.onetrust.com/privacy-notice/">
                  <b>OptanonConsent</b>
                </a>

                <p className="cookie_type">
                  Tipologia: <b>Necessari</b>
                </p>
              </td>
              <td>
                <p>Necessari per il funzionamento del sito web.</p>
                <p>
                  <b>Periodo di conservazione: 1 anno</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <a href="https://www.cookiebot.com/en/privacy-policy/">
                  <b>CookieConsent</b>
                </a>

                <p className="cookie_type">
                  Tipologia: <b>Necessari</b>
                </p>
              </td>
              <td>
                <p>
                  Necessari per memorizzare le preferenze relative al consenso
                  dei cookie.
                </p>
                <p>
                  <b>Periodo di conservazione: 1 anno</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <a href="https://www.cloudflare.com/it-it/privacypolicy/">
                  <b>__cfruid</b>
                </a>

                <p className="cookie_type">
                  Tipologia: <b>Necessari</b>
                </p>
              </td>
              <td>
                <p>Funzionalità sconosciuta.</p>
                <p>
                  <b>Periodo di conservazione: 1 sessione</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <a href="https://stripe.com/it/privacy">
                  <b>__stripe_mid</b>
                </a>

                <p className="cookie_type">
                  Tipologia: <b>Necessari</b>
                </p>
              </td>
              <td>
                <p>Per fornire prevenzione delle frodi.</p>
                <p>
                  <b>Periodo di conservazione: 1 anno</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <a href="https://calendly.com/privacy">
                  <b>_calendly_session</b>
                </a>

                <p className="cookie_type">
                  Tipologia: <b>Necessari</b>
                </p>
              </td>
              <td>
                <p>Per memorizzare le preferenze dell’utente.</p>
                <p>
                  <b>Periodo di conservazione: 1 sessione</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p>
                  <b>last_pysTrafficSource, pys_landing_page</b>
                </p>

                <p className="cookie_type">
                  Tipologia: <b>Configurazione</b>
                </p>
              </td>
              <td>
                <p>
                  Questo cookie consente di salvare le impostazioni di comfort
                  individuali selezionate e di conservarle per le visite attuali
                  e future al sito.
                </p>
                <p>
                  <b>Periodo di conservazione: circa 7 giorni</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p>
                  <b>
                    last_pys_bingid, last_pys_fbadid, last_pys_gadid, pys_padid,
                    last_pys_utm_campaign, pys_utm_content, pys_utm_medium,
                    last_pys_utm_source, pys_utm_term, pys_bingid, pys_fbadid,
                    pys_first_visit, pys_gadid, pys_padid, pys_start_session,
                    pys_utm_campaign, pys_utm_content, pys_utm_medium,
                    pys_utm_source, pys_utm_term
                  </b>
                </p>
              </td>
              <td>
                <p>
                  Questo cookie consente di salvare le impostazioni di comfort
                  individuali selezionate e di conservarle per le visite attuali
                  e future al sito.
                </p>
                <p>
                  <b>Periodo di conservazione: 1 sessione</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p>
                  <b>pys_session_limit</b>
                </p>

                <p className="cookie_type">
                  Tipologia: <b>Configurazione</b>
                </p>
              </td>
              <td>
                <p>
                  Questo cookie consente di salvare le impostazioni di comfort
                  individuali selezionate e di conservarle per le visite attuali
                  e future al sito.
                </p>
                <p>
                  <b>Periodo di conservazione: 1 ora</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p>
                  <b>last_pys_landing_page</b>
                </p>

                <p className="cookie_type">
                  Tipologia: <b>Necessari</b>
                </p>
              </td>
              <td>
                <p>
                  Questo cookie è necessario per il funzionamento del nostro
                  sito web.
                </p>
                <p>
                  <b>Periodo di conservazione: circa 7 giorni</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p>
                  <b>pysTrafficSource</b>
                </p>

                <p className="cookie_type">
                  Tipologia: <b>Analitici</b>
                </p>
              </td>
              <td>
                <p>
                  Consente di raccogliere dati che permettono ai servizi di
                  capire come gli utenti interagiscono con un determinato
                  servizio.
                </p>
                <p>
                  <b>Periodo di conservazione: circa 7 giorni</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p>
                  <a href="https://www.facebook.com/privacy/policy">
                    <b>_fbp</b>
                  </a>
                </p>

                <p className="cookie_type">
                  Tipologia: <b>Tracciamento</b>
                </p>
              </td>
              <td>
                <p>Per memorizzare le preferenze dell’utente.</p>
                <p>
                  <b>Periodo di conservazione: 3 mesi</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p>
                  <a href="https://policies.google.com/privacy?hl=it">
                    <b>_ga</b>
                  </a>
                </p>

                <p className="cookie_type">
                  Tipologia: <b>Statistiche</b>
                </p>
              </td>
              <td>
                <p>Per memorizzare e contare le visualizzazioni di pagina.</p>
                <p>
                  <b>Periodo di conservazione: 2 anni</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p>
                  <a href="https://policies.google.com/privacy?hl=it">
                    <b>Google Analytics</b>
                  </a>
                </p>

                <p className="cookie_type">
                  Tipologia: <b>Analitici</b>
                </p>
              </td>
              <td>
                <p>
                  Consente di raccogliere dati che permettono ai servizi di
                  capire come gli utenti interagiscono con un determinato
                  servizio.
                </p>
                <p>
                  <b>Periodo di conservazione: 26 mesi</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p>
                  <a href="https://policies.google.com/privacy?hl=it">
                    <b>Google Tag Manager</b>
                  </a>
                </p>

                <p className="cookie_type">
                  Tipologia: <b>Statistici</b>
                </p>
              </td>
              <td>
                <p>Servizio di statistica fornito da Google Inc.</p>
                <p>
                  <b>Periodo di conservazione: 26 mesi</b>
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <p>
                  <a href="https://it-it.facebook.com/policy.php">
                    <b>Pixel di Facebook</b>
                  </a>
                </p>

                <p className="cookie_type">
                  Tipologia: <b>Analitici</b>
                </p>
              </td>
              <td>
                <p>
                  Utilizzato per misurare le interazioni degli utenti con il
                  sito web.
                </p>
                <p>
                  <b>Periodo di conservazione: 26 mesi</b>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
