import axios from "axios";

const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
};
const axiosBaseUrl = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
    return axios;
};

export {setAuthToken, axiosBaseUrl};
