import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosBaseUrl } from "../../config/axios-configuration";

const axios = axiosBaseUrl();

export const dashboardStats = createAsyncThunk(
  "dasdboard/dashboard-stats",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get("/dashboard/dashboard-stats", data);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

  export const GetTransactions = createAsyncThunk(
    "dasdboard/get-transactions",
    async (params, { rejectWithValue }) => {
      try {
        const response = await axios.get("/dashboard/transactions", {params});
        const responsedData = response.data
        return responsedData
      } catch (err) {
        if (err.response && err.response.data) {
          return rejectWithValue({
            err: err.response.data,
            status: err.response.status,
          });
        }
        return rejectWithValue({
          err: "Network Error",
        });
      }
    }
  );

export const getGraphData = createAsyncThunk(
  "dasdboard/graph-data",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/dashboard/graph-data?startDate=${data?.startDate}&endDate=${data?.endDate}`
      );
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard-stats",
  initialState: {
    message: "",
    err: "",
    loading: false,
    success: false,
    reimbursedItems: 0,
    dashboardSummary: {},
    transactions: [],
    total: 0
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
  },
  extraReducers: {
    [dashboardStats.pending]: (state, action) => ({
      ...state,
      loading: true,
      err: "",
    }),
    [dashboardStats.fulfilled]: (state, action) => ({
      ...state,
      reimbursedItems: action.payload.reimbursedItems,
      dashboardSummary: action.payload.dashboardSummary,
      loading: false,
      err: "",
    }),
    [dashboardStats.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error || "Network error",
      success: false,
      loading: false,
    }),
    [GetTransactions.pending]: (state, action) => ({
      ...state,
      loading: true,
      err: "",
    }),
    [GetTransactions.fulfilled]: (state, action) => ({
      ...state,
      transactions: action.payload.transactions,
      total: action.payload.total,
      loading: false,
      err: "",
    }),
    [GetTransactions.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error || "Network error",
      success: false,
      loading: false,
    }),
    [getGraphData.pending]: (state, action) => ({
      ...state,
      loading: true,
      err: "",
    }),
    [getGraphData.fulfilled]: (state, action) => ({
      ...state,
      graphDate: action.payload.graphDate,
      loading: false,
      err: "",
    }),
    [getGraphData.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error || "Network error",
      success: false,
      loading: false,
    }),
  },
});

const { reducer, actions } = dashboardSlice;

export const { SetState } = actions;

export default reducer;
