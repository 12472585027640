import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

export const data = (dataset1, dataset2) => {
  const xValues = dataset1.map((x) => x.x);
  return {
    labels: xValues,
    datasets: [
      {
        label: `Amount Reimbursed`,
        data: dataset1.map((value) => value),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Total Item Reimbursed",
        data: dataset2.map((value) => value),
        borderColor: "green",
        backgroundColor: "green",
      },
    ],
  };
};
function Chart({ dataset1 = [], dataset2 = [] }) {
  return <Line options={options} data={data(dataset1, dataset2)} />;
}

export default Chart;
