import React, {useEffect, useState} from "react";
import {isEmpty} from "lodash";

import "./PaymentSetting.css";
import PaymentTile from "./PaymentTile";
import PaymentModal from "./PaymentModal";
import {useDispatch, useSelector} from "react-redux";

import {GetUser} from "../../redux/slices/auth-slice";

function PaymentSetting() {
    const [open, setOpen] = useState();
    const [payment, setPayment] = useState(undefined);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const dispatch = useDispatch();
    const {user, customer} = useSelector((state) => state.auth);
    const [paymentState, setPaymentState] = useState("billing_address");
    const [tile1Items, setTile1Items] = useState([
        {name: "Email", value: user?.email, color: true},
        {name: "Company name", value: "-"},
        {name: "Address 1", value: "-"},
        {name: "City", value: "-"},
        {name: "Postcode", value: "-"},
        {name: "Country", value: "-"},
    ]);

    const [tile2Items, setTile2Items] = useState([
        {name: "Payment method", value: "-"},
        {name: "Cardholder name", value: "-"},
        {name: "Card issuer", value: "-"},
        {name: "Credit card number", value: "-"},
        {name: "Card Expired", value: "-"},
        {name: "Card issued in ", value: "-"},
    ]);

    const handleOpen = (type) => {
        if (type === "billing_information") {
            setPaymentState("billing_address");
        } else if (type === "payment_information") {
            setPaymentState("payment_information");
        }
        setOpen(true);
        if (user.payment) {
            setPayment({
                email: user.email,
                companyName: user?.payment?.address.companyName || "",
                postCode: user?.payment?.address?.postCode || "",
                addressline1: user?.payment?.address?.addressline1 || "",
                addressline2: user?.payment?.address?.addressline2 || "",
                country: user?.payment?.address?.country || "",
                province: user?.payment?.address?.province || "",
                city: user?.payment?.address?.city || "",
                vatId: user?.payment?.vatNumber || "",
                cardHolderName: user?.payment?.cardHolderName,
                sdi: user?.payment?.sdi,
            });
            setIsNextButtonDisabled(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (isEmpty(payment) && !user.payment) {
            setOpen(true);
            setIsNextButtonDisabled(true);
        } else if (
            payment &&
            payment.companyName &&
            payment.postCode &&
            payment.addressline1 && payment.city && payment.province &&
            payment.country && payment.vatId && payment.sdi
        ) {
            setIsNextButtonDisabled(false);
        } else {
            setIsNextButtonDisabled(true);
        }
    }, [payment]);

    useEffect(() => {
        setTile1Items([
            {
                name: "Email",
                value: user?.email,
                color: true,
            },
            {
                name: "Company name",
                value: user?.payment?.address?.companyName || "-",
            },
            {name: "Address 1", value: user?.payment?.address?.addressline1 || "-"},
            {name: "City", value: user?.payment?.address?.city || "-"},
            {name: "Postcode", value: user?.payment?.address?.postCode || "-"},
            {name: "Province", value: user?.payment?.address?.province || "-"},
            {name: "Country", value: user?.payment?.address?.country || "-"},
        ]);
        setTile2Items([
            {name: "Payment method", value: user?.payment ? "Credit Card" : "-"},
            {name: "Cardholder name", value: user?.payment?.cardHolderName || "-"},
            {name: "Card issuer", value: user?.payment?.brand || "-"},
            {
                name: "Credit card number",
                value: user?.payment?.cardLast4Digits
                    ? `**** **** **** ${user?.payment?.cardLast4Digits}`
                    : "-",
            },
            {
                name: "Card Expired",
                value: user?.payment?.expMonth
                    ? `${user?.payment?.expMonth} / ${user?.payment?.expYear}`
                    : "-",
            },
            {
                name: "Card issued in ",
                value: user?.payment?.cardIssuedCountry || "-",
            },
        ]);
    }, [user]);

    useEffect(() => {
        dispatch(GetUser());
    }, []);

    return (
        <div className="payment_setting_container">
            <label className="payment_setting_header">Payment settings </label>
            <PaymentTile
                title={"Billing information"}
                items={tile1Items}
                type={"billing_information"}
                user={user}
                handleOpen={(type) => handleOpen(type)}
            />
            {user.role === 'Card' ?
                <PaymentTile
                    title={"Payment method"}
                    items={tile2Items}
                    type={"payment_information"}
                    user={user}
                    handleOpen={(type) => handleOpen(type)}
                />
                : <></>}
            <PaymentModal
                isNextButtonDisabled={isNextButtonDisabled}
                payment={payment}
                setPayment={setPayment}
                handleClose={handleClose}
                open={open}
                setIsNextButtonDisabled={setIsNextButtonDisabled}
                setOpen={setOpen}
                paymentState={paymentState}
                setPaymentState={setPaymentState}
                fromPage={"setting"}
            />
        </div>
    );
}

export default PaymentSetting;