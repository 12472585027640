import React, {useEffect} from "react";
import {Col, Input, Row} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";

function ContactInfo({
                         info,
                         setInfo,
                         index,
                         isDisabled,
                     }) {

    const handleInputChange = (key, value) => {
        const change = {
            ...info,
            [key]: value
        }
        setInfo(change,index)
    }
    return (
        <>
            <Row className="mt-3">
                <Col span={7}>Role</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('role', e.target.value)}
                        value={info?.role}
                        type="text"
                        style={{width: '134%'}}
                        disabled={isDisabled}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={7}>Description</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('description', e.target.value)}
                        value={info?.description}
                        type="text"
                        style={{width: '134%'}}
                        disabled={isDisabled}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={7}>Name</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('name', e.target.value)}
                        value={info?.name}
                        type="text"
                        style={{width: '134%'}}
                        disabled={isDisabled}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={7}>Surname</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('surname', e.target.value)}
                        value={info?.surname}
                        type="text"
                        style={{width: '134%'}}
                        disabled={isDisabled}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={7}>Phone</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('phone', e.target.value)}
                        value={info?.phone}
                        type="text"
                        style={{width: '134%'}}
                        disabled={isDisabled}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={7}>Email</Col>
                <Col span={12}>
                    <Input
                        onChange={(e) => handleInputChange('email', e.target.value)}
                        value={info?.email}
                        type="text"
                        style={{width: '134%'}}
                        disabled={isDisabled}/>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col span={7}>Brevo</Col>
                <Col span={12}>
                    <Input
                        disabled
                        value={info?.brevo_id}
                        type="text"
                        style={{width: '134%'}}/>
                </Col>
            </Row>
        </>
    );
}

export default ContactInfo;
