import {PlusOutlined} from "@ant-design/icons";
import {Button, Input, Modal} from "antd";
import React, {useState} from "react";
import {ClaimCase, CreateCase} from "../../redux/slices/adjustment-slice";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";
import TextArea from "antd/es/input/TextArea";

function AdminAddNewCase() {
    const [searchParams] = useSearchParams();
    const getQueryString = () => {
        return window?.location?.search || "";
    };
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false)
    const [caseIds, setCaseIds] = useState("")

    const submitCase = () => {
        if (caseIds) {
            dispatch(
                CreateCase({
                    amazonCaseIds: caseIds.trim().split(',').map(Number),
                    userId: searchParams.get('user'),
                })
            ).then((d) => {
                console.log(d)
                if (d.type === "adjustment/create-case/rejected") {
                    toast.error("Something went wrong. Please try again!");
                } else if (d.type === "adjustment/create-case/fulfilled") {
                    for (const cas of d.payload.response) {
                        if (cas.processed) toast.success("New case " + cas.amazonCaseId + " added successfully.");
                        else toast.error(cas.message);
                    }
                    setModalOpen(false);
                    setCaseIds([])
                }
            });
        } else {
            setModalOpen(false);
        }
    };
    return (<>
        <Button
            hidden={!getQueryString().includes('user')}
            style={{backgroundColor: 'cyan', width: 70, height: 40}}
            onClick={() => setModalOpen(true)}>
            <PlusOutlined/></Button>
        <Modal
            title="Inserisci nuovi case"
            open={modalOpen}
            onOk={() => {
                submitCase()
            }}
            onCancel={() => {
                setModalOpen(false);
            }}
        >
            Lista di Amazon Case ID
            <TextArea
                placeholder="Enter Amazon Case Ids separated with commas and without spaces - Example 2103810,1230812"
                size="middle"
                value={caseIds}
                onChange={(e) => setCaseIds(e.target.value)}
            />
        </Modal>

    </>)
}

export default AdminAddNewCase