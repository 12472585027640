import React, {useEffect, useState} from "react";
import axios from "axios";
import Select from "react-select";
import {Button, Checkbox, Col, Divider, Row, Table} from "antd";
import {CURRENCY_SYMBOLS, SELLER_CENTRAL_URL} from "../../utils/constant";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {toast} from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

export const ReimbursementsRecheck = () => {
    const [stores, setStores] = useState([])
    const [reimbursements, setReimbursements] = useState([])
    const [currentDisplayingReimbursementId, setCurrentDisplayingReimbursementId] = useState("")
    const [currentDisplayingCases, setCurrentDisplayingCases] = useState([])
    const getReimbursements = async () => {
            axios.get(`/reimbursements/recheck`)
                .then(x => setReimbursements(x.data))
    }

    const getCases = async (reimbursementId) => {
            axios.get(`/reimbursements/reimbursement/${reimbursementId}`)
                .then(x => setCurrentDisplayingCases(x.data))
    }

    useEffect(() => {
        getReimbursements()
    }, []);

    const cases_columns = [
        {
            title: 'Store',
            dataIndex: 'storeName',
            key: 'storeName',
        },        {
            title: 'Account',
            dataIndex: 'account',
            key: 'account',
        },
        {
            title: 'Date reimbursement',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'ReimbursementId',
            dataIndex: 'reimbursementId',
            key: 'reimbursementId',
        },
        {
            title: 'FNSKU',
            dataIndex: 'fnsku',
            key: 'fnsku',
        },
        {
            title: 'CaseId',
            dataIndex: 'caseId',
            key: 'caseId',
        },
        {
            title: 'Amount',
            dataIndex: 'amountReimbursed',
            key: 'amountReimbursed',
        },
        {
            title: 'Product price',
            dataIndex: 'amountProduct',
            key: 'amountProduct',
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: "Lost Warehouse",
            dataIndex: "store.servicesConfig.lost.warehouse.enabled",
            key: "store.servicesConfig.lost.warehouse.enabled",
            editable: false,
            render: (text, record) => <Checkbox checked={record?.store?.servicesConfig?.lost?.warehouse.enabled}/>
        },
        {
            title: "Lost Inbound",
            dataIndex: "store.servicesConfig.lost.inbound.enabled",
            key: "store.servicesConfig.lost.inbound.enabled",
            editable: false,
            render: (text, record) => <Checkbox checked={record?.store?.servicesConfig?.lost?.inbound.enabled}/>
        },
        {
            title: "Damaged Warehouse",
            dataIndex: "store.servicesConfig.damaged.warehouse.enabled",
            key: "store.servicesConfig.damaged.warehouse.enabled",
            editable: false,
            render: (text, record) => <Checkbox checked={record?.store?.servicesConfig?.damaged?.warehouse.enabled}/>
        }, {
            title: "Damaged Inbound",
            dataIndex: "store.servicesConfig.damaged.inbound.enabled",
            key: "store.servicesConfig.damaged.inbound.enabled",
            editable: false,
            render: (text, record) => <Checkbox checked={record?.store?.servicesConfig?.damaged?.inbound.enabled}/>
        },
        {
            title: "Damaged Outbound",
            dataIndex: "store.servicesConfig.damaged.outbound.enabled",
            key: "store.servicesConfig.damaged.outbound.enabled",
            editable: false,
            render: (text, record) => <Checkbox checked={record?.store?.servicesConfig?.damaged?.outbound.enabled}/>
        },
        {
            title: "Disposed",
            dataIndex: "store.servicesConfig.disposed.enabled",
            key: "store.servicesConfig.disposed.enabled",
            editable: false,
            render: (text, record) => {
                console.log(record)
                return <Checkbox checked={record?.store?.servicesConfig?.disposed?.enabled}/>
            }
        },
        {
            title: '',
            dataIndex: 'details',
            key: 'details',
        },
        {
            title: '',
            dataIndex: 'setNotSOS',
            key: 'setNotSOS',
        },
        {
            title: '',
            dataIndex: 'addToInvoice',
            key: 'addToInvoice',
        },
    ];

    const cases_reimb_columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'caseExists',
        },
        {
            title: 'FNSKU',
            dataIndex: 'fnsku',
            key: 'fnsku',
        },
        {
            title: 'Seller Sku',
            dataIndex: 'sellerSku',
            key: 'sellerSku',
        },
        {
            title: 'CaseId',
            dataIndex: 'amazonCaseId',
            key: 'amazonCaseId',
        },
    ];

    const addToInvoice = (reimbursementId) => {
        axios.post("/reimbursements/add-to-invoice", {reimbursementId})
            .then(x => {
                    if (x.data.success){
                        toast.success(`Added reimbursement ${reimbursementId} to invoice`)
                        getReimbursements()
                    }
                    else
                        toast.error(`Reimbursement ${reimbursementId} not added to invoice with generic error ${x.data.error}`)
                }
            )
            .catch(err => toast.error(`Reimbursement ${reimbursementId} not added to invoice ${err}`))
    }

    const setNotSOS = (reimbursementId) => {
        axios.post("/reimbursements/not-internal", {reimbursementId})
            .then(x => {
                    if (x.data.success) {
                        toast.success(`Processed reimbursement  ${reimbursementId}`)
                        getReimbursements()
                    }
                    else
                        toast.error(`${reimbursementId} generic error ${x.data.error}`)
                }
            )
            .catch(err => toast.error(`Reimbursement ${reimbursementId} not added to invoice ${err}`))
    }

    let locale = {
        emptyText: (
            <div className={'box content'}><Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner></div>
        )
    };
    return <>
        <Row>
            <Col md={18}>
                <h3><strong>Reimbursements</strong></h3>
                <Table
                    pagination={{pageSize: 100}}
                   showSizeChanger
                    scroll={{ y: `calc(100vh - 250px)` , x : 'auto'}}
                   style={{width: '100%'}}
                   dataSource={reimbursements && reimbursements.length && reimbursements.filter(x => new Date(x.reimbursementDate) > new Date(x.store.createdAt)).map(x => ({
                       reimbursementId: <a
                           target="_blank"
                           href={`${x.store.marketplaces.filter(x => x.countryCode === 'US').length > 0 ? "https://sellercentral.amazon.com":"https://sellercentral.amazon.it"}/reportcentral/REIMBURSEMENTS/0/%7B"filters":["","","${x.reimbursementId}",""],"pageOffset":1,"searchDays":700%7D`}
                           className="queryLink" rel="noreferrer">
                           {x.reimbursementId}
                       </a>,
                       amountProduct: 0,//TODO
                       storeName: x.store?.name,
                       fnsku: x.reimbursements ? x.reimbursements?.map(x => x.fnsku + ' ') : '',
                       caseId: x.caseId,
                       account: x.store?.managingAccount,
                       date: <strong>{x.reimbursements ? new Date(x.reimbursements[0]?.approvalDate).toDateString()
                           + '\n' + new Date(x.reimbursements[0]?.approvalDate).getHours()
                           + "." + new Date(x.reimbursements[0]?.approvalDate).getMinutes(): ''}</strong>,
                       reason: x.reimbursements?.map(x => x.reason + ' '),
                       addToInvoice: <Button
                           type={"primary"}
                           onClick={() => {
                               addToInvoice(x.reimbursementId)
                           }}>Add to invoice
                       </Button>,
                       store: x.store,
                       setNotSOS: <Button danger
                           type={"primary"}
                           onClick={() => {
                               setNotSOS(x.reimbursementId)
                           }}>Not SOSFBA
                       </Button>,
                       details: <Button
                           type={currentDisplayingReimbursementId === x._id ? "primary" : "dashed"}
                           onClick={() => {
                               setCurrentDisplayingReimbursementId(x._id)
                               getCases(x._id)
                           }}>Details
                       </Button>,
                       caseExists: x.case.length > 0 ? <CheckOutlined style={{color: 'green'}}/> :
                           <CloseOutlined style={{color: 'red'}}/>,
                       amountReimbursed: Math.round(x.amountTotal * 100) / 100 + ' ' + CURRENCY_SYMBOLS[x.reimbursements ? x.reimbursements[0].currencyUnit : ""]
                   }))} columns={cases_columns}/>
            </Col>
                <Col md={6}>

            <h3><strong>Cases</strong></h3>
            <Table locale={locale} pagination={false}
                   scroll={{ y: `calc(100vh - 250px)` , x : '550px'}}
                   dataSource={currentDisplayingReimbursementId !== '' ? currentDisplayingCases.map(x => ({
                       fnsku: x.fnsku,
                       sellerSku: x.sellerSku,
                       date: <strong>{new Date(x.createdAt).toDateString()
                           + '\n' + new Date(x.createdAt).getHours()
                           + "." + new Date(x.createdAt).getMinutes()}</strong>,
                       amazonCaseId: x.amazonCaseId,
                       caseId:x.caseId
                   })) : []} columns={cases_reimb_columns}/>
                </Col>
        </Row>
    </>
}