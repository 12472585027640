import React, {useEffect, useState} from "react";
import TableView from "./TabelView/TableView";
import {Button, Card, Checkbox, Col, Divider, Input, Modal, Row, Select, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {GetUsers} from "../../redux/slices/admin-slice";
import {UserModal} from "./UserModal";
import {toast} from "react-toastify";
import {axiosBaseUrl} from "../../config/axios-configuration";
import {useNavigate} from "react-router-dom";
import Verified from "../../assets/Instagram-Verification Badge-24.png";
import './Users.css'
import {AlertFilled, AlertOutlined, BookOutlined, FileTextOutlined, WhatsAppOutlined} from "@ant-design/icons";
import {WhatsappModal} from "./WhatsappModal.jsx";

const StoreVisualization = ({store}) => {
    const [managingAccount, setManagingAccount] = useState(store.managingAccount)
    const [lostWarehouse, setlostWarehouse] = useState(store.servicesConfig?.lost?.warehouse)
    const [lostInbound, setlostInbound] = useState(store.servicesConfig?.lost?.inbound)
    const [damagedWarehouse, setdamagedWarehouse] = useState(store.servicesConfig?.damaged?.warehouse)
    const [damagedInbound, setdamagedInbound] = useState(store.servicesConfig?.damaged?.inbound)
    const [damagedOutbound, setdamagedOutbound] = useState(store.servicesConfig?.damaged?.outbound)
    const [disposed, setdisposed] = useState(store.servicesConfig?.disposed)
    return (<>
            <Row>
                <h4><strong>{store.name} - {store.sellerId}</strong></h4>
                <Col md={12}>
                    <p><strong>LOST - WAREHOUSE</strong></p>
                    <Checkbox checked={lostWarehouse}/>
                </Col>
                <Col md={12}>
                    <p><strong>LOST - INBOUND</strong></p>
                    <Checkbox checked={lostInbound}/>
                </Col>
                <Col md={12}>
                    <p><strong>DAMAGED - WAREHOUSE</strong></p>
                    <Checkbox checked={damagedWarehouse}/>
                </Col>
                <Col md={12}>
                    <p><strong>DAMAGED - INBOUND</strong></p>
                    <Checkbox checked={damagedInbound}/>
                </Col>
                <Col md={12}>
                    <p><strong>DAMAGED - OUTBOUND</strong></p>
                    <Checkbox checked={damagedOutbound}/>
                </Col>
                <Col md={12}>
                    <p><strong>DISPOSED</strong></p>
                    <Checkbox checked={disposed}/>
                </Col>
                <Col md={12}>
                    <label><strong>Managing Account</strong></label>
                    <p>{managingAccount}</p>
                </Col>
            </Row>
        </>
    );
}

function NewCases() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pageNo, setPageNo] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [size, setSize] = useState(100);
    const [activeOptions, setActiveOptions] = useState({});
    const [billingModal, setBillingModal] = useState();
    const [whatsappModal, setWhatsappModal] = useState();
    const [modalAddUser, setModalAddUser] = useState(false);
    const axios = axiosBaseUrl();
    const [emailToAdd, setEmailToAdd] = useState("")
    const [pswToAdd, setPswToAdd] = useState("")
    const {users, isLoading, err, total} = useSelector((state) => state.admin) || {};
    const {token, user} = useSelector((state) => state.auth);


    const viewRow = (record) => {
        setBillingModal(record);
    };

    const viewRowWhats = (record) => {
        setWhatsappModal(record);
    };

    const USERS_COLUMNS = [
        {
            title: "ID",
            dataIndex: "_id",
            key: "_id",
            hasFilter: true
        },
        {
            title: "Business Name",
            dataIndex: "businessName",
            key: "businessName",
            hasFilter: true,
            filterSearch: true,
        },
        {
            title: "Commission Discount",
            dataIndex: "commissionDiscount",
            key: "commissionDiscount",
            hasFilter: true
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            hasFilter: true
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            hasFilter: true
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            hasFilter: true
        },
        {
            title: "Join Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text, record) => <p>{new Date(record.createdAt).toLocaleString()}</p>
        },
        // {
        //     title: "TeC accepted",
        //     dataIndex: "termsAndConditionsApproval.latestApproved",
        //     key: "termsAndConditionsApproval.latestApproved",
        //     editable: false,
        //     render: (text, record) =>
        //         <p>{record?.termsAndConditionsApproval?.latestApproved ? "Approvato" : "Non approvato"}</p>
        // },
        {
            title: "Stores",
            dataIndex: "storeNames",
            key: "storeNames",
            hasFilter: true,
            filterSearch: true,
        },
        {
            title: "Operation",
            dataIndex: "operation",
            render: (_, record) => {
                const missingInfo = (record.contacts?.length < 1 || !(record.reseller || record.producer) || !record?.payment?.vatNumber || !record.tax)
                const missingVat = !record?.payment?.vatNumber
                return <>
                    {user?.role === 'Admin' ?
                        <div>
                            {
                                <Tooltip title={'Edit'}>
                                    <Button onClick={() => viewRow(record)}>
                                        <img src={Verified} alt={""}/>
                                    </Button>
                                </Tooltip>
                            }
                            <Tooltip title={'Invoices'}>
                                <Button onClick={() => window.open('/invoices?user=' + record._id,'_blank')}>
                                    <BookOutlined/>
                                </Button>
                            </Tooltip>
                            {/*<Tooltip title={'Send Email'}>*/}
                            {/*    <Button onClick={() => sendEmail(record, record._id)} disabled={false}>*/}
                            {/*        <SendOutlined/>*/}
                            {/*    </Button>*/}
                            {/*</Tooltip>*/}
                            <Tooltip title={'Send WhatsApp'}>
                                <Button onClick={() => viewRowWhats(record)} disabled={false}>
                                    <WhatsAppOutlined/>
                                </Button>
                            </Tooltip>
                            {missingInfo &&  <Tooltip title={'Missing Data'}>
                                <Button danger disabled={false}>
                                    <AlertFilled/>
                                </Button>
                            </Tooltip>}
                            {missingVat &&  <Tooltip title={'Missing Vat'}>
                                <Button danger disabled={false}>
                                    <AlertOutlined/>
                                </Button>
                            </Tooltip>}
                        </div> : <></>
                    }
                    <Tooltip title={'Cases'}>
                        <Button onClick={() => window.open('/admin-new-cases?user=' + record._id, '_blank')}>
                            <FileTextOutlined/>
                        </Button>
                    </Tooltip>
                </>
            }
        }
    ];

    useEffect(() => {
        dispatch(
            GetUsers({
                skip: (pageNo - 1) * size,
                limit: size,
                filters: activeOptions,
            })
        );
    }, [pageNo, size, activeOptions]);

    const onlyUnique = (value, index, array) => {
        return value !== '' && array.indexOf(value) === index;
    }

    const mergedColumns = USERS_COLUMNS.map((col) => {
        return {
            ...col,
            filters: users.map(x => ({text: x[col?.key], value: x[col?.key]}))?.filter(onlyUnique),
            filterMode: 'menu',
            filterSearch: true,
            onFilter: (value, record) => {
                return record[col?.key]?.includes(value)
            },
        };
    });

    const addUser = () => {
        axios
            .post('/auth/user', {email: emailToAdd, password: pswToAdd})
            .then(r => {
                toast.success("User added successfully!")
                setEmailToAdd("")
                setPswToAdd("")
                setModalAddUser(false)
                setTimeout(() => window.location.reload(), 1500);
            })
            .catch(err => console.log(err))
    }
    return (
        <>
            <UserModal
                isModalOpen={!!billingModal}
                onCancel={() => setBillingModal(null)}
                user={billingModal}
                closeModal={setBillingModal}
            />
            <WhatsappModal
                isModalOpen={!!whatsappModal}
                onCancel={() => setWhatsappModal(null)}
                user={whatsappModal}
                closeModal={setWhatsappModal}
            />
            <Card>
                <div>
                    <Row>
                        <Col>
                            <Button onClick={() => setModalAddUser(true)}>
                                Add User
                            </Button>
                            <Modal key={'Adduser'}
                                   title="Add User"
                                   open={modalAddUser}
                                   onOk={addUser}
                                   destroyOnHide={true}
                                   onCancel={() => {
                                       setModalAddUser(false);
                                       setEmailToAdd("")
                                       setPswToAdd("")
                                   }}
                            >
                                <p>Email</p>
                                <Input onBlur={(e) => {
                                    setEmailToAdd(e.target.value)
                                }
                                }/>
                                <p>Password</p>
                                <Input onBlur={(e) => setPswToAdd(e.target.value)}/>
                            </Modal>
                        </Col>
                    </Row>
                </div>
            </Card>
            <TableView
                columns={mergedColumns}
                data={users}
            />
        </>
    );
}


export default NewCases;
